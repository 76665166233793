import { useContext } from 'react'

import { useMutation } from '@tanstack/react-query'

import { TEXTS } from '~/utils/allConstants/Constants'

import { NotificationContext } from '../components/Notification'
import { APIErrorRes } from '../types/APIErrorRes.model'
import { CustomerAlert } from '../types/CustomerAlert.model'
import { PageParams } from '../types/PageParams.model'

import axiosInstance from './axiosInstance'
import { getPreparedFilters } from './filters-utils'

const { SOMETHING_WENT_WRONG } = TEXTS

export const useCustomerAlertMutation = () => {
  const alert = useContext(NotificationContext)
  const customerAlertMutation = useMutation(
    (data: any) => {
      return axiosInstance<CustomerAlert>({
        url: '/v1/customer-alerts',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: data,
      })
    },
    {
      onSuccess: () => {
        alert.success('Banner Created Successfully!')
      },
      onError: (e: APIErrorRes) => {
        alert.error(e.response.data.message || SOMETHING_WENT_WRONG)
      },
    }
  )

  return customerAlertMutation
}

export const useCustomerAlertUpdateMutation = (alertId: string) => {
  const alert = useContext(NotificationContext)
  const customerAlertUpdateMutation = useMutation(
    (data: any) => {
      return axiosInstance<CustomerAlert>({
        url: `/v1/customer-alerts/${alertId}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: data,
      })
    },
    {
      onSuccess: () => {
        alert.success('Banner Updated Successfully!')
      },
      onError: (e: APIErrorRes) => {
        alert.error(e.response.data.message || SOMETHING_WENT_WRONG)
      },
    }
  )

  return customerAlertUpdateMutation
}

export const fetchCustomerAlerts = ({
  filters = {},
  fields,
  ...params
}: PageParams<any>) => {
  const paramsCopy = { ...params } as any
  delete paramsCopy['page']
  delete paramsCopy['pageSize']
  delete paramsCopy['additionalFilters']
  const filterParams = getPreparedFilters(filters, fields)
  return axiosInstance
    .get(`/v1/customer-alerts`, { params: { ...paramsCopy, ...filterParams } })
    .then(({ data }) => {
      return {
        ...data,
      }
    })
}

export const useDeleteCustomerAlert = () => {
  const alert = useContext(NotificationContext)
  const useDeleteCustomerAlertMutation = useMutation(
    (alertId: string) => {
      return axiosInstance.delete(`/v1/customer-alerts/${alertId}`)
    },
    {
      onSuccess: () => {
        alert.success('Banner Deleted Successfully!')
      },
    }
  )

  return useDeleteCustomerAlertMutation
}

export const fetchUnreadCustomerAlerts = (params: any) => {
  return axiosInstance
    .get<{ items: CustomerAlert[]; totalCount: number }>(
      `/v1/customer-alerts/unReadAlerts`,
      { params: params }
    )
    .then(({ data }) => {
      return { ...data }
    })
}

export const MarkAsReadCustomerAlert = () => {
  const useMarkAsReadMutation = useMutation((bannerId: string) => {
    return axiosInstance.post(`v1/customer-alerts/${bannerId}/markAsRead`)
  })

  return useMarkAsReadMutation
}

export const fetchCustomerAlertById = (alertId: string) => {
  return axiosInstance
    .get(`/v1/customer-alerts/${alertId}`)
    .then(({ data }) => {
      return { ...data }
    })
}
