import { FC, ReactElement, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ArrowBack } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Button, Grid } from '@mui/material'

import { TEXTS } from '~/utils/allConstants/Constants'

const { BACK, EXPORT_TO_PDF } = TEXTS

interface Props {
  isData: boolean
  handlePdfExport: any
  roleCheck: boolean
}

const Controls: FC<Props> = ({
  isData,
  handlePdfExport,
  roleCheck,
}): ReactElement => {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const onExportClick = () => {
    setIsLoading(true)
    handlePdfExport().then(() => setIsLoading(false))
  }
  return (
    <Grid container mt={3}>
      <Grid item xs={12} className="action-buttons-wrapper">
        <Button
          startIcon={<ArrowBack />}
          sx={{
            fontSize: '20px',
            color: (theme) => theme.palette.text.primary,
            px: 0,
            marginRight: 'auto',
          }}
          onClick={() => navigate(-1)}
        >
          {BACK}
        </Button>
        {isData && roleCheck && (
          <LoadingButton
            className={'action-button'}
            variant={'contained'}
            sx={{
              mt: 1,
            }}
            loading={isLoading}
            onClick={onExportClick}
          >
            {EXPORT_TO_PDF}
          </LoadingButton>
        )}
      </Grid>
    </Grid>
  )
}

export default Controls
