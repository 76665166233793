import { useCallback } from 'react'
import ReactGA from 'react-ga4'
import { useParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'

import { fetchOrderDetailsData } from '~/services/orders'
import { TEXTS } from '~/utils/allConstants/Constants'
import { exportFile } from '~/utils/helpers/helperFunctions'

import DetailsPage from '../UI/DetailsPage/DetailsPage'
import { GlobalLoading } from '../UI/Loading'

import { fields, orderSpecificContent } from './OrderDetailContent'

const { EXPORT_AS_PDF } = TEXTS

const options = {
  name: 'Order',
  queryName: 'order-details',
  tableProps: {
    fields,
  },
}
const OrderDetail = () => {
  const { id } = useParams<{ id?: string }>()

  const { isLoading, data } = useQuery(
    [`order-details`, id],
    () => {
      return fetchOrderDetailsData(id)
    },
    { keepPreviousData: true }
  )

  const handlePdfExport = useCallback(() => {
    ReactGA.event({
      category: 'Orders',
      action: EXPORT_AS_PDF,
    })
    return exportFile({
      name: 'orders',
      id,
      extension: 'pdf',
      exportfilename: 'OrderDetails',
    })
  }, [id])

  if (isLoading) {
    return <GlobalLoading />
  }

  return (
    <DetailsPage
      data={data}
      options={{ ...options, id }}
      assetSpecificContent={orderSpecificContent}
      handlePdfExport={handlePdfExport}
    />
  )
}

export default OrderDetail
