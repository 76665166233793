import { Controller, useForm } from 'react-hook-form'
import { Link as RouterLink } from 'react-router-dom'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import HelpIcon from '@mui/icons-material/Help'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  FormHelperText,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material'

import { useChangePasswordByTokenMutation } from '~/services/users'
import { theme } from '~/theme/AppTheme'
import { TEXTS } from '~/utils/allConstants/Constants'
import {
  confirmPasswordValidation,
  passwordValidation,
} from '~/utils/helpers/helperFunctions'

import CustomTooltip from '../common/customTooltip/CustomTooltip'
import { BootstrapInput } from '../Form/StyledFormControl'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorBlue, acrColorGrey } = variables
const {
  CONFIRM_NEW_PASSWORD,
  NEW_PASSWORD,
  UPDATE_PASSWORD,
  NO_PASSWORD_MESSAGE,
} = TEXTS

type ForgotPasswordConfirmFormProps = {
  email: string
  token: string
  complete: (val: boolean) => void
}

const schema = yup
  .object({
    password: yup
      .string()
      .default('')
      .required(NO_PASSWORD_MESSAGE)
      .test('validation', 'error', passwordValidation),
    confirm_password: confirmPasswordValidation,
  })
  .required()

const ForgotPasswordConfirmForm = ({
  email,
  token,
  complete,
}: ForgotPasswordConfirmFormProps) => {
  const { handleSubmit, control } = useForm<{
    password: string
    confirm_password: string
  }>({
    resolver: yupResolver(schema),
    defaultValues: {
      password: '',
      confirm_password: '',
    },
  })
  const changePassword = useChangePasswordByTokenMutation(token)
  const isMatch = useMediaQuery(theme.breakpoints.down('md'))

  const onSubmit = (data: { password: string; confirm_password: string }) => {
    changePassword.mutate(data, {
      onSuccess: () => {
        complete(true)
      },
    })
  }

  return (
    <Grid
      container
      alignItems="center"
      sx={{ height: '100%' }}
      px={isMatch ? 2 : 8}
    >
      <Grid item xs={12} md={6} mb={4} sx={{ textAlign: 'center' }}>
        <Typography variant="h1" mb={2} px={2}>
          Please enter your registered email and new password.
        </Typography>

        <Typography
          variant={'h4'}
          sx={{
            color: (theme) => theme.palette.text.secondary,
            py: 3,
            px: 3,
            fontSize: 16,
          }}
        >
          This portion of our site is exclusively for our current customers and
          sales force.
          <br />
          If you are a current customer or sales rep and need to set up an
          account&nbsp;
          <Link
            component={RouterLink}
            to={'/register'}
            sx={{
              fontWeight: 'bold',
              color: acrColorBlue,
              textDecorationColor: acrColorBlue,
            }}
          >
            click here
          </Link>
          .
          <br />
          <br />
          If you are not a current customer and would like more information on
          how to
          <br />
          buy our product,&nbsp;
          <Link
            href={'https://amercareroyal.com/pages/open-an-account'}
            target={'_blank'}
            sx={{
              fontWeight: 'bold',
              color: acrColorBlue,
              textDecorationColor: acrColorBlue,
            }}
          >
            click here
          </Link>
          .
          <br />
          <br />
          To review our Terms of Use policy&nbsp;
          <Link
            href={'https://amercareroyal.com/pages/terms-and-conditions'}
            target={'_blank'}
            sx={{
              fontWeight: 'bold',
              color: acrColorBlue,
              textDecorationColor: acrColorBlue,
            }}
          >
            click here
          </Link>
          .
        </Typography>
      </Grid>
      <Grid item xs={12} md={6} mb={4}>
        <Typography sx={{ fontWeight: 'bold' }} mb={2}>
          Forgot Password?
        </Typography>

        <Grid container alignItems="center" mb={2}>
          <Grid item xs={12} md={4} pb={isMatch ? 1 : 0} pr={isMatch ? 0 : 1}>
            Email
          </Grid>
          <Grid item xs={12} md={8}>
            <BootstrapInput
              defaultValue={email}
              fullWidth={true}
              disabled={true}
            />
          </Grid>
        </Grid>
        <Grid container alignItems="center" mb={2}>
          <Grid item xs={12} md={4} pb={isMatch ? 1 : 0} pr={isMatch ? 0 : 1}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <span>{NEW_PASSWORD}</span>
              <CustomTooltip
                title={
                  <ul
                    style={{ margin: 0, paddingLeft: '15px', lineHeight: 1.5 }}
                  >
                    Password Requirements:
                    <li>Min length 6 characters</li>
                    <li>1 special character</li>
                    <li>1 numeric digit</li>
                    <li>1 lowercase character</li>
                    <li>1 uppercase character</li>
                  </ul>
                }
              >
                <HelpIcon
                  sx={{
                    fontSize: '16px',
                    cursor: 'pointer',
                    color: acrColorGrey,
                  }}
                />
              </CustomTooltip>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Controller
              name={'password'}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <BootstrapInput
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!error}
                    fullWidth={true}
                    type={'password'}
                  />
                  {!!error && (
                    <FormHelperText error={!!error}>
                      {!!error ? error.message : ''}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
        </Grid>
        <Grid container alignItems={'center'} mb={2}>
          <Grid item xs={12} md={4} pb={isMatch ? 1 : 0} pr={isMatch ? 0 : 1}>
            {CONFIRM_NEW_PASSWORD}
          </Grid>
          <Grid item xs={12} md={8}>
            <Controller
              name={'confirm_password'}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <BootstrapInput
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!error}
                    fullWidth={true}
                    type={'password'}
                  />
                  {!!error && (
                    <FormHelperText error={!!error}>
                      {!!error ? error.message : ''}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid
            item
            xs={true}
            sx={{ mt: 2 }}
            container
            justifyContent="flex-end"
          >
            <LoadingButton
              variant="contained"
              size="large"
              fullWidth={isMatch}
              onClick={handleSubmit(onSubmit)}
              loading={changePassword.isLoading}
            >
              {UPDATE_PASSWORD}
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ForgotPasswordConfirmForm
