import { Address, Bill } from '~/types/OrderDetailsData.model'
import { formatCurrency } from '~/utils/helpers/helperFunctions'

export const fields = [
  { id: 'lineNumber', label: 'Line', minWidth: 30 },
  {
    id: 'orderedCount',
    label: 'Ordered',
    minWidth: 50,
    format: (value: number) => (value !== null ? value : '--'),
  },
  {
    id: 'shippedCount',
    label: 'Shipped',
    minWidth: 50,
    format: (value: number) => (value !== null ? value : '--'),
  },
  {
    id: 'loc',
    label: 'LOC',
    minWidth: 30,
    // align: 'right',
  },
  {
    id: 'itemNo',
    label: 'Item No',
    minWidth: 80,
    // align: 'right',
  },
  {
    id: 'description',
    label: 'Description',
    minWidth: 200,
    // align: 'right',
  },
  {
    id: 'unitOfMeasure',
    label: 'U/M',
    minWidth: 50,
    // align: 'right',
  },
  {
    id: 'packed',
    label: 'PKD',
    minWidth: 100,
    // align: 'right',
  },
  {
    id: 'discountPercent',
    label: 'Disc%',
    minWidth: 50,
    // align: 'right',
  },
  {
    id: 'price',
    label: 'Price',
    minWidth: 50,
    // align: 'right',
    format: (value: number) => formatCurrency(value),
  },
  {
    id: 'amount',
    label: 'Amount',
    minWidth: 100,
    // align: 'right',
    format: (value: number) => formatCurrency(value),
  },
]

export const orderSpecificContent = [
  {
    id: 'orderAdditionalInfo',
    items: [
      {
        title: 'Sold to',
        dataId: 'billingAddress',
        id: 'billingAddress',
        fields: [
          {
            id: 'billToLocation',
          },
          { id: 'billToName' },
          { id: 'addressLine1' },
          { id: 'addressLine2' },
          { id: 'addressLine3' },
          {
            id: 'city',
            format: ({ city, state, postcode }: Address) =>
              `${city}, ${state} ${postcode}`,
          },
          { id: 'country' },
        ],
      },
      {
        title: 'Ship to',
        dataId: 'shippingAddress',
        id: 'shippingAddress',
        fields: [
          {
            id: 'billToLocation',
            format: ({ billToLocation, shipToLocation }: Bill) =>
              `${billToLocation}${shipToLocation ? `-${shipToLocation}` : ''}`,
          },
          { id: 'shipToName' },
          { id: 'addressLine1' },
          { id: 'addressLine2' },
          { id: 'addressLine3' },
          {
            id: 'city',
            format: ({ city, state, postcode }: Address) =>
              `${city}, ${state} ${postcode}`,
          },
          { id: 'country' },
        ],
      },
      {
        title: 'Order Details',
        id: 'orderDetails',
        fields: [
          { name: 'Order Number', id: 'orderNumber' },
          { name: 'Customer PO', id: 'purchaseOrder' },
          { name: 'Payment Terms', id: 'paymentTerms' },
          { name: 'Order Status', id: 'orderStatus' },
          { name: 'Order Taker', id: 'orderTaker' },
        ],
      },
      {
        title: 'Shipping Details',
        id: 'shippingAddress',
        fields: [
          { name: 'Order Date', id: 'orderDate' },
          { name: 'Requested Delivery Date', id: 'reqDeliveryDate' },
          { name: 'Carrier', id: 'shipper' },
          { name: 'Tracking number', id: 'trackingNumber' },
        ],
      },
    ],
  },
]
