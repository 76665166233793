import { Fields } from '../types/Fields.model'
import { PriceFilter } from '../types/Filter.model'
import { PageParams } from '../types/PageParams.model'
import { PriceListsSummary } from '../types/PriceLists.model'
import { Method } from '../utils/allConstants/Constants'
import { downloadFile } from '../utils/helpers/helperFunctions'

import axiosInstance from './axiosInstance'
import { createQueryParams, getPreparedFilters } from './filters-utils'

export const fetchProducts = ({
  filters = {},
  fields,
  ...params
}: PageParams<PriceFilter>) => {
  // const qParams = createQParams(filters, fields)
  const createUrl = params?.groupBy
    ? '/v1/acr/products/grouped'
    : '/v1/acr/products'

  const preparedFilters = getPreparedFilters(filters, fields)

  const restparams = Object.keys(params)
    .filter((key) => key !== 'additionalFilters')
    .reduce((obj, key) => {
      obj[key] = params[key]
      return obj
    }, {})

  return axiosInstance
    .get<PriceListsSummary>(createUrl, {
      params: {
        ...restparams,
        ...preparedFilters,
      },
    })
    .then(({ data }) => data)
}

export const fetchFutureProducts = ({
  filters = {},
  fields,
  ...params
}: PageParams<PriceFilter>) => {
  // const qParams = createQParams(filters, fields)
  const createUrl = params?.groupBy
    ? '/v1/acr/products/futurePrices/grouped'
    : '/v1/acr/products/futurePrices'

  const preparedFilters = getPreparedFilters(filters, fields)

  const restparams = Object.keys(params)
    .filter((key) => key !== 'additionalFilters')
    .reduce((obj, key) => {
      obj[key] = params[key]
      return obj
    }, {})

  return axiosInstance
    .get<PriceListsSummary>(createUrl, {
      params: {
        ...restparams,
        ...preparedFilters,
      },
    })
    .then(({ data }) => data)
}

export const exportProductFile = ({
  extension,
  filters,
  fields,
  tab,
  ...params
}: {
  extension: string
  filters: PageParams<PriceFilter>
  fields: Fields[]
  tab: string
}) => {
  const url =
    tab === 'current'
      ? '/v1/acr/products/export'
      : '/v1/acr/products/futurePrices/export'
  const fileName =
    tab === 'current'
      ? `PriceList.${extension}`
      : `FuturePriceList.${extension}`
  const qParams = createQueryParams(filters, fields)
  const combinedParams: any = {
    ...params,
    ...qParams,
    type: extension,
  }
  return downloadFile(url, fileName, combinedParams, Method.GET)
}
