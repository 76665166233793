import { Link as RouterLink } from 'react-router-dom'

import { Grid, Link, Typography, useMediaQuery } from '@mui/material'

import Logo from '~/images/acr-logo.png'
import { theme } from '~/theme/AppTheme'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorBlue } = variables

const ForgotPasswordConfirmComplete = () => {
  const isMatch = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Grid
      container
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        textAlign: 'center',
      }}
    >
      <Grid item>
        {!isMatch && (
          <img
            src={Logo}
            alt={Logo}
            style={{ height: '100px', marginBottom: '16px' }}
          />
        )}

        <Typography variant="h1" mb={2}>
          Your Password has been successfully updated.
        </Typography>

        <Typography
          variant={'h4'}
          sx={{
            color: (theme) => theme.palette.text.secondary,
            py: 3,
            fontSize: 16,
          }}
        >
          Please&nbsp;
          <Link
            component={RouterLink}
            to={'/login'}
            sx={{
              fontWeight: 'bold',
              color: acrColorBlue,
              textDecorationColor: acrColorBlue,
            }}
          >
            click here
          </Link>
          &nbsp; to login.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ForgotPasswordConfirmComplete
