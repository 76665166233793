import { Container, Typography } from '@mui/material'

const AccountNotApproved = () => {
  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h1" mb={2}>
        Account Not Yet Approved
      </Typography>

      <Typography
        variant={'h4'}
        sx={{ color: (theme) => theme.palette.text.secondary, py: 2 }}
      >
        We have successfully received your account registration, but have not
        yet had a chance to approve your account.
        <br />
        You will receive an email when your account has been approved. We
        appreciate your patience.
      </Typography>
    </Container>
  )
}

export default AccountNotApproved
