import { FC, ReactElement } from 'react'

import { Grid, Typography } from '@mui/material'

import { formatCurrency } from '~/utils/helpers/helperFunctions'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorCyanWhite, acrColorGrey, acrColorDarkGrey } = variables

interface Props {
  balance: number
}

const Balance: FC<Props> = ({ balance }): ReactElement => {
  return (
    <Grid
      container
      sx={{
        borderRadius: '4px',
        padding: '5px 16px',
        margin: '20px 0px',
        backgroundColor: acrColorCyanWhite,
        justifyContent: 'end',
      }}
    >
      <Typography
        variant="h4"
        sx={{ mr: 3, color: acrColorGrey, fontWeight: 500 }}
      >
        Balance Due
      </Typography>
      <Typography
        variant="h4"
        sx={{ color: acrColorDarkGrey, fontWeight: 700 }}
      >
        {formatCurrency(balance)}
      </Typography>
    </Grid>
  )
}

export default Balance
