import { useContext } from 'react'
import { useParams } from 'react-router'

import { useQueries } from '@tanstack/react-query'

import { fetchAccountById, fetchAccountMetadata } from '~/services/accounts'

import Loading from '../UI/Loading'

import { AccountsContext } from './index'

const AccountLoader = ({ children }) => {
  const params = useParams()
  const accountsContext = useContext(AccountsContext)

  const queries = []
  if (!!params.accountId) {
    queries.push({
      queryKey: ['accounts', params.accountId],
      queryFn: fetchAccountById,
      onSuccess: (data) => accountsContext.setSelectedAccount(data),
    })
    queries.push({
      queryKey: ['accounts', params.accountId, 'metadata'],
      queryFn: fetchAccountMetadata,
    })
  }
  if (!!params.subAccountId) {
    queries.push({
      queryKey: ['subAccounts', params.subAccountId],
      queryFn: fetchAccountById,
      onSuccess: (data) => accountsContext.setSelectedSubAccount(data),
    })
  }
  const result = useQueries({
    queries,
  })

  if (result.some(({ isFetching }) => isFetching)) {
    return <Loading />
  }

  return children
}

export default AccountLoader
