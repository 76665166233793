import { FC, ReactElement } from 'react'
import { Control } from 'react-hook-form'

import { Typography } from '@mui/material'

import { ControllerStyledSelect } from './ModalInputs'

import './IssueSection.scss'

interface Props {
  name: string
  control: Control<any>
  items: any
}

const IssueSection: FC<Props> = ({ name, control, items }): ReactElement => {
  return (
    <>
      <div className="issue-section">
        <Typography variant="h5" fontWeight="bold">
          Please help us to identify the issue
        </Typography>
        <div className="issue-select">
          <ControllerStyledSelect name={name} control={control} items={items} />
        </div>
      </div>
    </>
  )
}

export default IssueSection
