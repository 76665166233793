import { PostComment } from '../types/Comment.model'
import { InvoiceFilter } from '../types/Filter.model'
import { Invoice, InvoiceSummary } from '../types/Invoice.model'
import { InvoiceDetailsData } from '../types/InvoiceDetailsData.model'
import { PageParams } from '../types/PageParams.model'

import axiosInstance from './axiosInstance'
import { getPreparedFilters } from './filters-utils'

export const fetchInvoices = ({
  filters = {},
  fields,
  ...params
}: PageParams<InvoiceFilter>) => {
  const filterParams = getPreparedFilters(filters, fields)

  return axiosInstance
    .get<InvoiceSummary>(`/v1/acr/search/invoice?`, {
      params: {
        ...params,
        ...filterParams,
      },
    })
    .then(({ data }) => {
      return {
        totalCount: data.totalCount,
        items: data.items.map((item: Invoice) => ({
          ...item,
          id: item.invoiceNumber,
        })),
      }
    })
}

export const fetchInvoiceDetailsData = (id: string) => {
  return axiosInstance
    .get<InvoiceDetailsData>(`/v1/acr/invoices/${id}`)
    .then(({ data }) => {
      return {
        ...data,
        orderItems: data.orderItems.map((item) => ({
          ...item,
          id: item.itemNo,
        })),
      }
    })
}

export const fetchInvoiceComments = (invoiceId: string) => {
  return axiosInstance
    .get<Comment[] | []>(`/v1/invoices/${invoiceId}/comments`)
    .then(({ data }) => {
      return data
    })
}

export const createInvoiceComment = ({ id: invoiceId, ...rest }: any) => {
  return axiosInstance
    .post<PostComment>(`/v1/invoices/${invoiceId}/comments`, { ...rest })
    .then(({ data }) => {
      return data
    })
}

export const fetchAllInvoiceComments = (invoiceNumbers: string[]) => {
  let url = `/v1/invoices/comments?invoiceIds=`
  url += invoiceNumbers.join(',')
  return axiosInstance.get<Comment[] | []>(url).then(({ data }) => {
    return data
  })
}
