import { FC, ReactElement, useEffect } from 'react'

import { Typography } from '@mui/material'

import DataGrid from '~/components/UI/DataGrid/DataGrid'
import useDataGridStore, {
  DataGridProvider,
} from '~/components/UI/DataGrid/DataGridContext'
import { fetchInvoices } from '~/services/invoices'
import { PAGE_TITLES, TEXTS } from '~/utils/allConstants/Constants'

import { fields as invoiceFields } from './components/CarrierInvoicesTabContent'

const { NO_DATA_MESSAGE } = TEXTS
const { INVOICES } = PAGE_TITLES

type Props = {
  filters: any
}

const SearchResultsInvoices: FC<Props> = ({ filters }): ReactElement => {
  const { setTableProps, tableProps } = useDataGridStore()

  const table = {
    name: 'search_invoices',
    additionalFilters: filters,
    source: fetchInvoices,
    fields: invoiceFields,
    notFoundMessage: NO_DATA_MESSAGE('Invoices'),
  }

  useEffect(() => {
    setTableProps(table)
  }, [filters])

  if (Object.keys(tableProps).length === 0) {
    return null
  }

  return (
    <>
      <Typography variant="h5" ml="20px" mt="20px">
        {INVOICES}
      </Typography>
      <DataGrid />
    </>
  )
}

// eslint-disable-next-line react/display-name
export default (props: Props) => {
  return (
    <DataGridProvider>
      <SearchResultsInvoices {...props} />
    </DataGridProvider>
  )
}
