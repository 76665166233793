import * as React from 'react'

import { LoadingButton } from '@mui/lab'
import { Button, Grid } from '@mui/material'
import Box from '@mui/material/Box'

import { BannerPreview } from '~/components/Manage/BannerNotification/BannerNotificationForm/component/BannerPreview'
import { CustomerAlert } from '~/types/CustomerAlert.model'

type Props = {
  bannerFormData: CustomerAlert
  closeModal?: () => void
  onDelete: (alertId: string) => void
  isLoading: boolean
}

const BannerPreviewModalBody: React.FC<Props> = ({
  bannerFormData,
  closeModal,
  onDelete,
  isLoading,
}): React.ReactElement => {
  return (
    <Box
      sx={{
        position: 'relative',
        top: '1%',
        textAlign: 'left',
        p: 2,
      }}
    >
      <BannerPreview bannerFormData={bannerFormData} />
      <Grid container>
        <Grid item xs={12} mt={4} display={'flex'} justifyContent={'center'}>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            sx={{
              borderRadius: 0,
              margin: '5px',
            }}
            onClick={() => onDelete(bannerFormData.id)}
          >
            DELETE
          </LoadingButton>
          <Button
            variant={'outlined'}
            sx={{
              borderRadius: 0,
              paddingLeft: 5,
              paddingRight: 5,
              margin: '5px',
              fontSize: '14px',
              width: '100px',
            }}
            onClick={closeModal}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default BannerPreviewModalBody
