import { FC, ReactElement } from 'react'

import { SvgIcon } from '@mui/material'

interface Props {
  color: string
}

const CommentIcon: FC<Props> = ({ color }): ReactElement => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17 10H6V8H17V10Z" fill={color} />
        <path d="M6 15H13V13H6V15Z" fill={color} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.5 20H20C21.1046 20 22 19.1046 22 18V5C22 3.89543 21.1046 3 20 3H4C2.89543 3 2 3.89543 2 5V23L5.5 20ZM4 18.6516L4.76015 18H20V5H4V18.6516Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  )
}

export default CommentIcon
