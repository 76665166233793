import { FC, ReactElement, useEffect, useState } from 'react'
import { Control, UseFormSetValue } from 'react-hook-form'
import { v4 as uuid } from 'uuid'

import { Grid, MenuItem } from '@mui/material'

import ControllerStyledSelect from '~/components/Form/StyledSelect'
import ControlledStyledTextField from '~/components/Form/StyledTextField'
import Loading from '~/components/UI/Loading'
import { ReturnItemList } from '~/types/Approval.model'
import { InternalReasons } from '~/types/Return.model'
import { ReturnItemPickupOptions, TEXTS } from '~/utils/allConstants/Constants'

import './ReviewReturnRequestDetailsInput.scss'

type Props = {
  control: Control<any>
  index: number
  row: ReturnItemList
  setValue: UseFormSetValue<any>
  internalReasonCodes: InternalReasons
}

const ReviewReturnRequestDetailsInput: FC<Props> = ({
  control,
  index,
  row,
  setValue,
  internalReasonCodes,
}): ReactElement => {
  const internalReason =
    internalReasonCodes && row.internalReason
      ? internalReasonCodes[
          row.internalReason as keyof typeof internalReasonCodes
        ]
      : ''

  const pickUpInformation =
    ReturnItemPickupOptions && row.pickUpInformation
      ? ReturnItemPickupOptions[
          row.pickUpInformation as keyof typeof ReturnItemPickupOptions
        ]
      : ''

  const [selectedInternalReason, setSelectedInternalReason] =
    useState(internalReason)
  const isMisPickSelected = selectedInternalReason === TEXTS.WAREHOUSE_MISPICK

  useEffect(() => {
    if (internalReasonCodes != undefined) {
      setValue(`items.${index}.productId`, row.productId)
      setValue(`items.${index}.reason`, row.reason)
      setValue(`items.${index}.lineNumber`, row.lineNumber)
      setValue(`items.${index}.internalReason`, internalReason)
      setValue(
        `items.${index}.comment`,
        row.comment === null ? '' : row.comment
      )
      setValue(`items.${index}.receivedItemNumber`, row.receivedItemNumber)
      setValue(`items.${index}.pickUpInformation`, pickUpInformation)
    }
  }, [internalReasonCodes, row, ReturnItemPickupOptions])

  if (internalReasonCodes === undefined) {
    return <Loading />
  }
  const sortedInternalReasonCode = Object.values(internalReasonCodes).sort()
  const sortedPickupOptions = Object.values(ReturnItemPickupOptions).sort()

  return (
    <Grid
      container
      spacing={2}
      sx={{ minWidth: 250, alignItems: 'flex-start' }}
    >
      <Grid item xs={6} sm={6} md={5}>
        <ControllerStyledSelect
          name={`items.${index}.internalReason`}
          control={control}
          className="margin-none"
          placeholder="Please select internal reason"
          onCustomChange={({ target }: React.MouseEvent) => {
            setSelectedInternalReason((target as HTMLSelectElement).value)
          }}
        >
          {sortedInternalReasonCode.map((value) => {
            return (
              <MenuItem key={uuid()} value={value}>
                {value}
              </MenuItem>
            )
          })}
        </ControllerStyledSelect>
        {isMisPickSelected && (
          <ControlledStyledTextField
            name={`items.${index}.receivedItemNumber`}
            control={control}
            className="margin-none item-number"
            placeholder="Enter received item no."
          />
        )}
      </Grid>
      <Grid item xs={6} sm={6} md={7}>
        <ControlledStyledTextField
          name={`items.${index}.comment`}
          control={control}
          className="margin-none"
          placeholder="Enter comments"
        />
        {isMisPickSelected && (
          <ControllerStyledSelect
            name={`items.${index}.pickUpInformation`}
            control={control}
            placeholder="Please select pickup info"
            className="margin-none pickup-dropdown"
          >
            {sortedPickupOptions.map((value) => {
              return (
                <MenuItem key={uuid()} value={value}>
                  {value}
                </MenuItem>
              )
            })}
          </ControllerStyledSelect>
        )}
      </Grid>
    </Grid>
  )
}

const ReviewReturnRequestDetailsInputHeader = () => {
  return (
    <Grid container spacing={2} sx={{ minWidth: 250, alignItems: 'center' }}>
      <Grid item xs={6} sm={6} md={5}>
        Internal Reason Code
      </Grid>
      <Grid item xs={6} sm={6} md={7}>
        Comments
      </Grid>
    </Grid>
  )
}

export { ReviewReturnRequestDetailsInputHeader }

export default ReviewReturnRequestDetailsInput
