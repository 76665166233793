import { FC, ReactElement, useState } from 'react'
import { NavLink } from 'react-router-dom'

import LogoutIcon from '@mui/icons-material/Logout'
import MenuIcon from '@mui/icons-material/Menu'
import PersonIcon from '@mui/icons-material/Person'
import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
} from '@mui/material'

import UserTab from '~/components/Manage/Users/UserTab'
import { useAuth } from '~/services/auth'
import { TEXTS } from '~/utils/allConstants/Constants'

import { HeaderFilter, HeaderItem, IUserNameProps } from '../Header'
import { MobileMenus } from '../MenuData'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorGrey } = variables
const { LOGOUT } = TEXTS

interface Props {
  title: string
  handleLogout: () => void
  handleMenu: () => void
  UserName: FC<IUserNameProps>
}

const DrawerComp: FC<Props> = ({
  UserName,
  handleMenu,
  handleLogout,
  title,
}): ReactElement => {
  const { data: user } = useAuth()
  const [openDrawer, setOpenDrawer] = useState(false)
  const toggleDrawer = () => setOpenDrawer((prevState) => !prevState)
  const style = {
    width: '100%',
    maxWidth: 360,
  }

  return (
    <>
      <Drawer open={openDrawer} onClose={toggleDrawer}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Link to={'/'} component={NavLink}>
            <img
              src={title}
              alt={title}
              style={{
                width: '210px',
                marginTop: '20px',
              }}
            />
          </Link>
        </Box>
        <Button
          variant="text"
          color="secondary"
          onClick={() => {
            handleMenu()
            toggleDrawer()
          }}
          startIcon={<PersonIcon />}
          sx={{
            fontWeight: 'bold',
            textTransform: 'none',
            fontSize: 21,
            padding: '20px 0px 20px 0px',
            justifyContent: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            maxWidth: 250,
          }}
          size="large"
        >
          <UserName user={user} />
        </Button>

        <Divider />
        <Box p={1}>
          <UserTab toggleDrawer={toggleDrawer} />
        </Box>
        <Divider />

        <List sx={style} component="nav" aria-label="mailbox folders">
          {MobileMenus.filter((page) => HeaderFilter({ page, user })).map(
            (list, index) => (
              <HeaderItem
                key={index}
                page={list}
                toggleDrawer={toggleDrawer}
                icon={<list.icon sx={{ m: '5px' }} />}
              />
            )
          )}
        </List>
        <Box>
          <Button
            variant="text"
            color="secondary"
            onClick={handleLogout}
            startIcon={
              <LogoutIcon sx={{ transform: 'scaleX(-1)' }} fontSize="small" />
            }
            sx={{
              fontWeight: 'bold',
              textTransform: 'none',
              fontSize: '24px',
              position: 'relative',
              bottom: '0',
              color: acrColorGrey,
              margin: '5px 20% 5px 20%',
            }}
          >
            {LOGOUT}
          </Button>
        </Box>
      </Drawer>
      {!openDrawer && (
        <IconButton
          onClick={toggleDrawer}
          sx={{ position: 'fixed', right: '16px' }}
        >
          <MenuIcon
            sx={{
              color: '#161f6e',
            }}
          />
        </IconButton>
      )}
    </>
  )
}

export default DrawerComp
