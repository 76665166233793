import { FC, ReactElement } from 'react'

import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'

import { theme } from '~/theme/AppTheme'

interface Props {
  data: any
  cardProps: any
}

const DashboardCard: FC<Props> = ({ data, cardProps }): ReactElement => {
  const isMatch = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Card
      sx={{
        [theme.breakpoints.down('md')]: {
          width: '100%',
        },
      }}
    >
      <CardHeader
        disableTypography={true}
        action={
          cardProps?.headerAction && (
            <IconButton
              color={'primary'}
              onClick={() => cardProps.headerAction(cardProps.id)}
            >
              <OpenInNewIcon />
            </IconButton>
          )
        }
        title={
          <Typography
            sx={{ fontSize: 24, textWeight: 'bold' }}
            color="text.primary"
            gutterBottom
          >
            {cardProps.title}
          </Typography>
        }
        subheader={
          cardProps.cardSubTitle &&
          cardProps.cardSubTitle(
            cardProps.currentAccount,
            cardProps.currentSubAccount
          )
        }
      />
      <CardContent>
        <Stack
          direction="row"
          sx={{ justifyContent: 'space-between' }}
          spacing={1}
        >
          {cardProps.cardContent.map((cardContentItem: any) => (
            <Box key={cardContentItem.label}>
              <Box sx={{ textAlign: 'center', fontSize: isMatch ? 14 : 18 }}>
                {cardContentItem.label}
              </Box>
              {!!data && (
                <Box
                  sx={{
                    textAlign: 'center',
                    textWeight: 'bold',
                    fontSize: isMatch ? 24 : 30,
                  }}
                >
                  {cardContentItem.hasOwnProperty('format')
                    ? cardContentItem.format(data[cardContentItem.id])
                    : data[cardContentItem.id]}
                </Box>
              )}
            </Box>
          ))}
        </Stack>
      </CardContent>
      {cardProps?.cardActions && cardProps.cardActions()}
    </Card>
  )
}

export default DashboardCard
