import {
  FC,
  Fragment,
  MouseEvent,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from 'react'
import cn from 'classnames'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import LogoutIcon from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import {
  AppBar,
  Button,
  Collapse,
  Divider,
  IconButton,
  Link,
  ListItemButton,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import Logo from '~/images/acr-logo.png'
import { useAuth, useLogoutMutation } from '~/services/auth'
import { RoleTypes } from '~/services/users'
import { Account, SubAccount } from '~/types/Account.model'
import { CurrentUser } from '~/types/User.model'
import {
  GrantedClaims,
  HEADER_HEIGHT,
  JobTitles,
  MENUS,
  PAGE_TITLES,
  TEXTS,
} from '~/utils/allConstants/Constants'
import { convertPageToLink, hasChildren } from '~/utils/helpers/helperFunctions'

import { AccountsContext } from '../Accounts'

import DrawerComp from './Drawer/DrawerComp'
import { Item, Page, Pages } from './MenuData'

import './Header.scss'
import variables from '~/theme/scss/_variables.module.scss'

const { acrColorPrimary, acrColorGrey, acrColorWhite, acrColorNavyBlue } =
  variables

const { APPROVALS, INVOICES, ORDERS, PRICE_LIST, REPORTS, RETURNS } =
  PAGE_TITLES
const { PRICE_CHANGE_HISTORY } = MENUS
const { LOGIN, NEW_USER, CARRIER_REGISTRATION } = TEXTS

type ListItem = {
  item: Page
  icon: any
}

export interface IUserNameProps {
  user: CurrentUser
}

const UserName: FC<IUserNameProps> = ({ user }): ReactElement | any => {
  if (user.firstName === '' && user.lastName === '') {
    return user.contactEmail
  }
  if ([RoleTypes.CARRIER].includes(user.role)) {
    return `${user.firstName} ${user.lastName} - ${user.company}`
  }
  return `${user.firstName} ${user.lastName}`
}

export const HeaderFilter = ({
  page,
  user,
}: {
  page: Page
  user: CurrentUser
}) => {
  {
    switch (user.role) {
      case RoleTypes.ADMIN: {
        {
          if (page.name == REPORTS) {
            if (!user.claims.includes(GrantedClaims.REPORTS)) {
              return false
            }
          }
          return true
        }
      }

      case RoleTypes.CUSTOMER: {
        if (page.name === APPROVALS) {
          return false
        }
        if (page.name == REPORTS) {
          if (!user.claims.includes(GrantedClaims.REPORTS)) {
            return false
          }
          return true
        }
        return true
      }

      case RoleTypes.EMPLOYEE: {
        if (page.name == APPROVALS) {
          if (
            [
              JobTitles.CUSTOMER_SERVICE_REPRESENTATIVE,
              JobTitles.CUSTOMER_SERVICE_SUPERVISOR,
              JobTitles.CUSTOMER_SERVICE_DIRECTOR,
              JobTitles.VP_OF_NATIONAL_ACCOUNT_SALES,
              JobTitles.VP_OF_DISTRIBUTION_SALES,
            ].includes(user.jobTitle)
          ) {
            return true
          }
          return false
        }
        if (page.name == REPORTS) {
          if (!user.claims.includes(GrantedClaims.REPORTS)) {
            return false
          }
          return true
        }
        return true
      }

      case RoleTypes.BROKER: {
        if (page.name === APPROVALS) {
          return false
        }
        if (page.name == REPORTS) {
          if (!user.claims.includes(GrantedClaims.REPORTS)) {
            return false
          }
          return true
        }
        return true
      }

      case RoleTypes.CARRIER: {
        if (page.name == RETURNS) {
          return true
        }
        return false
      }
      default:
        return true
    }
  }
}

export const HeaderItem: FC<{
  page: Page
  toggleDrawer?: () => void
  icon?: ReactElement
}> = ({ page, toggleDrawer, icon }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const urlArray = pathname.slice(1).split('/')
  const isCurrentPageActive =
    (urlArray.includes(INVOICES.toLowerCase()) && page.name === INVOICES) ||
    (urlArray.includes(ORDERS.toLowerCase()) && page.name === ORDERS) ||
    (urlArray.includes(RETURNS.toLowerCase()) && page.name === RETURNS) ||
    (urlArray.includes(APPROVALS.toLowerCase()) && page.name === APPROVALS) ||
    (urlArray.includes(REPORTS.toLowerCase()) && page.name === REPORTS)
  const firstItemFromArrayURL = urlArray[0]
  const secondItemFromArrayURL = urlArray[1]
  const approvalUrl = `${firstItemFromArrayURL}/${secondItemFromArrayURL}`
  const [selectedUrl, setSelectedUrl] = useState(approvalUrl)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  useEffect(() => {
    setSelectedUrl(approvalUrl)
  }, [approvalUrl])

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const approvalNavigation = (path: string) => {
    navigate(path)
    setAnchorEl(null)
    setSelectedUrl(path)
    return true
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const theme = useTheme()
  const isMatch = useMediaQuery(theme.breakpoints.down('md'))
  const isDesktop = useMediaQuery(theme.breakpoints.down('lg'))
  const accounts =
    useContext(AccountsContext) ||
    ({} as {
      selectedAccount: Account
      selectedSubAccount: SubAccount
    })

  const link: string[] = []

  const CheckAccountLink = (
    pageTitle: string[],
    pageName: string,
    link: string[]
  ) => {
    if (!pageTitle.includes(pageName)) {
      return
    }
    if ([null, undefined].includes(accounts.selectedAccount)) {
      return
    }
    link.push(accounts.selectedAccount.id)
    if ([null, undefined].includes(accounts.selectedSubAccount)) {
      return
    }
    link.push(accounts.selectedSubAccount.id)
  }

  CheckAccountLink([INVOICES, ORDERS, PRICE_LIST, RETURNS], page.name, link)

  link.push(convertPageToLink(page.name))

  const MenuItemList = ({ item, icon }: ListItem) => {
    const Component = hasChildren(item) ? MultiLevel : SingleLevel
    return <Component item={item} icon={icon} />
  }

  const SingleLevel = ({ item, icon }: ListItem) => {
    return (
      <ListItemButton
        divider
        to={link.join('/')}
        onClick={toggleDrawer}
        component={NavLink}
        sx={{
          '&.active': {
            color: acrColorNavyBlue,
            fontWeight: '700',
            bgcolor: 'lightgrey',
          },
          color: acrColorGrey,
          fontWeight: '500',
          textTransform: 'capitalize',
          width: '250px',
        }}
      >
        {icon}
        <Typography sx={{ fontSize: '20px', fontWeight: '500' }}>
          {item.name}
        </Typography>
      </ListItemButton>
    )
  }

  const MultiLevel = ({ item, icon }: ListItem) => {
    const { menuItem: children } = item
    const [open, setOpen] = useState(false)

    const handleClick = () => {
      setOpen((prev) => !prev)
    }

    return (
      <Fragment>
        <ListItemButton
          divider
          onClick={handleClick}
          sx={[
            isCurrentPageActive
              ? {
                  color: acrColorNavyBlue,
                  fontWeight: '700',
                  textTransform: 'capitalize',
                  bgcolor: 'lightgrey',
                }
              : {
                  color: acrColorGrey,
                  fontWeight: '500',
                  textTransform: 'capitalize',
                  width: '250px',
                },
          ]}
        >
          {icon}
          <Typography sx={{ fontSize: '20px', fontWeight: '500' }}>
            {item.name}
          </Typography>
          {open ? (
            <ExpandLessIcon sx={{ marginLeft: 'auto' }} />
          ) : (
            <ExpandMoreIcon sx={{ marginLeft: 'auto' }} />
          )}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children.map((child: Item, key: number) => {
            const childLink: string[] = []
            CheckAccountLink([PRICE_CHANGE_HISTORY], child.name, childLink)

            childLink.push(convertPageToLink(page.name))
            childLink.push(convertPageToLink(child.name))
            return (
              <ListItemButton
                key={key}
                divider
                to={childLink.join('/')}
                onClick={toggleDrawer}
                component={NavLink}
                sx={{
                  '&.active': {
                    color: acrColorNavyBlue,
                    fontWeight: '700',
                    bgcolor: 'lightgrey',
                  },
                  color: acrColorGrey,
                  fontWeight: '500',
                  textTransform: 'capitalize',
                  width: '250px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: '500',
                    marginLeft: '35px',
                  }}
                >
                  {child.name}
                </Typography>
              </ListItemButton>
            )
          })}
        </Collapse>
      </Fragment>
    )
  }

  if (isMatch) {
    return <MenuItemList item={page} icon={icon} />
  } else {
    return (
      <>
        {!page.menuItem && (
          <Button
            component={NavLink}
            to={link.join('/')}
            className={cn('top-simple-item', isDesktop && 'margin-left-none')}
            sx={{
              '&.active': {
                color: acrColorNavyBlue,
                fontWeight: 'bold',
              },
              color: acrColorGrey,
              textTransform: 'capitalize',
            }}
          >
            {page.name}
          </Button>
        )}

        {page.menuItem && (
          <>
            <Button
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              className={cn(
                'top-dropdown-item',
                isDesktop && 'margin-left-none'
              )}
              sx={[
                isCurrentPageActive
                  ? {
                      color: acrColorNavyBlue,
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                    }
                  : { color: acrColorGrey, textTransform: 'capitalize' },
              ]}
            >
              {page.name}
              {anchorEl ? (
                <ExpandLessIcon
                  sx={[
                    { mr: -1 },
                    isCurrentPageActive
                      ? {
                          color: acrColorNavyBlue,
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                        }
                      : { color: acrColorGrey },
                  ]}
                />
              ) : (
                <ExpandMoreIcon
                  sx={[
                    { mr: -1 },
                    isCurrentPageActive
                      ? {
                          color: acrColorNavyBlue,
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                        }
                      : { color: acrColorGrey },
                  ]}
                />
              )}
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={
                [isCurrentPageActive] && {
                  color: acrColorNavyBlue,
                  fontWeight: 'bold',
                }
              }
            >
              {page.menuItem.map((child: any, index: string) => {
                const childLink: string[] = []
                CheckAccountLink([PRICE_CHANGE_HISTORY], child.name, childLink)

                childLink.push(convertPageToLink(page.name))
                childLink.push(convertPageToLink(child.name))

                const isSelectedActive = () => {
                  if (
                    accounts.selectedAccount?.id === firstItemFromArrayURL &&
                    accounts.selectedSubAccount?.id === secondItemFromArrayURL
                  ) {
                    return (
                      JSON.stringify(urlArray) === JSON.stringify(childLink)
                    )
                  } else if (
                    accounts.selectedAccount?.id === firstItemFromArrayURL
                  ) {
                    return (
                      JSON.stringify(urlArray) === JSON.stringify(childLink)
                    )
                  } else {
                    return selectedUrl === childLink.join('/')
                  }
                }
                return (
                  <MenuItem
                    key={index + child.name}
                    onClick={() => approvalNavigation(childLink.join('/'))}
                    selected={isSelectedActive()}
                    sx={{
                      '&.Mui-selected': {
                        color: acrColorNavyBlue,
                        fontWeight: 'bold',
                      },
                    }}
                  >
                    {child.name}
                  </MenuItem>
                )
              })}
            </Menu>
          </>
        )}
      </>
    )
  }
}

const Header: FC = (): ReactElement => {
  const navigate = useNavigate()
  const logoutMutation = useLogoutMutation()
  const { data: user } = useAuth()
  const { pathname } = useLocation()
  const base = `/${pathname.slice(1).split('/').shift()}`
  const theme = useTheme()
  const isMatch = useMediaQuery(theme.breakpoints.down('md'))

  const isNotLoginPage = pathname !== '/login'
  const isNotRegisterPage = pathname !== '/register'
  const isNotProductionEnvironment = process.env.APP_ENV != 'prod'
  const isNotCarrierRegisterPage = pathname !== '/new-carrier-register'

  const handleMenu = () => {
    navigate('/manage/profile')
  }

  const handleLogout = () => {
    logoutMutation.mutate(null, {
      onSuccess: () => {
        window.location.href = '/login'
      },
    })
  }

  const displayDesktop = () => {
    return (
      <Toolbar
        sx={{
          [theme.breakpoints.down('md')]: {
            minHeight: '50px',
            px: '16px',
          },
        }}
      >
        <Link component={NavLink} to={'/'}>
          <img
            src={Logo}
            alt={Logo}
            style={{
              height: `${HEADER_HEIGHT / (isMatch ? 1.5 : 1)}px`,
              marginRight: '10px',
              verticalAlign: 'top',
            }}
          />
        </Link>
        {user && (
          <>
            {isMatch ? (
              <DrawerComp
                UserName={UserName}
                handleMenu={handleMenu}
                handleLogout={handleLogout}
                title={Logo}
              />
            ) : (
              <>
                <Stack spacing={3} direction="row" sx={{ marginLeft: 'auto' }}>
                  {Pages.filter((page) => HeaderFilter({ page, user })).map(
                    (page, index) => {
                      return <HeaderItem key={index} page={page} />
                    }
                  )}
                </Stack>
                <Stack
                  spacing={2}
                  direction={'row'}
                  sx={[
                    {
                      marginLeft: 'auto',
                    },
                    user.impersonate && {
                      background: '#2e7d32',
                    },
                  ]}
                >
                  <IconButton
                    aria-label="logout"
                    size="small"
                    onClick={handleLogout}
                    sx={{
                      color: user.impersonate ? acrColorWhite : acrColorGrey,
                      width: '37px',
                      height: '37px',
                    }}
                  >
                    <LogoutIcon
                      sx={{
                        transform: 'scaleX(-1)',
                      }}
                      fontSize="small"
                    />
                  </IconButton>
                  <Button
                    variant="text"
                    onClick={handleMenu}
                    endIcon={
                      user.impersonate ? (
                        <SupervisorAccountIcon />
                      ) : (
                        <PersonIcon />
                      )
                    }
                    className="margin-left-none"
                    sx={{
                      color:
                        base == '/manage'
                          ? user.impersonate
                            ? acrColorWhite
                            : acrColorNavyBlue
                          : user.impersonate
                          ? acrColorWhite
                          : acrColorGrey,
                      fontWeight: 'normal',
                      textTransform: 'none',
                    }}
                  >
                    <UserName user={user} />
                  </Button>
                </Stack>
              </>
            )}
          </>
        )}

        {!user && (
          <Stack
            spacing={1}
            direction="row"
            sx={{ marginLeft: 'auto', textAlign: 'center' }}
          >
            {isNotRegisterPage && (
              <Button
                component={NavLink}
                to={'/register'}
                sx={{
                  marginLeft: 'auto',
                  '&.active': {
                    color: acrColorNavyBlue,
                    fontWeight: '700',
                  },
                }}
                variant="text"
                color="secondary"
              >
                {NEW_USER}
              </Button>
            )}

            {isNotCarrierRegisterPage &&
              isNotRegisterPage &&
              isNotProductionEnvironment && (
                <Divider orientation="vertical" variant="middle" flexItem />
              )}

            {isNotCarrierRegisterPage && isNotProductionEnvironment && (
              <Button
                component={NavLink}
                to={'/new-carrier-register'}
                sx={{
                  marginLeft: 'auto',
                  '&.active': {
                    color: acrColorNavyBlue,
                    fontWeight: '700',
                  },
                }}
                variant="text"
                color="secondary"
              >
                {CARRIER_REGISTRATION}
              </Button>
            )}

            {(!isNotRegisterPage || (isNotLoginPage && isNotRegisterPage)) && (
              <Divider orientation="vertical" variant="middle" flexItem />
            )}

            {isNotLoginPage && (
              <>
                <Button
                  component={NavLink}
                  to={'/login'}
                  sx={{
                    marginLeft: 'auto',
                    '&.active': {
                      color: acrColorNavyBlue,
                      fontWeight: '700',
                    },
                  }}
                  variant="text"
                  color="secondary"
                >
                  {LOGIN}
                </Button>
              </>
            )}
          </Stack>
        )}
      </Toolbar>
    )
  }

  return (
    <AppBar
      sx={{
        padding: '15px 0px',
        background: 'linear-gradient(180deg, #FFFFFF 54.17%, #F6F7FF 100%);',
        boxShadow: 'none',
        borderBottom: '1px solid',
        borderBottomColor: acrColorPrimary,
        boxSizing: 'border-box',
        [theme.breakpoints.down('md')]: {
          padding: '7px 0px',
        },
      }}
    >
      {displayDesktop()}
    </AppBar>
  )
}

export default Header
