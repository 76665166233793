export const DIVISION_NAMES = [
  'Aluminum Foils',
  'Bags',
  'Bamboo',
  'Chafing Fuel',
  'Consumables',
  'Containers',
  'Credits',
  'Eco Sleeves',
  'Filters & Accessories',
  'Foam Cups & Lids',
  'Gloves',
  'Guest Checks',
  'Headware & Hair Restraints',
  'Holdaplate',
  'Janitorial Products',
  'Kids Program',
  'Legacy Products',
  'Miscellaneous',
  'Pads & Screens',
  'Palm Leaf',
  'Pan Liners',
  'Paper Cups & Lids',
  'Paper Goods',
  'Plastic Cups & Containers',
  'Plastic Products',
  'Point Of Sale',
  'Primeware Compostable',
  'Protective Wear',
  'Raw Materials',
  'Special Print Beer Coasters',
  'Take Out Packaging',
  'Towels & Wipers',
  'Woodenware',
]

export const CLASS_NAMES = [
  'Alumininum Product Containers',
  'Aluminum Foil Rolls & Sheets',
  'Aluminum Pans, Trays & Lids',
  'Apron Containers',
  'Aprons',
  'Arm Guards',
  'Ashtrays',
  'Asian Foods',
  'Baby Wipes',
  'Bagasse Food Containers',
  'Bagasse Packaging',
  'Baking Cups',
  'Bamboo Item Containers',
  'Bath Tissue Containers',
  'Bathmats',
  'Beard Protectors',
  'Beverage Napkins',
  'Bibs - Paper Bibs',
  'Bibs - Poly',
  'Black Stripping Pads',
  'Blue Poly Glove 500 Pack',
  'Bmsp',
  'Bulk Eco Sleeves',
  'Bun Rack Covers And Bags',
  'Business & Computer Forms',
  'Butcher, Freezer, Kraft Paper',
  'Can Liners - Trash Bags',
  'Caps - Beanie Caps',
  'Caps - Classy Cap',
  'Caps - Or/Bouffant Caps',
  'Caps - Overseas Cap',
  'Caps - Royal',
  'Cast Poly Pf Gloves',
  'Catalogs & Marketing Materials',
  'Catch All - Direct Source',
  'Chafing Fuel',
  'Chopholders',
  'Chopsticks - Bamboo',
  'Cleaning Cards/Pos Products',
  'Clear Cold Cups',
  'Cleargauge',
  'Coaster Dispensers',
  'Coasters - Beer Coasters',
  'Coasters - Catch-All',
  'Coasters - Non-Woven Coasters',
  'Coasters - Royal-B Coasters',
  'Cocktail Plate - Bamboo Leaf',
  'Combination Pad/Sponge',
  'Compostable Cutlery',
  'Compostable Hot Cups',
  'Compostable Portion Cups',
  'Copy Paper & Specialty Sheets',
  'Corrugated  Take Out  Box',
  'Corrugated Boxes Packaging',
  'Corrugated Cake Circles',
  'Corrugated Cake Circles Cnt',
  'Counterfeit Detection',
  'Coveralls',
  'Credits',
  'Cup Carriers',
  'Cup Containers',
  'Cup Sleeves',
  'Custom Ecosleeve',
  'Custom Plastic Expendables',
  'Custom Print Crayons',
  'Custom Print Kids & Spec Cups',
  'Custom Print Placemats',
  'Custom Products',
  'Custom Take Out Packaging',
  'Customer Specific Specialty',
  'Cutlery B600 Bulk Hw Psm',
  'Cutlery Containers',
  'Cutlery P203 Bulk Mw Pp',
  'Cutlery P203 Wrapped Med Pp',
  'Cutlery P405 Bulk Med Hw Pp',
  'Cutlery P405 Wrapped Med Hw Pp',
  'Cutlery P505 Bulk Hw Pp',
  'Cutlery P505 Wrapped Hw Pp',
  'Cutlery Polypro Meal Kits',
  'Cutlery Polystyrene Meal Kits',
  'Cutlery Psm Meal Kits',
  'Cutlery S203 Bulk Mw Ps',
  'Cutlery S308 Bulk Med Heavy Ps',
  'Cutlery S308 Wrapped Med Hw Ps',
  'Cutlery S601 Bulk Hw Ps',
  'Cutlery S601 Retail Hw Ps',
  'Cutlery S601 Retail Pack Hw Ps',
  'Cutlery S601 Wrapped Hw Ps',
  'Cutlery S602 Bulk Hw Ps',
  'Cutlery S602 Wrapped Hw Ps',
  'Cutlery S603 Bulk Xhw Ps',
  'Cutlery S603 Wrapped Xhw Ps',
  'Cutlery Utensils & Accessories',
  'Diamond Plate',
  'Diatomaceous Earth',
  'Dinner Napkins',
  'Dinnerware',
  'Disinfectant Wipe',
  'Dispenser Napkins',
  'Dispensers - Headware',
  'Doilies - Glassine',
  'Doilies - Lace',
  'Doilies - Paper',
  'Eclair Cups',
  'Elbow Length Poly Gloves',
  'Exam Grade Latex Gloves Pf',
  'Exam Grade Nitrile Gloves Pf',
  'Exam Grade Synthetic Gloves Pf',
  'Face Masks',
  'Filter Cone Holders',
  'Filter Cones',
  'Filter Discs',
  'Filter Envelopes',
  'Filter Sheets',
  'Fingerbowl Containers',
  'Foam Cups & Lids',
  'Folded Paper Take Out Boxes',
  'Folded Paper Takeout Box Cnt',
  'Folded Towels - Janitorial',
  'Food Safety Labels',
  'Food Storage Bags',
  'Food Storage Bags Containers',
  'Food Trays',
  'Foodshells',
  'Forks',
  'Frills',
  'Frydelite',
  'Gel Chafing Fuel',
  'Gingham Check Table Covers',
  'Glove Dispensers',
  'Grill Brick',
  'Grill Pads',
  'Grill Screens',
  'Guest Check Containers',
  'Guest Checks - Single Copy',
  'Hairnets - Heavy Duty Hairnets',
  'Hairnets -Lightweight Hairnets',
  'Hats - Flair Style Hats',
  'Hats - Flat Pack Hats',
  'Hats - Royal Chef Hats',
  'Hats - Stirling Chef Hats',
  'Headwear - Stirling Brand',
  'Headwear Containers',
  'Heavy Duty Scouring Pads',
  'Hinged Lid',
  'Holders',
  'Hot Food Containers',
  'Hotel & Motel Products',
  'Household Glove Containers',
  'Household Gloves',
  'Hybrid Glove Containers',
  'Hybrid Pf Gloves',
  'Inventory',
  'Isolation Gowns',
  'Janitorial Paper Dispensers',
  "Jerry'S Placemat",
  'Kids Crayons And Placemats',
  'Kids Cups',
  'Kits',
  'Koronets',
  'Lab Coat',
  'Latex Exam Gloves',
  'Latex Exam Powder Free Gloves',
  'Latex Glove Containers',
  'Latex Gloves',
  'Latex Powder Free Gloves',
  'Legacy Directsource',
  'Legacy Primeware',
  'Lemon Wraps/Covers',
  'Light Duty Scouring Pads',
  'Linen Like Napkins',
  'Loaf Liners',
  'Logistics',
  'Luncheon Napkins',
  'Marketing',
  'Medium Duty Scouring Pads',
  'Melamine Holdaplate',
  'Mesh Bags',
  'Mesh Sleeve',
  'Microwave Takeout Containers',
  'Miracle Filter Powder',
  'Misc.',
  'Moist Towelettes',
  'Morning Fresh',
  'Multi Copy - Carbon',
  'Multi Copy - Carbonless',
  'Napkin Bands - Printed',
  'Napkin Bands - Solid Color',
  'Napkin Containers',
  'Napkin Dispensers',
  'Nitrile Glove Containers',
  'Nitrile Lp Gloves',
  'Nitrile Powder Free Gloves',
  'Non-Woven Pp Tote Bags',
  'Non-Woven Towels',
  'Oval Bowls',
  'Oven Pan Liners-High Heat',
  'Oven Roasting Bag-High Heat',
  'Pad Item Containers',
  'Palm Leaf Bowls',
  'Palm Leaf Plates',
  'Palm Leaf Platter',
  'Pants',
  'Paper Bags',
  'Paper Board Food Trays',
  'Paper Cups & Lids',
  'Paper Food Containers',
  'Paper Take Out Bags',
  'Paperboard',
  'Parasols',
  'Party Picks And Straws',
  'Patty Paper',
  'Pet Drink Cups & Lids',
  'Picks - Arrow Picks',
  'Picks - Bamboo',
  'Picks - Flag',
  'Picks - Sandwich Picks',
  'Picks - Toothpicks',
  'Placemats - Economy',
  'Placemats - Stock Design',
  'Placemats - White',
  'Plain White Design',
  'Plant Starch Cutlery',
  'Plastic Bottles',
  'Plastic Cable Tie',
  'Plastic Deli Containers',
  'Plastic Film Foodservice Rolls',
  'Plastic Micro Togo Containers',
  'Plastic Pick - Prism',
  'Plastic Picks - Sword',
  'Plastic Spoons',
  'Plastic Take Out Bags',
  'Plastic Take Out Packaging',
  'Plastic Take Out Pkg Container',
  'Po Expense',
  'Poly Bag Racks',
  'Poly Glove Containers',
  'Poly Gloves - 10/10/100',
  'Poly Gloves - Bagged',
  'Poly Gloves - Boxed',
  'Poly Gloves 500 Pack',
  'Poly Tablecovers',
  'Polypropylene Holdaplate',
  'Portion Cups & Lids',
  'Pot & Pan Pads',
  'Pw Compostable Containers',
  'Pya Latex Gloves',
  'Pya Poly Gloves',
  'Raw Materials',
  'Reb Deductions Post 11-30-19',
  'Red Buffing Pads',
  'Reduction Of Sales',
  'Register Ink Ribbons',
  'Register Roll Containers',
  'Register Rolls - Bond',
  'Register Rolls - Carbonless',
  'Register Rolls - Thermal',
  'Register Rolls-Wet Strength',
  'Retail Pack Eco Sleeves',
  'Roll Towels - Centerpull',
  'Roll Towels - Hardwound',
  'Roll Towels - Kitchen',
  'S/Steel Spong Containers',
  'Satin Feel Gloves',
  'Server Card Holders',
  'Serving Boat - Bamboo',
  'Serving Boat - Wooden',
  'Serving Boats',
  'Sheeted Food Wraps',
  'Shellfish Steamer Bags',
  'Shoe Covers',
  'Silverware Bags',
  'Skewers - Bamboo Skewers',
  'Skewers - Wood Skewers',
  'Soap & Sanitizer Dispensers',
  'Soap Pads',
  'Soaps & Sanitizers',
  'Spec Prt Wax Bck Royal B  Cstr',
  'Special Print Beer Coasters',
  'Special Print Bus/Comp Forms',
  'Special Print Caps',
  'Special Print Carmats',
  'Special Print Chop Sticks',
  'Special Print Cutlery Kits',
  'Special Print Foam Cups',
  'Special Print Guest Checks',
  'Special Print Mag Server Cards',
  'Special Print Moist Towelettes',
  'Special Print Napkin Bands',
  'Special Print Napkins',
  'Special Print Paper Bags',
  'Special Print Paper Bibs',
  'Special Print Paper Cups',
  'Special Print Pet Drink Cups',
  'Special Print Pizza Box Corrug',
  'Special Print Placemats',
  'Special Print Plastic Bags',
  'Special Print Register Rolls',
  'Special Print Royal-B Coasters',
  'Special Print Sheet Food Wraps',
  'Special Prnt Catch-All Coaster',
  'Sponges - Bronze',
  'Sponges - Copper',
  'Sponges - Plastic',
  'Sponges - Stainless Steel',
  'Square Bottom Food Pails',
  'Stainfeel Glove Containers',
  'Steak Markers - Wood',
  'Steak Markers Plastic',
  'Steak Paper',
  'Steam Pan Liners',
  'Stg Ballon Plug Stix',
  'Stg Circle Plug',
  'Stg Sip N Plug Stix',
  'Stg Stir N Plug Stix',
  'Stg Stir Stix Plug',
  'Stg Swirl Plug',
  'Stg Theme Plug',
  'Stirrers - Bamboo',
  'Stirrers - Plastic',
  'Stirrers - Wood',
  'Straws',
  'Straws - Eco Friendly',
  'Straws - Fruit',
  'Straws And Stirrers Containers',
  'Synthetic Glove Containers',
  'Synthetic Gloves',
  'Synthetic Gloves - Powder Free',
  'Table Top Pizza Savers',
  'Tableware',
  'Takeout Bag Containers',
  'Tan Collection',
  'Tan Mini Trays',
  'Tasting Cone',
  'Tasting Cone - Bamboo',
  'Tissue - Personal Care Rolls',
  'Toilet Seat Cover Containers',
  'Toners/Drums/Printer Imaging',
  'Tripod Pizza Savers',
  'Usf Only',
  'Utensils',
  'Vinyl Baskets',
  'Vinyl Exam Gloves',
  'Vinyl Exam Powder Free Gloves',
  'Vinyl Glove Containers',
  'Vinyl Gloves',
  'Vinyl Powder Free Gloves',
  'Viscose Chef Hats',
  'White Super Polishing Pads',
  'Wick Chafing Fuel',
  'Wicker Baskets',
  'Wobble Wedges',
  'Wood Item Containers',
  'Wood Spoons',
  'Xmas Design Placemats',
  'Xtra Heavy Duty Scouring Pads',
  'Zip Bags',
]

export const ORDER_STATUSES = [
  'Open',
  'Picking',
  'Awaiting Product Receipt',
  'Shipped',
  'Cancelled',
  'Closed', // maybe this should be removed because we are filtering on !closed Orders
]

export const RETURN_STATUSES_HISTORY = [
  'Closed',
  'Rejected',
  'Approved RA-Expired',
]
export const RETURN_STATUSES_OPEN = [
  'Pending',
  'Approved',
  'Reviewed',
  'Awaiting Product Receipt',
  'Reopened',
  'Approved-O',
]
