import { ChangeEvent, FC, Fragment, memo, ReactElement } from 'react'
import cn from 'classnames'
import { Link as RouterLink, useLocation } from 'react-router-dom'

import { KeyboardBackspace as KeyboardBackspaceIcon } from '@mui/icons-material'
import {
  Box,
  Container,
  IconButton,
  Link,
  MenuItem,
  Pagination,
  PaginationItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  useMediaQuery,
} from '@mui/material'

import CustomTooltip from '~/components/common/customTooltip/CustomTooltip'
import FilterGridData from '~/components/common/filterGridData/FilterGridData'
import { theme } from '~/theme/AppTheme'
import { Fields } from '~/types/Fields.model'
import { Actions } from '~/types/TabsType.model'
import CustomModal from '~/UI/modals/CustomModal'
import { TEXTS } from '~/utils/allConstants/Constants'
import { TableCellFormat } from '~/utils/helpers/helperFunctions'

import { GlobalLoading } from '../Loading'

import DataGridFilters from './DataGridFilters/DataGridFilters'
import useDataGridStore from './DataGridContext'

import './DataGrid.scss'
import variables from '~/theme/scss/_variables.module.scss'

const { NO_DATA_FOUND } = TEXTS
const {
  acrColorGrey,
  acrColorBlack,
  acrColorPrimary,
  acrColorBluishWhite,
  acrColorBlue,
  acrColorDarkPrimary,
  acrColorLightGrey2,
  acrColorRedishGrey,
} = variables

const ReversedKeyboardBackspaceIcon = () => (
  <KeyboardBackspaceIcon
    sx={{ transform: 'rotate(180deg)', color: acrColorBlack }}
  />
)

const StyledKeyboardBackspaceIcon = () => (
  <KeyboardBackspaceIcon sx={{ color: acrColorBlack }} />
)

export const InternalLink: FC<{ to: string; children: string }> = ({
  to,
  children,
}) => {
  to = to ?? ''
  let props: any = {
    component: RouterLink,
    to: to,
  }
  if (to.indexOf('http') !== -1) {
    props = {
      component: 'a',
      href: to,
      target: '_blank',
    }
  }
  return (
    <Link
      {...props}
      sx={{
        textDecoration: 'none',
        wordBreak: 'break-all',
        color: acrColorBlue,
        fontWeight: '700',
        '&:hover': {
          textDecoration: 'underline',
        },
      }}
    >
      {children}
    </Link>
  )
}

const iconButtonSx = (isCellActive: boolean) => {
  return {
    height: 32,
    width: 32,
    backgroundColor: isCellActive ? acrColorPrimary : acrColorBluishWhite,
    borderRadius: '4px',
    border: '1px solid',
    borderColor: acrColorPrimary,
    mr: 1,
    '&:hover': {
      backgroundColor: isCellActive ? acrColorDarkPrimary : acrColorBluishWhite,
    },
  }
}

const DataGrid = (): ReactElement => {
  const {
    tableProps,
    page,
    setPage,
    pageSize,
    openFilterModal,
    setOpenFilterModal,
    data,
    isFetching,
    isError,
    getIsCellActive,
    getIsGroupActive,
    applyGrouping,
    getSortingDirection,
    applyCellSort,
    changePageSize,
  } = useDataGridStore()
  const { pathname } = useLocation()
  const dataItems = data?.items
  const isApprovalRelatedPages = pathname.includes('/approvals/')
  const isManageUsersPage = pathname.includes('/manage/users')
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleChangePageSize = (event: SelectChangeEvent) => {
    changePageSize(parseInt(event.target.value, 10))
  }

  const handleChangePage = (event: ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage)
  }

  const checkFilterFieldConfiguration = tableProps.fields?.some(
    (field: Fields) => !!field?.filterFieldConfiguration
  )

  const tableActions = tableProps.actions

  return (
    <Container maxWidth={false} disableGutters>
      <Box>
        <TableContainer
          sx={{
            mt: tableProps.name ? 5 : 0,
            border: '1px solid',
            borderColor: acrColorRedishGrey,
          }}
          component={Paper}
        >
          <Table
            sx={{
              height:
                (isFetching && dataItems.length === 0) || isError ? 439 : '',
            }}
            aria-label="custom pagination table"
            stickyHeader
          >
            <TableHead>
              <TableRow sx={{ textAlign: 'center' }}>
                {tableProps.fields?.map((column: any) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    sx={{
                      minWidth: column.minWidth,
                      borderBottom: '2px solid',
                      borderBottomColor: acrColorLightGrey2,
                    }}
                  >
                    <div className="flexVerticalCenter">
                      {column.hasOwnProperty('icon') && (
                        <IconButton
                          sx={{
                            ...iconButtonSx(getIsGroupActive(column.id)),
                          }}
                          onClick={() =>
                            applyGrouping(
                              column.id,
                              column.label,
                              column.groupType
                            )
                          }
                        >
                          {column.icon(
                            getIsGroupActive(column.id)
                              ? acrColorBluishWhite
                              : acrColorPrimary
                          )}
                        </IconButton>
                      )}
                      {column.hasOwnProperty('sort') && column.sort ? (
                        <TableSortLabel
                          active={getIsCellActive(column.id)}
                          direction={getSortingDirection(column.id)}
                          onClick={() => applyCellSort(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      ) : (
                        column.label
                      )}
                    </div>
                  </TableCell>
                ))}

                {(checkFilterFieldConfiguration ||
                  tableActions?.length > 0) && (
                  <TableCell
                    className={
                      checkFilterFieldConfiguration &&
                      isApprovalRelatedPages &&
                      'stickyTableCell'
                    }
                    sx={{
                      borderBottom: '2px solid',
                      borderBottomColor: acrColorLightGrey2,
                    }}
                  >
                    <div className="table-actions-wrapper">
                      {tableActions?.map((actionProps: any) => {
                        return actionProps.header ? actionProps.header() : null
                      })}
                      {checkFilterFieldConfiguration &&
                        (!isTabletOrMobile ||
                          isApprovalRelatedPages ||
                          isManageUsersPage) && <FilterGridData />}
                    </div>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {(isError || (!isFetching && dataItems.length === 0)) && (
                <TableRow>
                  <TableCell
                    align={'center'}
                    colSpan={
                      tableProps?.fields?.length +
                      (checkFilterFieldConfiguration ||
                      tableProps?.actions?.length > 0
                        ? 1
                        : 0)
                    }
                  >
                    <strong>
                      {!!tableProps.notFoundMessage
                        ? tableProps.notFoundMessage
                        : NO_DATA_FOUND}
                    </strong>
                  </TableCell>
                </TableRow>
              )}

              {!isError &&
                dataItems.length > 0 &&
                dataItems.map((row: any, index: any) => {
                  if (row.hasOwnProperty('type') && row.type === 'group') {
                    return (
                      <>
                        {/* Added fragment to resolve bug 2713 */}
                        <TableRow key={row.key} sx={{ height: 56 }}>
                          <TableCell
                            colSpan={
                              tableProps.fields.length +
                              (checkFilterFieldConfiguration ||
                              tableActions?.length > 0
                                ? 1
                                : 0)
                            }
                            sx={[
                              {
                                backgroundColor: acrColorGrey,
                                color: acrColorBluishWhite,
                              },
                              row.hasOwnProperty('rowSx') ? row.rowSx : {},
                            ]}
                          >
                            {`${row.groupLabel}: ${row.groupItemName}`}
                          </TableCell>
                        </TableRow>
                      </>
                    )
                  } else {
                    return (
                      <>
                        {/* Added fragment to resolve bug 2713 */}
                        <TableRow key={row.id} sx={{ height: 73 }}>
                          {tableProps.fields.map((column: any) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              <TableCellFormat column={column} row={row} />
                            </TableCell>
                          ))}
                          {(checkFilterFieldConfiguration ||
                            tableActions?.length > 0) && (
                            <TableCell
                              className={
                                checkFilterFieldConfiguration &&
                                isApprovalRelatedPages &&
                                'stickyTableCell'
                              }
                            >
                              <div
                                className={
                                  checkFilterFieldConfiguration &&
                                  'table-actions-wrapper'
                                }
                              >
                                {tableActions?.map(
                                  ({
                                    render,
                                    icon,
                                    tooltip,
                                    click,
                                    disabled,
                                  }: Actions) => (
                                    <Fragment key={index}>
                                      {render ? (
                                        render(row, index)
                                      ) : (
                                        <CustomTooltip title={tooltip}>
                                          <IconButton
                                            onClick={click?.bind(this, row)}
                                            disabled={
                                              disabled &&
                                              disabled.call(this, row)
                                            }
                                          >
                                            {icon}
                                          </IconButton>
                                        </CustomTooltip>
                                      )}
                                    </Fragment>
                                  )
                                )}
                              </div>
                            </TableCell>
                          )}
                        </TableRow>
                      </>
                    )
                  }
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {!isError && !!dataItems && !!data?.totalCount && (
          <Box
            className={cn(
              'pagination-wrapper',
              isTabletOrMobile && 'mobile-version'
            )}
          >
            <Pagination
              className="pagination-nav"
              count={
                data.totalCount ? Math.ceil(data.totalCount / pageSize) : 0
              }
              page={page}
              defaultPage={1}
              size={isTabletOrMobile ? 'medium' : 'large'}
              siblingCount={isTabletOrMobile ? 0 : 1}
              onChange={handleChangePage}
              renderItem={(item) => (
                <PaginationItem
                  components={{
                    previous: StyledKeyboardBackspaceIcon,
                    next: ReversedKeyboardBackspaceIcon,
                  }}
                  className="pagination-item"
                  {...item}
                />
              )}
            />
            <Select
              value={pageSize}
              label={'rows per page'}
              onChange={handleChangePageSize}
              className="page-size-select"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              {tableProps.pagination500 && <MenuItem value={500}>500</MenuItem>}
            </Select>
          </Box>
        )}
      </Box>

      {isFetching && <GlobalLoading />}

      <CustomModal
        open={openFilterModal}
        setOpen={setOpenFilterModal}
        sx={{ width: '50%' }}
      >
        <DataGridFilters />
      </CustomModal>
    </Container>
  )
}

export default memo(DataGrid)
