import { FC } from 'react'

import { MarkAsReadCustomerAlert } from '~/services/bannerNotification'
import { CustomerAlert } from '~/types/CustomerAlert.model'

import { BannerPreview } from './BannerPreview'

type Props = {
  customerAlertData: { items: CustomerAlert[]; totalCount: number }
}

const DashboardBannerPreview: FC<Props> = ({ customerAlertData }) => {
  const useMarkAsReadMutation = MarkAsReadCustomerAlert()

  const customerAlert: CustomerAlert[] = customerAlertData.items

  const handleMarkedAsRead = (bannerId: string) => {
    useMarkAsReadMutation.mutate(bannerId, {
      onSuccess: () => {
        customerAlert.shift()
      },
    })
  }

  return (
    <>
      {customerAlert.length > 0 && (
        <BannerPreview
          key={customerAlert[0].id}
          bannerFormData={customerAlert[0]}
          header={false}
          handleMarkAsRead={handleMarkedAsRead}
          isLoading={useMarkAsReadMutation.isLoading}
        />
      )}
    </>
  )
}

export default DashboardBannerPreview
