import React from 'react'

import AccountLoader from '../components/Accounts/AccountLoader'
import Returns from '../components/Return/Returns'
import ReturnDetail from '../components/ReturnDetail/ReturnDetail'
import ReturnDetailInfo from '../components/ReturnDetailsInfo/ReturnDetailInfo'
import { RoutesCheck } from '../utils/allConstants/Constants'
import { RedirectWithAccountParams } from '../utils/routes-util'

import CheckRoutes from './CheckRoutes'

export default [
  {
    path: 'returns',
    element: <RedirectWithAccountParams to={'/returns/open-returns'} />,
  },
  {
    path: ':accountId/returns',
    element: (
      <RedirectWithAccountParams to={'/:accountId/returns/open-returns'} />
    ),
  },
  {
    path: ':accountId/:subAccountId/returns',
    element: (
      <RedirectWithAccountParams
        to={'/:accountId/:subAccountId/returns/open-returns'}
      />
    ),
  },
  {
    path: 'returns/:active_tab',
    element: (
      <CheckRoutes type={[RoutesCheck.PRIVATE]}>
        <Returns />
      </CheckRoutes>
    ),
  },
  {
    path: ':accountId/returns/:active_tab',
    element: (
      <CheckRoutes type={[RoutesCheck.PRIVATE]}>
        <AccountLoader>
          <Returns />
        </AccountLoader>
      </CheckRoutes>
    ),
  },
  {
    path: ':accountId/:subAccountId/returns/:active_tab',
    element: (
      <CheckRoutes type={[RoutesCheck.PRIVATE]}>
        <AccountLoader>
          <Returns />
        </AccountLoader>
      </CheckRoutes>
    ),
  },
  {
    path: 'returns/:id/detailInfo',
    element: (
      <CheckRoutes type={[RoutesCheck.PRIVATE]}>
        <ReturnDetailInfo />
      </CheckRoutes>
    ),
  },
  {
    path: 'returns/:id/details',
    element: (
      <CheckRoutes
        type={[RoutesCheck.CUSTOMER_RA_REQUEST, RoutesCheck.CARRIER_RETURNS]}
      >
        <ReturnDetail />
      </CheckRoutes>
    ),
  },
]
