import { FC, ReactElement } from 'react'
import { Control, Controller } from 'react-hook-form'

import { Divider, Grid, Select, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import GroupCheckbox from '~/components/Form/GroupCheckbox'
import { fetchUserClaimsByRole, fetchUserCustomClaims } from '~/services/users'
import { TEXTS } from '~/utils/allConstants/Constants'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorRedishGrey, acrColorGrey, acrColorBlack } = variables
const { CLAIMS, GRANTED_CLAIMS } = TEXTS

type Props = {
  grantedClaimsName: string
  enabledClaimIdsName: string
  control: Control<any>
  role: string
}

const UserClaims: FC<Props> = ({
  grantedClaimsName,
  enabledClaimIdsName,
  control,
  role,
}): ReactElement => {
  const { data } = useQuery(
    ['claims', role],
    () => {
      return fetchUserClaimsByRole(role)
    },
    {
      placeholderData: [],
      keepPreviousData: true,
    }
  )

  const { data: customClaimsData } = useQuery(
    ['custom', role],
    () => {
      return fetchUserCustomClaims(role)
    },
    {
      placeholderData: [],
      keepPreviousData: true,
    }
  )

  const claimsData = customClaimsData
    .filter((custom) => !data.some((item) => item.claim == custom.claim))
    .map((item) => {
      return {
        id: item.claim,
        name: item.name,
        disabled: !item.enabled,
      }
    })

  return (
    <Grid container my={3}>
      <Grid item xs={12} my={3}>
        <Divider sx={{ color: acrColorRedishGrey }} />
      </Grid>
      <Grid item xs={12} lg={5} sx={{ px: 3 }} mb={{ sm: 2 }}>
        <Typography
          sx={{
            color: acrColorBlack,
            fontWeight: 700,
            mb: 1,
            fontSize: 20,
          }}
        >
          {CLAIMS}
        </Typography>
        <Typography sx={{ color: acrColorBlack, fontSize: 14 }}>
          Claims are saved when saving the user
        </Typography>
        <Grid display={'flex'} alignItems={'center'}>
          <GroupCheckbox
            name={enabledClaimIdsName}
            control={control}
            items={claimsData}
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        lg={7}
        sx={{ display: 'flex', justifyContent: 'end', px: 3 }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: 16,
            color: acrColorBlack,
            alignSelf: 'center',
            minWidth: 150,
          }}
        >
          {GRANTED_CLAIMS}
        </Typography>
        <Controller
          name={grantedClaimsName}
          control={control}
          render={({}) => (
            <Select
              multiple
              native
              fullWidth={true}
              value={[]}
              inputProps={{
                style: { overflowY: 'auto', padding: 5 },
              }}
            >
              {data.map(({ name }: { name: string }) => (
                <option
                  key={name}
                  value={name}
                  style={{
                    marginTop: 5,
                    fontSize: 16,
                    color: acrColorGrey,
                  }}
                >
                  {name}
                </option>
              ))}
            </Select>
          )}
        />
      </Grid>
    </Grid>
  )
}

export default UserClaims
