import { Backdrop, CircularProgress, styled } from '@mui/material'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorWhite, acrColorSkyBlue } = variables

const Loading = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
`

export const GlobalLoading = () => {
  return (
    <Backdrop
      sx={{ color: acrColorWhite, zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <Loading sx={{ color: acrColorSkyBlue }} />
    </Backdrop>
  )
}

export default Loading
