import { memo, useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'

import { Button } from '@mui/material'

import ContentWrapper from '~/components/UI/ContentWrapper/ContentWrapper'
import useDataGridStore, {
  DataGridProvider,
} from '~/components/UI/DataGrid/DataGridContext'
import TabsTablePage from '~/components/UI/TabsTablePage'
import { fetchReturns } from '~/services/returns'
import { ReturnRequestApproval } from '~/types/Approval.model'
import { TabsType } from '~/types/TabsType.model'
import { APPROVAL_STATUSES, TEXTS } from '~/utils/allConstants/Constants'

import { fields } from '../components/ApprovalTabContent'
import { UnAssignButton } from '../components/UnAssignButton'
const { NO_DATA_MESSAGE, REVIEW, MY_ASSIGNED_ITEMS } = TEXTS
const { APPROVED, REJECTED, PENDING, CLOSED } = APPROVAL_STATUSES

const Returns = () => {
  const { active_tab } = useParams()
  const currentTab = active_tab || 'assigned'
  const { setTableProps, refetch } = useDataGridStore()
  const [unassignSuccess, setUnassignSuccess] = useState(null)

  useEffect(() => {
    refetch()
    setTableProps((prevState: TabsType) => {
      return {
        ...prevState,
        additionalFilters: {
          isAssignedToMe: prevState.additionalFilters.isAssignedToMe,
        },
      }
    })
  }, [unassignSuccess])

  const actions = [
    {
      render: (row: ReturnRequestApproval) => {
        return (
          <>
            <Button
              variant={'contained'}
              component={NavLink}
              to={
                row.approvalStatus === PENDING && row.isCarrierReturn === null
                  ? `${row.id}/review-details`
                  : `${row.id}/approve-details`
              }
              className="action-button"
              disabled={[APPROVED, CLOSED, REJECTED].includes(
                row.approvalStatus
              )}
            >
              {REVIEW}
            </Button>
            <UnAssignButton row={row} setUnassignSuccess={setUnassignSuccess} />
          </>
        )
      },
    },
  ]
  const defaultProps = {
    source: fetchReturns,
    actions,
    notFoundMessage: NO_DATA_MESSAGE('Returns'),
  }
  const params: TabsType[] = [
    {
      label: MY_ASSIGNED_ITEMS,
      name: 'assigned_items',
      ...defaultProps,
      fields,
      additionalFilters: {
        isAssignedToMe: true,
      },
    },
  ]

  return (
    <ContentWrapper>
      <TabsTablePage currentTab={currentTab} tabs={params} />
    </ContentWrapper>
  )
}
// eslint-disable-next-line react/display-name
export default memo(() => (
  <DataGridProvider>
    <Returns />
  </DataGridProvider>
))
