import * as React from 'react'

import { Grid, Stack, Typography, useMediaQuery } from '@mui/material'
import Link from '@mui/material/Link'
import { Box } from '@mui/system'

import FacebookIcon from '~/images/icons/fb.svg'
import LinkedinIcon from '~/images/icons/linkedin.svg'
import VimeoIcon from '~/images/icons/vimeo.svg'
import systemInstructions from '~/PDFs/WIN.Customer.Communication.Instructions.pdf'
import { theme } from '~/theme/AppTheme'

import FooterCard from './FooterCard'

import './Footer.scss'

const Footer = () => {
  const isMatch = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Box component={'footer'}>
      <Grid
        container
        spacing={0}
        columnSpacing={{ xs: 12, sm: 0, md: 0 }}
        sx={{
          display: 'flex',
          justifyContent: 'space-around',
          boxSizing: 'border-box',
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          display={'flex'}
          justifyContent={isMatch ? 'left' : 'center'}
        >
          <FooterCard heading="Help & Support">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                bgcolor: 'light',
              }}
            >
              <Link
                href="https://weareacr.com/privacy-policy"
                target={'_blank'}
                className="footer-text"
              >
                Policies
              </Link>
              <Link
                href={`mailTo:customerservice@amercareroyal.com`}
                className="footer-text"
              >
                Customer Support
              </Link>
              <Link
                target="_blank"
                href={systemInstructions}
                className="footer-text"
              >
                System Instructions
              </Link>
            </Box>
          </FooterCard>
          {/* <Box borderBottom={1}>Help & Support</Box> */}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FooterCard heading="Contact">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography className="footer-text">
                Call us at <Link href={'tel:+800-666-6655'}>+800-666-6655</Link>
              </Typography>

              <Typography className="footer-text">
                or&nbsp;
                <Link
                  target={'_blank'}
                  href="https://amercareroyal.com/pages/contact-us"
                  sx={{ fontStyle: 'italic' }}
                >
                  Contact&nbsp;
                </Link>
                Us Online
              </Typography>
              <Stack direction={'row'} spacing={1} sx={{ mt: 1 }}>
                <Link
                  href="https://www.linkedin.com/company/amercareroyal/"
                  target={'_blank'}
                >
                  <img src={LinkedinIcon} alt={'LinkedIn'} />
                </Link>
                <Link href="https://vimeo.com/amercareroyal" target={'_blank'}>
                  <img src={VimeoIcon} alt={'Vimeo'} />
                </Link>
                <Link
                  href="https://www.facebook.com/login/?next=https%3A%2F%2Fwww.facebook.com%2Famercareroyal"
                  target={'_blank'}
                >
                  <img src={FacebookIcon} alt={'Facebook page'} />
                </Link>
              </Stack>
            </Box>
          </FooterCard>
          {/* <Box borderBottom={1}>Help & Support</Box> */}
        </Grid>
        <Grid item xs={12} sm={12} md={5}>
          <FooterCard heading="Distribution Points">
            <Grid container spacing={0} rowSpacing={3}>
              <Grid item xs={12} sm={6}>
                <FooterCard heading="PENNSYLVANIA">
                  <Typography className="footer-text">
                    420 Clover Mill Rd
                    <br />
                    Exton, PA <br />
                    19341
                  </Typography>
                </FooterCard>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FooterCard heading="SOUTH CAROLINA">
                  <Typography className="footer-text">
                    1334 Drop Off Dr
                    <br />
                    Summerville, SC <br /> 29486
                  </Typography>
                </FooterCard>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FooterCard heading="TEXAS">
                  <Typography className="footer-text">
                    2401 Petty Pl
                    <br />
                    Fort Worth, TX <br />
                    76177
                  </Typography>
                </FooterCard>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FooterCard heading="CALIFORNIA">
                  <Typography className="footer-text">
                    1801 S Carlos Ave
                    <br />
                    Ontario, CA <br />
                    91761
                  </Typography>
                </FooterCard>
              </Grid>
            </Grid>
          </FooterCard>
          {/* <Box borderBottom={1}>Help & Support</Box> */}
        </Grid>
      </Grid>
    </Box>
  )
}

export default Footer
