import React from 'react'

import AccountLoader from '../components/Accounts/AccountLoader'
import SearchResults from '../components/SearchResults/SearchResults'
import { RoutesCheck } from '../utils/allConstants/Constants'

import CheckRoutes from './CheckRoutes'

export default [
  {
    path: 'search-results/:searchVal',
    element: (
      <CheckRoutes type={[RoutesCheck.PRIVATE]}>
        <SearchResults />
      </CheckRoutes>
    ),
  },
  {
    path: ':accountId/search-results/:searchVal',
    element: (
      <CheckRoutes type={[RoutesCheck.PRIVATE]}>
        <AccountLoader>
          <SearchResults />
        </AccountLoader>
      </CheckRoutes>
    ),
  },
  {
    path: ':accountId/:subAccountId/search-results/:searchVal',
    element: (
      <CheckRoutes type={[RoutesCheck.PRIVATE]}>
        <AccountLoader>
          <SearchResults />
        </AccountLoader>
      </CheckRoutes>
    ),
  },
]
