import { useContext, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import { useAuth } from '~/services/auth'
import { RoleTypes } from '~/services/users'
import { Account, SubAccount } from '~/types/Account.model'

import { AccountsContext } from '../Accounts'
import useSynchAccountsNavigation from '../Accounts/hook/useSynchAccountsNavigation'

import SearchResultsCarrierInvoices from './components/SearchCarierInvoice/SearchResultsCarrierInvoices'
import SearchResultsInvoices from './components/SearchInvoiceAndOrder/SearchResultsInvoices'
import SearchResultsOrders from './components/SearchInvoiceAndOrder/SearchResultsOrders'

const SearchResults = () => {
  const { searchVal } = useParams<{ searchVal?: string }>()
  const { data: user } = useAuth()
  const { selectedAccount, selectedSubAccount } =
    useContext(AccountsContext) ||
    ({} as {
      selectedAccount: Account
      selectedSubAccount: SubAccount
    })

  useSynchAccountsNavigation(`search-results/${searchVal}`)

  const filters = useMemo(() => {
    const f = {} as { customerNumber: string; shipToNumber: string }
    if (selectedAccount) {
      f.customerNumber = selectedAccount.customerNumber

      if (selectedSubAccount) {
        f.shipToNumber = selectedSubAccount.shipToNumber
      }
    }
    return f
  }, [selectedAccount, selectedSubAccount])

  return (
    <Box sx={{ mx: { xs: 2, md: 3 }, my: 3 }}>
      <Typography
        variant="h3"
        sx={{
          color: (theme) => theme.palette.text.secondary,
          fontWeight: 'bold',
        }}
      >
        Search Results for - {searchVal}
      </Typography>
      {[RoleTypes.CARRIER].includes(user.role) ? (
        <SearchResultsCarrierInvoices
          filters={{
            q: searchVal,
            ...filters,
          }}
        />
      ) : (
        <>
          <SearchResultsOrders
            filters={{
              q: searchVal,
              ...filters,
            }}
          />

          <SearchResultsInvoices
            filters={{
              q: searchVal,
              ...filters,
            }}
          />
        </>
      )}
    </Box>
  )
}

export default SearchResults
