import { Box, Grid, Link, Typography, useTheme } from '@mui/material'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorBlue } = variables

const RegisterComplete = () => {
  const theme = useTheme()
  return (
    <Box
      sx={{
        maxWidth: '540px',
        mx: 'auto',
        [theme.breakpoints.down('md')]: {
          maxWidth: '100%',
          padding: '16px',
        },
      }}
    >
      <Grid
        container
        sx={{
          width: '100%',
          justifyContent: 'center',
          margin: '100px auto',
          p: 1,
        }}
      >
        <Typography variant="h1" align={'center'}>
          Thank you for registering for the ACR Customer Portal!
        </Typography>

        <Typography
          variant={'h4'}
          align={'center'}
          sx={{ color: (theme) => theme.palette.text.secondary, py: 3 }}
        >
          You will soon receive an email asking you to confirm your email
          address. Once confirmed an ACR representative will review.
        </Typography>

        <Typography
          mt={3}
          sx={{
            color: (theme) => theme.palette.text.secondary,
            lineHeight: '24px',
            textAlign: 'center',
          }}
        >
          If your active customer status is confirmed, you will receive an
          approval notification email. Non-ACR existing customers, please
          click&nbsp;
          <Link
            component={'a'}
            href={'https://amercareroyal.com/pages/open-an-account'}
            target={'_blank'}
            sx={{
              fontWeight: 'bold',
              color: acrColorBlue,
              textDecorationColor: acrColorBlue,
            }}
          >
            here
          </Link>
          &nbsp; for more information on how to open a customer account.
        </Typography>
      </Grid>
    </Box>
  )
}

export default RegisterComplete
