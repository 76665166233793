import cn from 'classnames'

import { Box } from '@mui/material'

import './ContentWrapper.scss'

type Props = {
  children: React.ReactNode
  className?: string
}

const ContentWrapper: React.FC<Props> = ({ children, className }) => (
  <Box className={cn('content-spacing', className)}>{children}</Box>
)

export default ContentWrapper
