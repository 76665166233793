import { Order, OrderSummary } from '~/types/Order.model'

import { Comment, PostComment } from '../types/Comment.model'
import { OrderFilter } from '../types/Filter.model'
import { InvoiceDetailsData } from '../types/InvoiceDetailsData.model'
import { PageParams } from '../types/PageParams.model'

import axiosInstance from './axiosInstance'
import { getPreparedFilters } from './filters-utils'

export const fetchOrders = ({
  filters = {},
  fields,
  ...params
}: PageParams<OrderFilter>) => {
  const filterParams = getPreparedFilters(filters, fields)

  return axiosInstance
    .get<OrderSummary>(`/v1/acr/search/order?`, {
      params: {
        ...params,
        ...filterParams,
      },
    })
    .then(({ data }) => {
      return {
        totalCount: data.totalCount,
        items: data.items.map((item: Order) => ({
          ...item,
          id: item.orderNumber,
        })),
      }
    })
}

export const fetchOrderDetailsData = (id: string) => {
  return axiosInstance
    .get<InvoiceDetailsData>(`/v1/acr/orders/${id}`)
    .then(({ data }) => {
      return {
        ...data,
        orderItems: data.orderItems.map((item) => ({
          ...item,
          id: item.itemNo,
        })),
      }
    })
}

export const fetchOrderComments = (orderId: string) => {
  return axiosInstance
    .get<Comment[] | []>(`/v1/orders/${orderId}/comments`)
    .then(({ data }) => {
      return data
    })
}

export const createOrderComment = ({ id: orderId, ...rest }: any) => {
  return axiosInstance
    .post<PostComment>(`/v1/orders/${orderId}/comments`, { ...rest })
    .then(({ data }) => {
      return data
    })
}

export const fetchAllOrderComments = (orderNumbers: string[]) => {
  let url = `/v1/orders/comments?orderNumbers=`
  url += orderNumbers.join(',')
  return axiosInstance.get<Comment[] | []>(url).then(({ data }) => {
    return data
  })
}
