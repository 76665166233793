import React from 'react'

import AccountLoader from '../components/Accounts/AccountLoader'
import PriceList from '../components/PriceList/PriceList'
import { RoutesCheck } from '../utils/allConstants/Constants'
import { RedirectWithAccountParams } from '../utils/routes-util'

import CheckRoutes from './CheckRoutes'

export default [
  {
    path: 'price-list',
    element: <RedirectWithAccountParams to={'/price-list/current'} />,
  },
  {
    path: ':accountId/price-list',
    element: (
      <RedirectWithAccountParams to={'/:accountId/price-list/current'} />
    ),
  },
  {
    path: ':accountId/:subAccountId/price-list',
    element: (
      <RedirectWithAccountParams
        to={'/:accountId/:subAccountId/price-list/current'}
      />
    ),
  },

  {
    path: 'price-list/:active_tab',
    element: (
      <CheckRoutes type={[RoutesCheck.PRIVATE, RoutesCheck.NOT_CARRIER]}>
        <PriceList />
      </CheckRoutes>
    ),
  },
  {
    path: ':accountId/price-list/:active_tab',
    element: (
      <CheckRoutes type={[RoutesCheck.PRIVATE, RoutesCheck.NOT_CARRIER]}>
        <AccountLoader>
          <PriceList />
        </AccountLoader>
      </CheckRoutes>
    ),
  },
  {
    path: ':accountId/:subAccountId/price-list/:active_tab',
    element: (
      <CheckRoutes type={[RoutesCheck.PRIVATE, RoutesCheck.NOT_CARRIER]}>
        <AccountLoader>
          <PriceList />
        </AccountLoader>
      </CheckRoutes>
    ),
  },
]
