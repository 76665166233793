import { FC, ReactElement } from 'react'

import { Box } from '@mui/system'

import './FooterCard.scss'

interface Props {
  heading: string
  children: ReactElement
}
const FooterCard: FC<Props> = ({ heading, children }): ReactElement => {
  return (
    <Box component={'div'}>
      <p className="footer-element-heading">{heading}</p>
      {children}
    </Box>
  )
}

export default FooterCard
