import { Chip } from '@mui/material'

import { Roles, UserStatus } from '~/services/users'
import { Fields } from '~/types/Fields.model'
import { TEXTS } from '~/utils/allConstants/Constants'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorWhite, acrColorYellowishWhite, acrColorYellow } = variables
const {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  EMAIL_CONFIRMED,
  STATUS,
  SECURITY_ROLE,
  COMPANY,
} = TEXTS

enum UserConfirmed {
  true = 'Yes',
  false = 'No',
}

const userFields: Fields[] = [
  {
    id: 'firstName',
    label: FIRST_NAME,
    minWidth: 50,
    sort: true,
    filterFieldConfiguration: {
      type: 'freeText',
    },
  },
  {
    id: 'lastName',
    label: LAST_NAME,
    minWidth: 50,
    sort: true,
    filterFieldConfiguration: {
      type: 'freeText',
    },
  },
  {
    id: 'contactEmail',
    label: EMAIL,
    minWidth: 100,
    sort: true,
    filterFieldConfiguration: {
      type: 'freeText',
    },
  },
  {
    id: 'emailVerified',
    label: EMAIL_CONFIRMED,
    minWidth: 50,
    format: (value: boolean) => (value ? 'Yes' : 'No'),
    sort: false,
    filterFieldConfiguration: {
      type: 'boolean-select',
      values: UserConfirmed,
    },
  },
  {
    id: 'userStatus',
    label: STATUS,
    minWidth: 100,
    format: (value) => {
      if (!value) {
        return null
      } else {
        const status =
          value in UserStatus
            ? UserStatus[value as keyof typeof UserStatus]
            : 'Undefined'
        const styles = {
          ACTIVE: {
            color: acrColorWhite,
            backgroundColor: '#82b085',
            border: '1px solid #377a3b',
          },
          PENDING_APPROVAL: {
            backgroundColor: acrColorYellowishWhite,
            border: '1px solid',
            borderColor: acrColorYellow,
          },
          DISABLED: {
            color: acrColorWhite,
            backgroundColor: '#e45656',
            border: '1px solid #d32f2f',
          },
          NOT_APPROVED: {
            color: acrColorWhite,
            backgroundColor: '#e45656',
            border: '1px solid #d32f2f',
          },
        }
        return (
          <Chip
            label={status}
            sx={styles[value as keyof typeof UserStatus | undefined]}
          />
        )
      }
    },
    sort: false,
    filterFieldConfiguration: {
      type: 'select',
      values: UserStatus,
    },
  },
  {
    id: 'securityRole',
    label: SECURITY_ROLE,
    minWidth: 80,
    format: (value) =>
      value in Roles ? Roles[value as keyof typeof Roles] : 'Undefined',
    sort: false,
    filterFieldConfiguration: {
      type: 'select',
      values: Roles,
    },
  },
  {
    id: 'company',
    label: COMPANY,
    minWidth: 100,
    sort: true,
    filterFieldConfiguration: {
      type: 'freeText',
    },
  },
]

export default userFields
