import React from 'react'
import { useParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'

import { fetchReturnDetailsData } from '~/services/returns'

import { GlobalLoading } from '../UI/Loading'

import { fields } from './components/ReturnDetailInfoContent'
import ReturnDetailsInfoPage from './components/ReturnDetailsInfoPage'

const options = {
  name: 'Return Item Details',
  queryName: 'return-info-details',
  tableProps: {
    fields,
  },
}
const ReturnDetailInfo = () => {
  const { id } = useParams<{ id?: string }>()

  const { isLoading, data } = useQuery(
    [`return-info-details`, id],
    () => {
      return fetchReturnDetailsData(id)
    },
    { keepPreviousData: true }
  )

  if (isLoading) {
    return <GlobalLoading />
  }

  return <ReturnDetailsInfoPage data={data} options={{ ...options, id }} />
}

export default ReturnDetailInfo
