import { Controller } from 'react-hook-form'

import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectProps,
  TextFieldProps,
} from '@mui/material'

import { StyledFormFieldProps } from '~/types/StyledFormFieldProps.model'

import { BootstrapInput } from './StyledFormControl'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250 },
  },
}

export const StyledSelect = ({
  label,
  className,
  placeholder,
  children,
  error,
  ...rest
}: TextFieldProps & StyledFormFieldProps & SelectProps<unknown>) => {
  return (
    <FormControl
      variant="standard"
      fullWidth={true}
      margin={'dense'}
      className={className}
    >
      {label && (
        <InputLabel
          shrink
          htmlFor={rest.id}
          id={rest.id + '-label'}
          error={!!error}
          sx={(theme) => ({
            fontSize: 16,
            transform: 'none',
            color: theme.palette.text.primary,
          })}
        >
          {label}
        </InputLabel>
      )}
      <Select
        MenuProps={MenuProps}
        error={!!error}
        labelId={rest.id + '-label'}
        input={<BootstrapInput />}
        displayEmpty
        {...rest}
        renderValue={(val: any) => {
          if (val === '') {
            return (
              <span className={'placeholder'}>
                {placeholder || 'Please select'}
              </span>
            )
          }

          return val
        }}
      >
        {children}
      </Select>
      {!!error && (
        <FormHelperText error={!!error}>{error.message}</FormHelperText>
      )}
    </FormControl>
  )
}

const ControllerStyledSelect = ({
  control,
  name,
  className,
  placeholder,
  onCustomChange,
  children,
  ...rest
}: StyledFormFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <StyledSelect
          variant="standard"
          name={field.name}
          placeholder={placeholder}
          value={field.value || ''}
          className={className}
          onChange={(e) => {
            if (onCustomChange) {
              onCustomChange(e)
            }

            field.onChange(e)
          }}
          onBlur={field.onBlur}
          error={error}
          {...rest}
        >
          {children}
        </StyledSelect>
      )}
    />
  )
}

export default ControllerStyledSelect
