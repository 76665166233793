import { Chip } from '@mui/material'

import { InternalLink } from '~/components/UI/DataGrid/DataGrid'
import { Fields } from '~/types/Fields.model'
import { TEXTS } from '~/utils/allConstants/Constants'
import { formatCurrency, formatDate } from '~/utils/helpers/helperFunctions'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorYellowishWhite, acrColorYellow } = variables
const {
  CUSTOMER_NAME,
  RETURN_STATUS,
  PURCHASE_ORDER,
  ORDER_DATE,
  RETURN_CREATION_DATE,
} = TEXTS

const fields: Fields[] = [
  {
    id: 'customerName',
    label: CUSTOMER_NAME,
    minWidth: 150,
    filterFieldConfiguration: {
      type: 'freeText',
    },
    format: (_, row) => {
      return row.customerName
    },
    sort: true,
  },
  {
    id: 'id',
    label: 'Return#',
    minWidth: 120,
    filterFieldConfiguration: {
      type: 'freeText',
    },
    format: (_, row) => (
      <InternalLink to={`/returns/${row.id}/detailInfo`}>{row.id}</InternalLink>
    ),
    sort: true,
  },
  {
    id: 'displayStatus',
    label: RETURN_STATUS,
    minWidth: 90,
    format: (_, row) => {
      if (!row.approvalStatus) {
        return null
      } else {
        return (
          <Chip
            label={row.displayStatus}
            sx={{
              backgroundColor: acrColorYellowishWhite,
              border: '1px solid',
              borderColor: acrColorYellow,
            }}
          />
        )
      }
    },
    sort: true,
  },
  {
    id: 'totalValue',
    label: 'Return Value $',
    minWidth: 90,
    filterFieldConfiguration: {
      type: 'range-freeText',
      prefix: '$',
    },
    format: (_, row) => formatCurrency(row.total.value),
    sort: true,
  },
  {
    id: 'orderNumber',
    label: 'Order#',
    minWidth: 80,
    filterFieldConfiguration: {
      type: 'freeText',
    },
    format: (_, row) => (
      <InternalLink to={`/orders/${row.orderNumber}/details`}>
        {row.orderNumber}
      </InternalLink>
    ),
    sort: true,
  },

  {
    id: 'purchaseOrder',
    label: PURCHASE_ORDER,
    minWidth: 110,
    filterFieldConfiguration: {
      type: 'freeText',
    },
    sort: true,
    format: (_, row) => {
      return row.purchaseOrder
    },
  },

  {
    id: 'orderDate',
    label: ORDER_DATE,
    minWidth: 80,
    filterFieldConfiguration: {
      type: 'range-date',
    },
    format: (_, row) => {
      return formatDate(row.orderDate)
    },
    sort: true,
  },
  {
    id: 'createdAt',
    label: RETURN_CREATION_DATE,
    minWidth: 80,
    filterFieldConfiguration: {
      type: 'range-date',
    },
    format: (_, row) => formatDate(row.createdAt),
    sort: true,
  },
]

export { fields }
