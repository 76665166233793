import { FC, ReactElement } from 'react'
import { Controller } from 'react-hook-form'

import HelpIcon from '@mui/icons-material/Help'
import {
  FormControl,
  FormHelperText,
  InputBase,
  InputBaseProps,
  InputLabel,
  TextFieldProps,
} from '@mui/material'
import { styled, StyledComponentProps } from '@mui/material/styles'

import { StyledFormFieldProps } from '~/types/StyledFormFieldProps.model'

import CustomTooltip from '../common/customTooltip/CustomTooltip'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorWhite, acrColorGrey } = variables

export const BootstrapInput: FC<StyledComponentProps | InputBaseProps> = styled(
  InputBase
)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(4),
  },
  '.placeholder': {
    color: 'rgba(90, 90, 90, 0.6)',
  },
  '&.MuiInputBase-root': {
    borderRadius: 5,
    position: 'relative',
    backgroundColor: acrColorWhite,
    border: '1px solid #565759',
    fontSize: 16,
    padding: '7px 9px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      // boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
    '&::placeholder': {},
  },
  '&.Mui-error': {
    borderColor: 'rgb(244, 67, 54)',
  },
}))

export const StyledFormControl = ({
  label,
  error,
  helperText,
  tooltip = null,
  ...rest
}: TextFieldProps & StyledFormFieldProps): ReactElement => {
  return (
    <FormControl variant="standard" fullWidth={true} margin={'dense'}>
      <InputLabel
        shrink
        htmlFor={rest.id}
        error={!!error}
        sx={(theme) => ({
          fontSize: 16,
          transform: 'none',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          color: theme.palette.text.primary,
        })}
      >
        {label}
        {tooltip !== null && (
          <CustomTooltip title={tooltip}>
            <HelpIcon
              sx={{
                fontSize: '16px',
                cursor: 'pointer',
                color: acrColorGrey,
              }}
            />
          </CustomTooltip>
        )}
      </InputLabel>
      <BootstrapInput error={!!error} {...rest} autoComplete="new-password" />
      {!!error && <FormHelperText error={!!error}>{helperText}</FormHelperText>}
    </FormControl>
  )
}

const ControllerStyledFormControl: FC<StyledFormFieldProps> = ({
  control,
  name,
  ...rest
}): ReactElement => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <StyledFormControl
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          error={!!error}
          helperText={!!error ? error.message : ''}
          {...rest}
        />
      )}
    />
  )
}

export default ControllerStyledFormControl
