import { Grid, Link, Typography, useTheme } from '@mui/material'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorBlue } = variables

const RegisterConfirmInvalid = () => {
  const theme = useTheme()
  return (
    <Grid
      container
      sx={{
        minWidth: '700px',
        width: '700px',
        justifyContent: 'center',
        margin: '100px auto',
        [theme.breakpoints.down('md')]: {
          minWidth: 'auto',
          width: '100%',
          padding: '16px',
          margin: '50px auto',
        },
      }}
    >
      <Typography variant="h1" align={'center'}>
        Confirmation link is invalid please create a&nbsp;
        <Link
          component={'a'}
          href={'https://helpdesk.amercareroyal.com/a/tickets/new'}
          target={'_blank'}
          sx={{
            fontWeight: 'bold',
            color: acrColorBlue,
            textDecorationColor: acrColorBlue,
          }}
        >
          helpdesk ticket
        </Link>
      </Typography>
    </Grid>
  )
}

export default RegisterConfirmInvalid
