import { InternalLink } from '~/components/UI/DataGrid/DataGrid'
import { Fields } from '~/types/Fields.model'
import { Invoice } from '~/types/Invoice.model'
import { TEXTS } from '~/utils/allConstants/Constants'

const { INVOICE_NUMBER, ORDER_NUMBER, CUSTOMER_PO, CARRIER, TRACKING_NUMBER } =
  TEXTS

const fields: Fields[] = [
  {
    id: 'invoiceNumber',
    label: INVOICE_NUMBER,
    minWidth: 120,
    filterFieldConfiguration: {
      type: 'freeText',
    },
    format: (value: string) => (
      <InternalLink to={`/invoices/${value}/details`}>{value}</InternalLink>
    ),
    sort: true,
  },
  {
    id: 'orderNumber',
    label: ORDER_NUMBER,
    minWidth: 110,
    filterFieldConfiguration: {
      type: 'freeText',
    },
    sort: true,
  },
  {
    id: 'customerPO',
    label: CUSTOMER_PO,
    minWidth: 100,
    filterFieldConfiguration: {
      type: 'freeText',
    },
    sort: true,
  },
  {
    id: 'shipper',
    label: CARRIER,
    minWidth: 100,
    sort: true,
  },
  {
    id: 'trackingNumber',
    label: TRACKING_NUMBER,
    minWidth: 50,
    format: (value: string, row: Invoice) =>
      row.trackingUrl ? (
        <InternalLink to={row.trackingUrl}>{value}</InternalLink>
      ) : (
        value
      ),
    sort: false,
  },
]

export { fields }
