import { useState } from 'react'

import NewCarrierRegisterForm from '../components/Register/NewCarrierRegisterForm'
import RegisterComplete from '../components/Register/RegisterComplete'

const NewCarrierRegisterRoute = () => {
  const [completeForm, setCompleteForm] = useState<boolean>(false)

  if (completeForm) {
    return <RegisterComplete />
  }

  return <NewCarrierRegisterForm complete={setCompleteForm} />
}

export default NewCarrierRegisterRoute
