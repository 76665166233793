import { Address, Bill } from '~/types/OrderDetailsData.model'

export const returnSpecificContent = [
  {
    id: 'returnDetailInfo',
    items: [
      {
        title: 'Sold to',
        dataId: 'billingAddress',
        id: 'billingAddress',
        fields: [
          {
            id: 'billToLocation',
          },
          { id: 'billToName' },
          { id: 'addressLine1' },
          { id: 'addressLine2' },
          { id: 'addressLine3' },
          {
            id: 'city',
            format: ({ city, state, postcode }: Address) =>
              `${city}, ${state} ${postcode}`,
          },
          { id: 'country' },
        ],
      },
      {
        title: 'Ship to',
        dataId: 'shippingAddress',
        id: 'shippingAddress',
        fields: [
          {
            id: 'billToLocation',
            format: ({ billToLocation, shipToLocation }: Bill) =>
              `${billToLocation}${shipToLocation ? `-${shipToLocation}` : ''}`,
          },
          { id: 'shipToName' },
          { id: 'addressLine1' },
          { id: 'addressLine2' },
          { id: 'addressLine3' },
          {
            id: 'city',
            format: ({ city, state, postcode }: Address) =>
              `${city}, ${state} ${postcode}`,
          },
          { id: 'country' },
        ],
      },
      {
        title: 'Return Details',
        id: 'returnDetails',
        infoText:
          'Order Number & Customer PO will be available once return is approved',
        fields: [
          {
            name: 'Order Number',
            id: 'raNumber',
          },
          { name: 'Invoice Number', id: 'invoiceNumber' },
          {
            name: 'Customer PO',
            id: 'raPurchaseOrder',
          },
          { name: 'Return Status', id: 'approvalStatus' },
          { name: 'Requested By', id: 'requestedBy' },
          { name: 'Return #', id: 'id' },
        ],
      },
    ],
  },
]
