import { useContext } from 'react'

import { useMutation, useQueryClient } from '@tanstack/react-query'

import { TEXTS } from '~/utils/allConstants/Constants'

import { NotificationContext } from '../components/Notification'
import { APIErrorRes } from '../types/APIErrorRes.model'
import { ReturnRequestApproval } from '../types/Approval.model'
import { PageParams } from '../types/PageParams.model'
import {
  CreateOrderReturn,
  DisputeDenial,
  OrderItems,
  ReturnReasons,
  Returns,
} from '../types/Return.model'

import axiosInstance from './axiosInstance'
import { getPreparedFilters } from './filters-utils'
import { RoleTypes } from './users'

const { SOMETHING_WENT_WRONG } = TEXTS

export const fetchReturns = ({
  filters = {},
  fields,
  ...params
}: PageParams<any>) => {
  const approvalStatusFilter = [
    {
      key: 'Approved',
      status: 'Approved',
      customStatus: 'null',
    },
    {
      key: 'Closed',
      status: 'Closed',
      customStatus: 'null',
    },
    {
      key: 'Approved RA-Expired',
      status: 'Closed',
      customStatus: 'APPROVED_RA_EXPIRED',
    },
    {
      key: 'Awaiting Product Receipt',
      status: 'Approved',
      customStatus: 'AWAITING_PRODUCT_RECEIPT',
    },
    {
      key: 'Approved-O',
      status: 'Approved',
      customStatus: 'APPROVED_O',
    },
    {
      key: 'Reopened',
      status: 'Reviewed',
      customStatus: 'REOPENED',
    },
  ]

  if (
    Object.keys(filters).includes('approvalStatus') &&
    approvalStatusFilter.some((keys) => keys.key === filters?.approvalStatus)
  ) {
    const { key, customStatus, status } = approvalStatusFilter.filter(
      (status) => status?.key === filters.approvalStatus
    )[0]

    if (key) {
      filters = {
        ...filters,
        approvalStatus: status,
        customStatus: customStatus,
      }
    }
  }

  if (
    !params.hasOwnProperty('isAssignedToMe') &&
    !params.hasOwnProperty('approverGroup')
  ) {
    if (!filters.approvalStatus) {
      filters.approvalStatus = params.q.approvalStatus.toString()
    }

    if (params.q.hasOwnProperty('accountFilters')) {
      if (params.q.accountFilters.hasOwnProperty('shipToNumber')) {
        filters.compoundShipToNumber = `${params.q.accountFilters.customerNumber}-${params.q.accountFilters.shipToNumber}`
      } else if (params.q.accountFilters.hasOwnProperty('customerNumber')) {
        filters.customerNumber = `${params.q.accountFilters.customerNumber}`
      }
    }

    if (params.q.hasOwnProperty(['submitter.email'])) {
      filters.submitterEmail = params.q['submitter.email']
    }
  }

  const preparedFilters = getPreparedFilters(filters, fields)

  const restparams = Object.keys(params)
    .filter((key) => !['additionalFilters', 'q'].includes(key))
    .reduce((obj, key) => {
      obj[key] = params[key]
      return obj
    }, {})

  return axiosInstance
    .get<Returns>('/v1/returns', {
      params: {
        ...restparams,
        ...preparedFilters,
      },
    })
    .then(({ data }) => {
      return {
        ...data,
        items: data.items.map((item: any) => ({
          ...item,
        })),
      }
    })
}

export const fetchReturnDetailsData = (id: string) => {
  return axiosInstance
    .get<ReturnRequestApproval>(`/v1/returns/${id}`)
    .then(({ data }) => {
      return {
        ...data,
        returnItems: data.orders[0].items.map((item: OrderItems) => ({
          ...item,
          id: item.id,
        })),
      }
    })
}

export const useCreateOrderReturn = () => {
  const alert = useContext(NotificationContext)
  const queryClient = useQueryClient()

  const createOrderReturn = useMutation(
    (data: FormData) => {
      return axiosInstance<CreateOrderReturn>({
        url: '/v1/returns',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: data,
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['return-details'])

        alert.success('Return placed successfully!')
      },
      onError: (e: APIErrorRes) => {
        alert.error(e.response.data.message || SOMETHING_WENT_WRONG)
      },
    }
  )

  return createOrderReturn
}

export const fetchReturnReasons = (role?: RoleTypes) => {
  const endPoints = [RoleTypes.CARRIER].includes(role)
    ? 'carrierReasons'
    : 'reasons'
  return axiosInstance
    .get<ReturnReasons>(`/v1/returns/directory/${endPoints}`)
    .then(({ data }) => {
      return {
        ...data,
      }
    })
}

export const useDisputeMutation = (id: string) => {
  const disputeMutation = useMutation((data) => {
    return axiosInstance
      .post<DisputeDenial>(`/v1/returns/${id}/dispute`, data)
      .then(({ data }) => {
        return data
      })
      .catch(({ response }) => {
        return Promise.reject(response)
      })
  })
  return disputeMutation
}

export const useIssueReopen = () => {
  const reopenMutation = useMutation((returnId: string) => {
    return axiosInstance
      .post(`/v1/returns/${returnId}/reopen`)
      .then(({ data }) => {
        return data
      })
      .catch((error: APIErrorRes) => {
        return Promise.reject(error)
      })
  })
  return reopenMutation
}
