import { FC, ReactElement } from 'react'

import { SvgIcon } from '@mui/material'

interface Props {
  color: string
}

const GroupIcon: FC<Props> = ({ color }): ReactElement => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-inside-1_1277_10462" fill="white">
          <rect x="8" y="2" width="14" height="14" rx="1" />
        </mask>
        <rect
          x="8"
          y="2"
          width="14"
          height="14"
          rx="1"
          stroke={color}
          strokeWidth="4"
          mask="url(#path-1-inside-1_1277_10462)"
        />
        <mask id="path-2-inside-2_1277_10462" fill="white">
          <rect x="2" y="8" width="14" height="14" rx="1" />
        </mask>
        <rect
          x="2"
          y="8"
          width="14"
          height="14"
          rx="1"
          stroke={color}
          strokeWidth="4"
          mask="url(#path-2-inside-2_1277_10462)"
        />
      </svg>
    </SvgIcon>
  )
}

export default GroupIcon
