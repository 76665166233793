import { FC, ReactElement } from 'react'

import { SvgIcon } from '@mui/material'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorMediumGrey } = variables

interface Props {
  color: string
}

const CalendarIcon: FC<Props> = ({ color }): ReactElement => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="24 / time / calendar">
          <path
            id="icon"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6 6H8V5H16V6H18V5H20V8H4V5H6V6ZM4 10V20H20V10H4ZM8 3H16V2H18V3H20C21.1046 3 22 3.89543 22 5V20C22 21.1046 21.1046 22 20 22H4C2.89543 22 2 21.1046 2 20V5C2 3.89543 2.89543 3 4 3H6V2H8V3Z"
            fill={color}
          />
        </g>
      </svg>
    </SvgIcon>
  )
}

const DatePickerOpenIcon: FC = () => <CalendarIcon color={acrColorMediumGrey} />

export default DatePickerOpenIcon
