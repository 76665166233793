import { FC, Fragment, ReactElement } from 'react'

import { Divider, Grid, Typography } from '@mui/material'

type HeaderType = {
  label: string
  value: number | string
}
interface Props {
  heading: string
  headerPara?: string
  data: HeaderType[]
}

const ModalHeader: FC<Props> = ({
  data,
  heading,
  headerPara,
}): ReactElement => {
  return (
    <>
      <Grid container my={2} display="flex">
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight={'bold'} mb={!headerPara && 4}>
            {heading}
          </Typography>
        </Grid>

        {headerPara && (
          <Grid item xs={12}>
            <Typography mb={4}>{headerPara}</Typography>
          </Grid>
        )}

        {data.map(({ label, value }: HeaderType, index: number) => (
          <Fragment key={index}>
            {!!value && (
              <Grid item xs={'auto'} mr={5}>
                <Typography color={(theme) => theme.palette.text.secondary}>
                  {label}
                </Typography>

                <Typography fontWeight={'bold'}>{value}</Typography>
              </Grid>
            )}
          </Fragment>
        ))}
      </Grid>

      <Divider />
    </>
  )
}

export default ModalHeader
