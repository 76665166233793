import { FC, ReactElement, useEffect, useMemo } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { Button, Grid, MenuItem } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import CustomTooltip from '~/components/common/customTooltip/CustomTooltip'
import ControllerStyledSelect from '~/components/Form/StyledSelect'
import { NoGridStyledTextField } from '~/components/Form/StyledTextField'
import Loading from '~/components/UI/Loading'
import { useAuth } from '~/services/auth'
import { fetchReturnReasons } from '~/services/returns'
import { ReturnItems, UpdateReturnData } from '~/types/Return.model'
import { TEXTS } from '~/utils/allConstants/Constants'

import './ReturnDetailInput.scss'

const { AVAILABLE_QTY, RETURN_QTY, REASON_CODE } = TEXTS

type Props = {
  updateData: (item: ReturnItems[] | []) => void
  row: UpdateReturnData
}

type InputItemProps = {
  update: (index: number, item: ReturnItems) => void
  remove: (index: number, item: ReturnItems) => void
  index: number
  value: ReturnItems
  isLast: boolean
  remainingItems: number
  fields: ReturnItems[]
}

const ReturnDetailInput: FC<Props> = ({ row, updateData }): ReactElement => {
  const { control, watch } = useForm({
    defaultValues: {
      return: [
        {
          reason: '',
          quantity: '',
        },
      ],
    },
  })
  const { fields, append, update, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'return', // unique name for your Field Array
  })

  const updateHandler = (index: number, data: ReturnItems) => {
    update(index, data)
    append({
      reason: '',
      quantity: '',
    })
  }
  const removeHandler = (index: number) => {
    remove(index)
  }

  const returnWatch = watch('return')

  const remainingItems = useMemo(() => {
    return (
      row.availableReturnQuantity -
      returnWatch.reduce((acc, i) => acc + Number(i.quantity), 0)
    )
  }, [returnWatch])

  useEffect(() => {
    updateData(
      returnWatch.filter((item) => item.quantity !== '' && item.reason !== '')
    )
  }, [returnWatch])

  return (
    <>
      {fields.map((field, index, array) => {
        return (
          <InputItem
            key={field.id}
            index={index}
            value={field}
            update={updateHandler}
            remove={removeHandler}
            isLast={array.length === index + 1}
            remainingItems={remainingItems}
            fields={array}
          />
        )
      })}
    </>
  )
}

const ReturnDetailInputHeader = () => {
  return (
    <Grid container spacing={2} sx={{ minWidth: 460, alignItems: 'center' }}>
      <Grid item sx={{ flex: 1, minWidth: 80 }}>
        {AVAILABLE_QTY}
      </Grid>
      <Grid item sx={{ flex: 1, minWidth: 150 }}>
        {RETURN_QTY}
      </Grid>
      <Grid item sx={{ flex: 1, minWidth: 150 }}>
        {REASON_CODE}
      </Grid>
      <Grid item sx={{ width: 70, alignSelf: 'center' }}>
        &nbsp;
      </Grid>
    </Grid>
  )
}

const InputItem: FC<InputItemProps> = ({
  update,
  remove,
  index,
  value,
  isLast,
  remainingItems,
  fields,
}) => {
  const { data: user } = useAuth()
  const schema = yup
    .object({
      reason: yup.string().default('').required('Reason should be set'),
      quantity: yup
        .number()
        .integer('Qty must be a integer')
        .typeError('Qty must be a number')
        .min(1, 'Qty must be greater than 1')
        .max(
          Number(remainingItems),
          `Qty must be less than or equal to ${remainingItems}`
        )
        .required('Qty should be set'),
    })
    .required()

  const { control, handleSubmit } = useForm({
    defaultValues: value,
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const handleAdd = (data: ReturnItems) => {
    update(index, data)
  }

  const handleRemove = (data?: any) => {
    remove(index, data)
  }

  const chosenReasons = useMemo(() => {
    return fields
      .reduce((acc: string[], i) => {
        acc.push(i.reason)
        return acc
      }, [])
      .filter((i: string) => i !== '')
  }, [fields])

  const { data: returnReasonCodes, isLoading } = useQuery(
    ['return-reason-code'],
    () => fetchReturnReasons(user.role)
  )

  if (isLoading) {
    return <Loading />
  }

  return (
    <Grid
      container
      spacing={2}
      className="content-wrapper"
      sx={{ minWidth: 460, alignItems: 'stretch' }}
    >
      <Grid item sx={{ flex: 1, minWidth: 80, alignSelf: 'center' }}>
        {index === 0 && (
          <div
            style={{
              color: remainingItems < 0 ? '#d32f2f' : '#000',
            }}
          >
            {remainingItems}
          </div>
        )}
      </Grid>
      <Grid item sx={{ flex: 1, minWidth: 150 }}>
        <NoGridStyledTextField
          type={'number'}
          name="quantity"
          control={control}
          placeholder="Return Qty"
          disabled={!isLast}
        />
      </Grid>
      <Grid item sx={{ flex: 1, minWidth: 150 }}>
        <ControllerStyledSelect
          name={'reason'}
          control={control}
          disabled={!isLast}
          className="margin-none"
          placeholder="Please select reason"
        >
          {Object.keys(returnReasonCodes).map((key) => {
            return (
              <MenuItem
                key={key}
                value={returnReasonCodes[key as keyof typeof returnReasonCodes]}
                disabled={chosenReasons.includes(
                  returnReasonCodes[key as keyof typeof returnReasonCodes]
                )}
              >
                {returnReasonCodes[key as keyof typeof returnReasonCodes]}
              </MenuItem>
            )
          })}
        </ControllerStyledSelect>
      </Grid>
      <Grid item sx={{ width: 70, alignSelf: 'center' }}>
        {isLast ? (
          <CustomTooltip title="Click + to add selections to return request">
            <Button onClick={handleSubmit(handleAdd)}>
              <AddCircleOutlineIcon />
            </Button>
          </CustomTooltip>
        ) : (
          <Button onClick={handleRemove}>
            <CancelOutlinedIcon />
          </Button>
        )}
      </Grid>
    </Grid>
  )
}

export { ReturnDetailInputHeader }

export default ReturnDetailInput
