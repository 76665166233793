import React from 'react'
import ReactGA from 'react-ga4'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { FormHelperText, Grid, Typography, useMediaQuery } from '@mui/material'
import { UseQueryResult } from '@tanstack/react-query'

import Logo from '~/images/acr-logo.png'
import { useForgotPasswordMutation } from '~/services/auth'
import { theme } from '~/theme/AppTheme'
import { User } from '~/types/User.model'
import { HEADER_HEIGHT, TEXTS } from '~/utils/allConstants/Constants'

import { BootstrapInput } from '../Form/StyledFormControl'

const { EMAIL_FORMAT_MESSAGE, NO_EMAIL_MESSAGE } = TEXTS

const schema = yup
  .object({
    email: yup.string().email(EMAIL_FORMAT_MESSAGE).required(NO_EMAIL_MESSAGE),
  })
  .required()

const ForgotPasswordForm = ({
  complete,
  auth,
}: {
  complete: (val: boolean) => void
  auth: UseQueryResult<User | undefined>
}) => {
  const forgotPasswordMutation = useForgotPasswordMutation()
  const isMatch = useMediaQuery(theme.breakpoints.down('md'))

  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  })
  const onSubmit = (form: { email: string }) => {
    forgotPasswordMutation.mutate(form, {
      onSuccess: () => {
        ReactGA.event({
          category: 'User',
          action: 'Forgot Password Request',
        })

        complete(true)
      },
    })
  }

  return (
    <Grid
      container
      alignItems={'center'}
      sx={{ height: isMatch ? '50%' : '100%' }}
      mt={isMatch && '25%'}
      px={!isMatch ? 8 : 2}
    >
      {auth.data && (
        <Grid item xs={12} md={6}>
          {!isMatch && (
            <img
              src={Logo}
              alt={Logo}
              style={{ height: `${HEADER_HEIGHT}px` }}
            />
          )}

          <Typography variant="h1">
            Don’t worry, we have your back on this.
            <br />
            We help people all the time.
          </Typography>
        </Grid>
      )}

      {!auth.data && (
        <Grid
          item
          xs={12}
          md={6}
          display="flex"
          justifyContent="center"
          pb={10}
        >
          <Typography variant="h1">Forgot Password?</Typography>
        </Grid>
      )}

      <Grid item xs={12} md={6}>
        <Typography sx={{ fontWeight: 'bold' }} mb={2}>
          Please enter your registered email address to change your password.
        </Typography>

        <Grid container alignItems={'center'}>
          <Grid item xs={4} pb={isMatch ? 2 : 0}>
            Email
          </Grid>
          <Grid item xs={12} md={8}>
            <Controller
              name={'email'}
              control={control}
              render={({ field, fieldState: { error } }) => (
                <>
                  <BootstrapInput
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!error}
                    fullWidth={true}
                  />
                  {!!error && (
                    <FormHelperText error={!!error}>
                      {!!error ? error.message : ''}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid
            item
            xs={true}
            sx={{ mt: 2 }}
            container
            justifyContent={'flex-end'}
          >
            <LoadingButton
              loading={forgotPasswordMutation.isLoading}
              variant="contained"
              size={'large'}
              fullWidth={isMatch}
              onClick={handleSubmit(onSubmit)}
            >
              Send Reset Email
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ForgotPasswordForm
