import * as yup from 'yup'

import {
  ApprovalStatusItemsCarrier,
  TEXTS,
  VALIDATION_MESSAGES,
} from '~/utils/allConstants/Constants'

const {
  WAREHOUSE_LOCATION_REQUIRED,
  APPROVAL_STATUS_REQUIRED,
  INTERNAL_REASON_REQUIRED,
  DENIAL_REASON_REQUIRED,
} = VALIDATION_MESSAGES
const { WAREHOUSE_MISPICK } = TEXTS
const { DENIED, APPROVED_RA } = ApprovalStatusItemsCarrier

export const schemaCarrierReturn = yup.object().shape({
  items: yup.array().of(
    yup.object().shape({
      itemCustomStatus: yup
        .string()
        .default('')
        .required(APPROVAL_STATUS_REQUIRED),
      denialReason: yup
        .string()
        .when('itemCustomStatus', (itemCustomStatus, field) => {
          if (itemCustomStatus === DENIED) {
            return yup.string().required(DENIAL_REASON_REQUIRED)
          }
          return field
        }),
      warehouseLocationName: yup
        .string()
        .nullable()
        .when('itemCustomStatus', (itemCustomStatus, field) => {
          if (itemCustomStatus === APPROVED_RA || itemCustomStatus === DENIED) {
            return yup.string().nullable().required(WAREHOUSE_LOCATION_REQUIRED)
          }
          return field
        }),
      internalReason: yup
        .string()
        .when('itemCustomStatus', (itemCustomStatus, field) => {
          if (itemCustomStatus === APPROVED_RA) {
            return yup.string().required(INTERNAL_REASON_REQUIRED)
          }
          return field
        }),
    })
  ),
})

export const schemaNormalReturn = yup.object().shape({
  items: yup.array().of(
    yup.object().shape({
      approvalStatus: yup
        .string()
        .default('')
        .required(APPROVAL_STATUS_REQUIRED),
      denialReason: yup
        .string()
        .when('approvalStatus', (approvalStatus, field) => {
          if (approvalStatus === DENIED) {
            return yup.string().required(DENIAL_REASON_REQUIRED)
          }
          return field
        }),
    })
  ),
})

export const schemaWarehouseMispickReturn = yup.object().shape({
  items: yup.array().of(
    yup.object().shape({
      approvalStatus: yup
        .string()
        .default('')
        .when('internalReason', (internalReason, field) => {
          if (internalReason === WAREHOUSE_MISPICK) {
            return yup.string().required(APPROVAL_STATUS_REQUIRED)
          }
          return field
        }),
      denialReason: yup
        .string()
        .when('approvalStatus', (approvalStatus, field) => {
          if (approvalStatus === DENIED) {
            return yup.string().required(DENIAL_REASON_REQUIRED)
          }
          return field
        }),
      internalReason: yup.string(),
    })
  ),
})
