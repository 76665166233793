import { InternalLink } from '~/components/UI/DataGrid/DataGrid'
import { Roles } from '~/services/users'
import { Fields } from '~/types/Fields.model'
import { TEXTS } from '~/utils/allConstants/Constants'
import { formatDate } from '~/utils/helpers/helperFunctions'

const {
  START_DATE,
  EXPIRATION,
  BANNER_NOTIFICATION,
  SECURITY_ROLE,
  CONTAINS_FILE,
} = TEXTS

enum SelectedRoles {
  ROLE_CUSTOMER = 'Customer',
  ROLE_BROKER = 'Broker',
  ROLE_EMPLOYEE = 'ACR Corporate Employee',
}

const fields: Fields[] = [
  {
    id: 'effectiveFrom',
    label: START_DATE,
    minWidth: 60,
    filterFieldConfiguration: {
      type: 'range-date',
    },
    format: (value: string) => formatDate(value),
    sort: false,
  },
  {
    id: 'expiration',
    label: EXPIRATION,
    minWidth: 60,
    filterFieldConfiguration: {
      type: 'range-date',
    },
    format: (value: string) => formatDate(value),
    sort: false,
  },
  {
    id: 'message',
    label: BANNER_NOTIFICATION,
    minWidth: 160,
    filterFieldConfiguration: {
      type: 'freeText',
    },
  },
  {
    id: 'role',
    label: SECURITY_ROLE,
    minWidth: 100,
    format: (value) => {
      return value
        .map((role: string) => {
          return Roles[role as keyof typeof Roles]
        })
        .join(', ')
    },
    sort: false,
    filterFieldConfiguration: {
      type: 'select',
      values: SelectedRoles,
    },
  },
  {
    id: 'fileUrl',
    label: CONTAINS_FILE,
    minWidth: 90,
    format: (value) => {
      return value ? <InternalLink to={value}>Attachment</InternalLink> : 'N/A'
    },
  },
]

export { fields }
