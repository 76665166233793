import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import ReactGA from 'react-ga4'
import { useParams } from 'react-router-dom'

import { capitalize } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import {
  createInvoiceComment,
  fetchAllInvoiceComments,
  fetchInvoiceComments,
  fetchInvoices,
} from '~/services/invoices'
import { useOrderPod } from '~/services/orderPod'
import { Account, SubAccount } from '~/types/Account.model'
import { Invoice, InvoiceState } from '~/types/Invoice.model'
import { TabsType } from '~/types/TabsType.model'
import CustomModal from '~/UI/modals/CustomModal'
import OrderInvoiceActionButton from '~/UI/modals/modalBodies/tableCommentModalBody/actionButton/OrderInvoiceActionButton'
import TableAttachFileModalBody from '~/UI/modals/modalBodies/tableCommentModalBody/TableAttachFileModalBody'
import TableCommentModalBody from '~/UI/modals/modalBodies/tableCommentModalBody/TableCommentModalBody'
import { TEXTS } from '~/utils/allConstants/Constants'
import { downloadFileRes, exportFile } from '~/utils/helpers/helperFunctions'

import { AccountsContext } from '../Accounts'
import useSynchAccountsNavigation from '../Accounts/hook/useSynchAccountsNavigation'
import ContentWrapper from '../UI/ContentWrapper/ContentWrapper'
import useDataGridStore, {
  DataGridProvider,
} from '../UI/DataGrid/DataGridContext'
import TabsTablePage from '../UI/TabsTablePage'

import { fields as allFields } from './components/AllInvoicesTabContent'
import { fields as closedFields } from './components/ClosedInvoicesTabContent'
import { fields as openFields } from './components/OpenInvoicesTabContent'

const { ALL_INVOICES, OPEN_INVOICES, CLOSED_INVOICES, NO_DATA_MESSAGE } = TEXTS

const Invoices = () => {
  const { active_tab } = useParams()
  const currentTab = active_tab || 'all'

  const { selectedAccount, selectedSubAccount } =
    useContext(AccountsContext) ||
    ({} as {
      selectedAccount: Account
      selectedSubAccount: SubAccount
    })
  const [openCommentModal, setOpenCommentModal] = useState(false)
  const [openAttachFileModal, setOpenAttachFileModal] = useState(false)
  const [attachFileModalData, setAttachFileModalData] = useState<Invoice>()
  const [commentModalData, setCommentModalData] = useState<Invoice>()
  const [attachFileRow, setAttachFileRow] = useState<Invoice>()

  const {
    setTableProps,
    tableProps,
    sort,
    filters: storeFilters,
    data,
  } = useDataGridStore()

  const { data: comments, isFetching } = useQuery(
    ['invoices', 'comments', data?.items],
    async () => {
      const invoiceNumbers = data?.items.map((itm: any) => itm.invoiceNumber)
      return await fetchAllInvoiceComments(invoiceNumbers)
    },
    {
      enabled: data?.items.length > 0,
      placeholderData: [],
    }
  )

  const useOrderPodMutation = useOrderPod()

  const toggleCommentModal = useCallback((rowData: Invoice) => {
    setOpenCommentModal((prevState) => {
      if (!prevState) {
        setCommentModalData(rowData)
      }
      return !prevState
    })
  }, [])

  useSynchAccountsNavigation(`invoices/${currentTab}`)

  const filters = useMemo(() => {
    const f = {} as { customerNumber: string; shipToNumber: string }
    if (selectedAccount) {
      f.customerNumber = selectedAccount.customerNumber

      if (selectedSubAccount) {
        f.shipToNumber = selectedSubAccount.shipToNumber
      }
    }
    return f
  }, [selectedAccount, selectedSubAccount])

  const handleExcelExport = useCallback(() => {
    ReactGA.event({
      category: 'Invoices',
      action: 'Export as Excel',
    })
    return exportFile({
      name: 'invoices',
      extension: 'xlsx',
      exportfilename: capitalize(currentTab) + 'Invoices',
      fields: tableProps.fields,
      filters: {
        page: 1,
        pageSize: 1000,
        ...tableProps.additionalFilters,
        ...storeFilters,
        sort: sort !== '' ? sort : undefined,
      },
    })
  }, [tableProps, storeFilters, sort])

  const toggleAttachFileModal = useCallback((rowData: Invoice) => {
    setAttachFileRow(rowData)
    useOrderPodMutation.mutate(rowData.orderNumber, {
      onSuccess: (response) => {
        const filename = `Order-POD-${rowData.orderNumber}.pdf`
        return downloadFileRes({ response, filename })
      },
      onError: () => {
        setOpenAttachFileModal((prevState) => {
          if (!prevState) {
            setAttachFileModalData(rowData)
          }
          return !prevState
        })
      },
    })
  }, [])

  const actions = [
    {
      render: (row: Invoice) => {
        return (
          <OrderInvoiceActionButton
            hasComments={comments?.[row.invoiceNumber]?.length}
            toggleAttachFileModal={toggleAttachFileModal.bind(this, row)}
            toggleCommentModal={toggleCommentModal.bind(this, row)}
            isFetching={isFetching}
            isOrderPodLoading={useOrderPodMutation.isLoading}
            hasAttachClick={row === attachFileRow}
          />
        )
      },
    },
  ]

  useEffect(() => {
    setTableProps((prevState: TabsType) => {
      return {
        ...prevState,
        actions: actions,
        additionalFilters: {
          state: prevState?.additionalFilters?.state,
          ...filters,
        },
      }
    })
  }, [filters, isFetching, useOrderPodMutation.isLoading, attachFileRow])

  const defaultProps = {
    source: fetchInvoices,
    actions,
    additionalFilters: filters,
    notFoundMessage: NO_DATA_MESSAGE('Invoices'),
  }

  const params: TabsType[] = [
    {
      label: ALL_INVOICES,
      name: 'all-invoices',
      ...defaultProps,
      fields: allFields,
    },
    {
      label: OPEN_INVOICES,
      name: 'open-invoices',
      ...defaultProps,
      fields: openFields,
      additionalFilters: {
        ...defaultProps.additionalFilters,
        state: InvoiceState.OPEN,
      },
    },
    {
      label: CLOSED_INVOICES,
      name: 'closed-invoices',
      ...defaultProps,
      fields: closedFields,
      additionalFilters: {
        ...defaultProps.additionalFilters,
        state: InvoiceState.CLOSED,
      },
    },
  ]

  return (
    <ContentWrapper>
      <TabsTablePage
        key={currentTab}
        currentTab={currentTab}
        tabs={params}
        handleExcelExport={handleExcelExport}
      />
      <CustomModal
        open={openCommentModal}
        setOpen={setOpenCommentModal}
        sx={{ width: '50%' }}
      >
        <TableCommentModalBody
          source={commentModalData}
          name={'invoices'}
          dataActions={{
            fetchSource: fetchInvoiceComments,
            createSource: createInvoiceComment,
          }}
        />
      </CustomModal>
      <CustomModal
        open={openAttachFileModal}
        setOpen={setOpenAttachFileModal}
        sx={{ width: '50%' }}
      >
        <TableAttachFileModalBody source={attachFileModalData} />
      </CustomModal>
    </ContentWrapper>
  )
}

// eslint-disable-next-line react/display-name
export default () => (
  <DataGridProvider>
    <Invoices />
  </DataGridProvider>
)
