import { PriceChangeHistoryFilter } from '../types/Filter.model'
import { PageParams } from '../types/PageParams.model'
import { ProductsPriceHistory } from '../types/Reports.model'
import { Method } from '../utils/allConstants/Constants'
import { downloadFile } from '../utils/helpers/helperFunctions'

import axiosInstance from './axiosInstance'
import { createQueryParams } from './filters-utils'

export const fetchProductsPriceHistory = ({
  filters = {},
  fields,
  ...params
}: PageParams<PriceChangeHistoryFilter>) => {
  const qParams = createQueryParams(filters, fields)
  return axiosInstance
    .get<ProductsPriceHistory>('/v1/acr/products/priceHistory', {
      params: {
        ...params,
        ...qParams,
      },
    })
    .then(({ data }) => {
      return { data, params }
    })
}

export const exportProdcutsHistoryFile = ({
  extension,
  accountId,
  expandedProductId,
}: {
  extension: string
  accountId: string
  expandedProductId: number[]
}) => {
  const url = '/v1/acr/products/priceHistory/export'
  const fileName = `PriceChangeHistory.${extension}`
  const combinedParams: {
    type: string
    accountId: string
    page: number
  } = {
    type: extension,
    accountId,
    page: 1,
  }
  return downloadFile(url, fileName, combinedParams, Method.POST, {
    expandedProductId: expandedProductId,
  })
}
