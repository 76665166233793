import React from 'react'

import AllApproval from '../components/Approval/All Approval/AllApproval'
import AllReturns from '../components/Approval/All Returns/AllReturns'
import ApprovalReturnRequestFetchInfo from '../components/Approval/ApprovalReturnRequest/ApprovalReturnRequestFetchInfo'
import MyApproval from '../components/Approval/My Approval/MyApproval'
import ReviewReturnRequestFetchInfo from '../components/Approval/ReviewReturnRequest/ReviewReturnRequestFetchInfo'
import { RoutesCheck } from '../utils/allConstants/Constants'
import { RedirectWithAccountParams } from '../utils/routes-util'

import CheckRoutes from './CheckRoutes'

export default [
  {
    path: 'approvals',
    element: <RedirectWithAccountParams to={'/approvals/all-approvals/csr'} />,
  },
  {
    path: 'approvals/all-approvals',
    element: <RedirectWithAccountParams to={'/approvals/all-approvals/csr'} />,
  },

  {
    path: 'approvals/all-approvals/:active_tab',
    element: (
      <CheckRoutes
        type={[
          RoutesCheck.NOT_CUSTOMER,
          RoutesCheck.NOT_BROKER,
          RoutesCheck.NOT_SERVICE_EMPLOYEE,
          RoutesCheck.NOT_CARRIER,
        ]}
      >
        <AllApproval />
      </CheckRoutes>
    ),
  },
  {
    path: 'approvals/all-approvals/csr/:id/details',
    element: (
      <CheckRoutes
        type={[
          RoutesCheck.NOT_CUSTOMER,
          RoutesCheck.NOT_BROKER,
          RoutesCheck.NOT_SERVICE_EMPLOYEE,
          RoutesCheck.NOT_CARRIER,
        ]}
      >
        <ReviewReturnRequestFetchInfo />
      </CheckRoutes>
    ),
  },
  {
    path: 'approvals/all-approvals/css/:id/details',
    element: (
      <CheckRoutes
        type={[
          RoutesCheck.NOT_CUSTOMER,
          RoutesCheck.NOT_BROKER,
          RoutesCheck.NOT_SERVICE_EMPLOYEE,
          RoutesCheck.NOT_CARRIER,
        ]}
      >
        <ApprovalReturnRequestFetchInfo />
      </CheckRoutes>
    ),
  },
  {
    path: 'approvals/all-approvals/director/:id/details',
    element: (
      <CheckRoutes
        type={[
          RoutesCheck.NOT_CUSTOMER,
          RoutesCheck.NOT_BROKER,
          RoutesCheck.NOT_SERVICE_EMPLOYEE,
          RoutesCheck.NOT_CARRIER,
        ]}
      >
        <ApprovalReturnRequestFetchInfo />
      </CheckRoutes>
    ),
  },
  {
    path: 'approvals/all-approvals/vp/:id/details',
    element: (
      <CheckRoutes
        type={[
          RoutesCheck.NOT_CUSTOMER,
          RoutesCheck.NOT_BROKER,
          RoutesCheck.NOT_SERVICE_EMPLOYEE,
          RoutesCheck.NOT_CARRIER,
        ]}
      >
        <ApprovalReturnRequestFetchInfo />
      </CheckRoutes>
    ),
  },
  {
    path: 'approvals/all-approvals/lc/:id/details',
    element: (
      <CheckRoutes
        type={[
          RoutesCheck.NOT_CUSTOMER,
          RoutesCheck.NOT_BROKER,
          RoutesCheck.NOT_SERVICE_EMPLOYEE,
          RoutesCheck.NOT_CARRIER,
        ]}
      >
        <ApprovalReturnRequestFetchInfo />
      </CheckRoutes>
    ),
  },
  //All Returns
  {
    path: 'approvals/all-returns',
    element: (
      <RedirectWithAccountParams to={'/approvals/all-returns/open-returns'} />
    ),
  },
  {
    path: 'approvals/all-returns/:active_tab',
    element: (
      <CheckRoutes
        type={[
          RoutesCheck.NOT_CUSTOMER,
          RoutesCheck.NOT_BROKER,
          RoutesCheck.NOT_SERVICE_EMPLOYEE,
          RoutesCheck.NOT_CARRIER,
        ]}
      >
        <AllReturns />
      </CheckRoutes>
    ),
  },
  {
    path: 'approvals/all-returns/open-returns/:id/review-details',
    element: (
      <CheckRoutes
        type={[
          RoutesCheck.NOT_CUSTOMER,
          RoutesCheck.NOT_BROKER,
          RoutesCheck.NOT_SERVICE_EMPLOYEE,
          RoutesCheck.NOT_CARRIER,
        ]}
      >
        <ReviewReturnRequestFetchInfo />
      </CheckRoutes>
    ),
  },
  {
    path: 'approvals/all-returns/open-returns/:id/approve-details',
    element: (
      <CheckRoutes
        type={[
          RoutesCheck.NOT_CUSTOMER,
          RoutesCheck.NOT_BROKER,
          RoutesCheck.NOT_SERVICE_EMPLOYEE,
          RoutesCheck.NOT_CARRIER,
        ]}
      >
        <ApprovalReturnRequestFetchInfo />
      </CheckRoutes>
    ),
  },
  //My Approval
  {
    path: 'approvals/my-approvals',
    element: (
      <RedirectWithAccountParams to={'/approvals/my-approvals/assigned'} />
    ),
  },
  {
    path: 'approvals/my-approvals/:active_tab',
    element: (
      <CheckRoutes
        type={[
          RoutesCheck.NOT_CUSTOMER,
          RoutesCheck.NOT_BROKER,
          RoutesCheck.NOT_SERVICE_EMPLOYEE,
          RoutesCheck.NOT_CARRIER,
        ]}
      >
        <MyApproval />
      </CheckRoutes>
    ),
  },
  {
    path: 'approvals/my-approvals/assigned/:id/review-details',
    element: (
      <CheckRoutes
        type={[
          RoutesCheck.NOT_CUSTOMER,
          RoutesCheck.NOT_BROKER,
          RoutesCheck.NOT_SERVICE_EMPLOYEE,
          RoutesCheck.NOT_CARRIER,
        ]}
      >
        <ReviewReturnRequestFetchInfo />
      </CheckRoutes>
    ),
  },
  {
    path: 'approvals/my-approvals/assigned/:id/approve-details',
    element: (
      <CheckRoutes
        type={[
          RoutesCheck.NOT_CUSTOMER,
          RoutesCheck.NOT_BROKER,
          RoutesCheck.NOT_SERVICE_EMPLOYEE,
          RoutesCheck.NOT_CARRIER,
        ]}
      >
        <ApprovalReturnRequestFetchInfo />
      </CheckRoutes>
    ),
  },
]
