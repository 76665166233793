import { FC, ReactElement, useState } from 'react'

import { Box, Divider, Grid, Link, Typography } from '@mui/material'

import { InternalLink } from '~/components/UI/DataGrid/DataGrid'
import { CustomerAlert } from '~/types/CustomerAlert.model'
import { TEXTS } from '~/utils/allConstants/Constants'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorBlue, acrColorGreenishWhite, acrColorGreen } = variables
const { VIEW_MORE, MARK_AS_READ } = TEXTS

type Props = {
  bannerFormData: CustomerAlert
  header?: boolean
  fileUrl?: boolean
  handleMarkAsRead?: (id: string) => void
  isLoading?: boolean
}

export const StyledLink: FC<{ children: string; onClick: () => void }> = ({
  children,
  onClick,
}) => {
  return (
    <Link
      sx={{
        cursor: 'pointer',
        textDecoration: 'none',
        wordBreak: 'break-all',
        color: acrColorBlue,
        fontWeight: '700',
        '&:hover': {
          textDecoration: 'underline',
        },
      }}
      onClick={onClick}
    >
      {children}
    </Link>
  )
}

export const BannerPreview: FC<Props> = ({
  bannerFormData,
  header = true,
  fileUrl = true,
  handleMarkAsRead,
  isLoading,
}): ReactElement => {
  const [viewMore, setViewMore] = useState(false)

  return (
    <>
      {header && (
        <>
          <Typography variant="h3" pb={3}>
            Banner Preview
          </Typography>

          <Divider />
        </>
      )}

      <Box
        bgcolor={acrColorGreenishWhite}
        mt={!header ? 0 : 4}
        mb={2}
        sx={{ border: 1, borderColor: acrColorGreen, borderRadius: 4 }}
      >
        <Grid p={2}>
          <Typography>
            {bannerFormData.message}&nbsp;
            <StyledLink onClick={() => setViewMore((prev) => !prev)}>
              {VIEW_MORE}
            </StyledLink>
          </Typography>
          {viewMore && (
            <Typography>
              {bannerFormData.details}
              {bannerFormData.includeAttachment && (
                <Typography component="span">
                  &nbsp; Click&nbsp;
                  <InternalLink to={fileUrl ? bannerFormData.fileUrl : ''}>
                    here
                  </InternalLink>
                  &nbsp;to learn more.
                </Typography>
              )}
            </Typography>
          )}
          <Typography align="right">
            {isLoading ? (
              'Loading...'
            ) : (
              <StyledLink
                onClick={() => !header && handleMarkAsRead(bannerFormData.id)}
              >
                {MARK_AS_READ}
              </StyledLink>
            )}
          </Typography>
        </Grid>
      </Box>
    </>
  )
}
