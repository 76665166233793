import { FC, ReactElement, useEffect, useState } from 'react'
import { Control, UseFormResetField, UseFormSetValue } from 'react-hook-form'

import { Box, Grid, MenuItem, SelectChangeEvent } from '@mui/material'

import ControllerStyledSelect from '~/components/Form/StyledSelect'
import LabelAndValue from '~/components/UI/LabelAndValue/LabelAndValue'
import { GlobalLoading } from '~/components/UI/Loading'
import {
  DenialReasons,
  InternalReasons,
  UpdateReturnData,
  Warehouse,
} from '~/types/Return.model'
import {
  ApprovalStatusItems,
  ApprovalStatusItemsCarrier,
  ReturnItemPickupOptions,
  TEXTS,
} from '~/utils/allConstants/Constants'
import {
  getApprovalStatus,
  getSelectedInternalReason,
  isMisPickSelectedAsInternalReason,
} from '~/utils/helpers/helperFunctions'

const { APPROVED, DENIED } = ApprovalStatusItems
const { APPROVED_NO_RA, APPROVED_RA } = ApprovalStatusItemsCarrier
const {
  APPROVAL_STATUS,
  DENIAL_REASON_CODE,
  WAREHOUSE_LOCATIONS,
  INTERNAL_REASON,
  WRONG_ITEM,
  PICKUP_INFO,
  COMMENTS,
} = TEXTS

type Props = {
  control: Control<any>
  index: number
  row: UpdateReturnData
  resetField: UseFormResetField<any>
  setValue: UseFormSetValue<any>
  isCarrierReturn: boolean
  denialReasonsCodes: DenialReasons
  internalReasonCodes: InternalReasons
  warehouse: Warehouse[]
}
const ApprovalReturnRequestDetailsInput: FC<Props> = ({
  control,
  index,
  row,
  resetField,
  setValue,
  isCarrierReturn,
  denialReasonsCodes,
  internalReasonCodes,
  warehouse,
}): ReactElement => {
  const [APvalue, setAPvalue] = useState('')

  const isMisPickSelected = isMisPickSelectedAsInternalReason({
    internalReasonCodes,
    internalReason: row.internalReason,
  })

  const approvedOptions: string[] = [APPROVED_NO_RA, APPROVED_RA, APPROVED]

  const isApproved = (value: string) => approvedOptions.includes(value)

  // Note: case of simple return with warehouse mispick selected as internal reason
  const isFieldDisabled = isCarrierReturn === null && !isMisPickSelected

  useEffect(() => {
    if (denialReasonsCodes != undefined && warehouse != undefined) {
      setAPvalue(
        row.approved
          ? isCarrierReturn
            ? ApprovalStatusItemsCarrier[
                row.itemCustomStatus as keyof typeof ApprovalStatusItemsCarrier
              ]
            : APPROVED
          : DENIED
      )
      setValue(
        `items.${index}.alreadyValidated`,
        row.systemDenied ? true : false
      )
      setValue(`items.${index}.productId`, row.productId)
      setValue(`items.${index}.reason`, row.reason)
      setValue(`items.${index}.lineNumber`, row.lineNumber)
      setValue(
        `items.${index}.itemCustomStatus`,
        row.approved == false
          ? DENIED
          : ApprovalStatusItemsCarrier[
              row.itemCustomStatus as keyof typeof ApprovalStatusItemsCarrier
            ]
      )
      setValue(`items.${index}.approvalStatus`, getApprovalStatus(row.approved))
      setValue(
        `items.${index}.warehouseLocationName`,
        row?.warehouseMixin &&
          `${row?.warehouseMixin?.locationNumber} - ${row?.warehouseMixin?.name}`
      )
      setValue(
        `items.${index}.internalReason`,
        getSelectedInternalReason({
          internalReasonCodes,
          internalReason: row.internalReason,
        })
      )
      const name = `items.${index}.denialReason`
      setValue(name, '')
      if (!row.itemDenialReasonList) {
        setValue(name, '')
      } else {
        const list = row.itemDenialReasonList
          .filter((item) => item != null)
          .map(
            (item) =>
              denialReasonsCodes[item as keyof typeof denialReasonsCodes]
          )
        setValue(name, list[0])
      }
      return
    }
    setValue(`items.${index}.itemCustomStatus`, '')
    setValue(`items.${index}.approvalStatus`, '')
    setValue(`items.${index}.denialReason`, '')
    setValue(`items.${index}.internalReason`, '')
  }, [denialReasonsCodes, row, warehouse, internalReasonCodes])

  const handleChange = (event: SelectChangeEvent) => {
    setAPvalue(event.target.value)
    if (isApproved(event.target.value)) {
      resetField(`items.${index}.denialReason`)
    }

    if (event.target.value === APPROVED_NO_RA) {
      resetField(`items.${index}.warehouseLocationName`)
    }

    if (
      (event.target.value === APPROVED_NO_RA ||
        event.target.value === DENIED) &&
      !isMisPickSelected
    ) {
      resetField(`items.${index}.internalReason`)
    }
  }

  if (
    denialReasonsCodes === undefined ||
    warehouse === undefined ||
    internalReasonCodes === undefined
  ) {
    return <GlobalLoading />
  }

  const sortedDenialReasonsCodes = Object.values(denialReasonsCodes).sort()
  const sortedInternalReasonCode = Object.values(internalReasonCodes).sort()

  const details = [
    {
      label: WRONG_ITEM,
      value: row.receivedItemNumber,
    },
    {
      label: PICKUP_INFO,
      value:
        ReturnItemPickupOptions[
          row.pickUpInformation as keyof typeof ReturnItemPickupOptions
        ],
    },
    {
      label: COMMENTS,
      value: row.comment,
    },
  ]

  return (
    <Grid
      container
      spacing={2}
      sx={{ minWidth: 600, alignItems: 'stretch', flex: 1 }}
    >
      <Grid item xs={3} sm={3} md={3} sx={{ alignItems: 'stretch' }}>
        {row.systemDenied ? (
          <Box className="flexVerticalCenter">{DENIED}</Box>
        ) : isCarrierReturn ? (
          <ControllerStyledSelect
            key={JSON.stringify(row)}
            name={`items.${index}.itemCustomStatus`}
            control={control}
            onCustomChange={handleChange}
            disabled={isFieldDisabled}
            placeholder="Please select status"
          >
            {Object.keys(ApprovalStatusItemsCarrier).map((key) => {
              return (
                <MenuItem
                  key={key}
                  value={
                    ApprovalStatusItemsCarrier[
                      key as keyof typeof ApprovalStatusItemsCarrier
                    ]
                  }
                >
                  {
                    ApprovalStatusItemsCarrier[
                      key as keyof typeof ApprovalStatusItemsCarrier
                    ]
                  }
                </MenuItem>
              )
            })}
          </ControllerStyledSelect>
        ) : (
          <ControllerStyledSelect
            key={JSON.stringify(row)}
            name={`items.${index}.approvalStatus`}
            control={control}
            onCustomChange={handleChange}
            disabled={isFieldDisabled}
            placeholder="Please select status"
          >
            {Object.keys(ApprovalStatusItems).map((key) => {
              return (
                <MenuItem
                  key={key}
                  value={
                    ApprovalStatusItems[key as keyof typeof ApprovalStatusItems]
                  }
                >
                  {ApprovalStatusItems[key as keyof typeof ApprovalStatusItems]}
                </MenuItem>
              )
            })}
          </ControllerStyledSelect>
        )}
      </Grid>
      <Grid item xs={3} sm={3} md={3}>
        {row.systemDenied ? (
          <Box className="flexVerticalCenter">
            {row.itemDenialReasonList
              .map(
                (item) =>
                  denialReasonsCodes[item as keyof typeof denialReasonsCodes]
              )
              .join(', ')}
          </Box>
        ) : (
          <ControllerStyledSelect
            key={`${row.id}-${row.code}`}
            name={`items.${index}.denialReason`}
            control={control}
            disabled={isApproved(APvalue) || isFieldDisabled}
            placeholder="Please select denial reason"
          >
            {sortedDenialReasonsCodes.map((key) => {
              return (
                <MenuItem key={key} value={key}>
                  {key}
                </MenuItem>
              )
            })}
          </ControllerStyledSelect>
        )}
      </Grid>
      <Grid item xs={3} sm={3} md={3}>
        <ControllerStyledSelect
          key={`${row.id}-${row.code}`}
          name={`items.${index}.warehouseLocationName`}
          placeholder="Please select warehouse location"
          control={control}
          disabled={APvalue === APPROVED_NO_RA || isFieldDisabled}
        >
          {Object.values(warehouse).map((location: Warehouse) => {
            return (
              <MenuItem
                key={location.locationNumber}
                value={`${location.locationNumber} - ${location.name}`}
              >
                {location.locationNumber} - {location.name}
              </MenuItem>
            )
          })}
        </ControllerStyledSelect>
      </Grid>
      <Grid item xs={3} sm={3} md={3}>
        <ControllerStyledSelect
          name={`items.${index}.internalReason`}
          control={control}
          placeholder="Please select internal reason"
          disabled={
            APvalue === APPROVED_NO_RA || isMisPickSelected || isFieldDisabled
          }
        >
          {sortedInternalReasonCode.map((key) => {
            return (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            )
          })}
        </ControllerStyledSelect>
      </Grid>
      {isMisPickSelected && isCarrierReturn === null && (
        <Grid item xs={12} sx={{ alignItems: 'stretch' }}>
          <LabelAndValue items={details} />
        </Grid>
      )}
    </Grid>
  )
}

const ApprovalReturnRequestDetailsInputHeader = () => {
  return (
    <Grid container spacing={2} sx={{ minWidth: 600, alignItems: 'center' }}>
      <Grid item xs={3} sm={3} md={3}>
        {APPROVAL_STATUS}
      </Grid>
      <Grid item xs={3} sm={3} md={3}>
        {DENIAL_REASON_CODE}
      </Grid>
      <Grid item xs={3} sm={3} md={3}>
        {WAREHOUSE_LOCATIONS}
      </Grid>
      <Grid item xs={3} sm={3} md={3}>
        {INTERNAL_REASON}
      </Grid>
    </Grid>
  )
}

export { ApprovalReturnRequestDetailsInputHeader }

export default ApprovalReturnRequestDetailsInput
