import { Dispatch, SetStateAction, useState } from 'react'
import ReactGA from 'react-ga4'
import { useForm } from 'react-hook-form'
import { Link as RouterLink } from 'react-router-dom'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Link, Typography, useMediaQuery } from '@mui/material'

import { useRegisterMutation } from '~/services/auth'
import { theme } from '~/theme/AppTheme'
import { RegisterUserForm } from '~/types/UserForm.model'
import { TEXTS } from '~/utils/allConstants/Constants'
import {
  companyNameValidation,
  companyPhoneValidation,
  confirmPasswordValidation,
  emailValidation,
  firstNameValidation,
  lastNameValidation,
  mobilePhoneRequiredValidation,
  passwordYupValidation,
  responsibilityAreaValidation,
  textFieldValidation,
  userTypeValidation,
} from '~/utils/helpers/helperFunctions'

import UserRegistrationForm from './components/UserRegistrationForm'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorWhite, acrColorBlue } = variables
const { REGISTER, REGISTER_NEW_USER } = TEXTS

const schema = yup
  .object({
    contactEmail: emailValidation,
    password: passwordYupValidation,
    confirm_password: confirmPasswordValidation,
    responsibilityArea: responsibilityAreaValidation,
    responsibilityAreaOther: textFieldValidation,
    userType: userTypeValidation,
    userTypeOther: textFieldValidation,
    firstName: firstNameValidation,
    lastName: lastNameValidation,
    contactPhone: mobilePhoneRequiredValidation,
    company: companyNameValidation,
    companyContactPhone: companyPhoneValidation,
  })
  .required()

const RegisterForm = ({
  complete,
}: {
  complete: Dispatch<SetStateAction<boolean>>
}) => {
  const registerMutation = useRegisterMutation()

  // FIXME: need to be improvement
  const [responseError, setResponseError] = useState('')

  const { handleSubmit, control, watch } = useForm<RegisterUserForm>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({}),
  })
  const isMatch = useMediaQuery(theme.breakpoints.down('md'))

  const onSubmit = (data: RegisterUserForm) => {
    setResponseError('')

    if (
      data.responsibilityArea === 'Other' &&
      data.responsibilityAreaOther !== ''
    ) {
      data.responsibilityArea = data.responsibilityAreaOther
    }
    if (data.userType === 'Other' && data.userTypeOther !== '') {
      data.userType = data.userTypeOther
    }
    data.contactEmail = data.contactEmail.trim()
    registerMutation.mutate(data, {
      onSuccess: () => {
        ReactGA.event({
          category: 'User',
          action: 'Registration Success',
        })

        complete(true)
      },
      onError: (error: any) => {
        // FIXME: need to be improvement
        let message = error.data.message
        if (error.data.status === 'CONFLICT') {
          message = (
            <>
              Email &#39;{data.contactEmail}&#39; is already in use. Please log
              in or request a&nbsp;
              <Link component={RouterLink} to={'/forgot-password'}>
                password reset
              </Link>
              .
            </>
          )
        }

        setResponseError(message)
      },
    })
  }

  return (
    <Box sx={{ maxWidth: '100%', mx: 'auto' }}>
      <Grid
        container
        sx={[
          {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            mt: 0,
          },
          !isMatch && {
            background: acrColorWhite,
            borderRadius: '10px',
          },
        ]}
      >
        <Grid item xs={12} sm={12} md={12} lg={6} px={{ xs: 2, sm: 2, md: 3 }}>
          <Typography variant="h1" align={'center'}>
            {REGISTER_NEW_USER}
          </Typography>
          <Typography
            variant={'h4'}
            align={'center'}
            sx={{ color: (theme) => theme.palette.text.secondary, py: 3 }}
          >
            The ACR portal is for existing ACR customers only. If you are an
            existing ACR customer, please complete this form to register for a
            user ID.
            <br />
            <br />
            Only requests from existing customers will be approved for access.
            Non-ACR existing customers, please click&nbsp;
            <Link
              href={'https://amercareroyal.com/pages/open-an-account'}
              target={'_blank'}
              sx={{
                color: acrColorBlue,
                textDecorationColor: acrColorBlue,
              }}
            >
              here
            </Link>
            &nbsp; for more information on how to open a customer account.
          </Typography>
        </Grid>
        <UserRegistrationForm
          control={control}
          watch={watch}
          registerMutation={registerMutation}
          responseError={responseError}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          submitButton={REGISTER}
          password={true}
          type="newUser"
        />
      </Grid>
    </Box>
  )
}

export default RegisterForm
