import React from 'react'
import { useParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'

import { GlobalLoading } from '~/components/UI/Loading'
import { fetchReturnDetailsData } from '~/services/returns'
import { TEXTS } from '~/utils/allConstants/Constants'

import { fields } from './components/ReviewReturnRequestContent'
import ReviewReturnRequestInfoDisplayPage from './ReviewReturnRequestInfoDisplayPage'

const { RETURN_REQUEST_REVIEW } = TEXTS

const options = {
  name: RETURN_REQUEST_REVIEW,
  queryName: 'return-info-details-review',
  tableProps: {
    fields,
  },
}
const ReviewReturnRequestFetchInfo = () => {
  const { id } = useParams<{ id?: string }>()

  const { data, isFetching } = useQuery(
    [`return-info-details-review`, id],
    () => {
      return fetchReturnDetailsData(id)
    },
    { keepPreviousData: true }
  )

  if (isFetching) {
    return <GlobalLoading />
  }

  return (
    <ReviewReturnRequestInfoDisplayPage
      data={data}
      options={{ ...options, id }}
    />
  )
}

export default ReviewReturnRequestFetchInfo
