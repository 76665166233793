export type Invoice = {
  id?: string
  invoiceNumber: number
  orderNumber: string
  invoiceDate: any
  dueDate: null
  state: InvoiceState
  status: null
  balance: number
  customerPO: string
  invoiceAmount: number
  shipper: string
  trackingNumber: string
  trackingUrl: string
}

export type InvoiceSummary = {
  items: Invoice[]
  totalCount: number
}

export enum InvoiceState {
  OPEN = 'Open',
  CLOSED = 'Closed',
}
