import { useContext } from 'react'

import { useMutation } from '@tanstack/react-query'

import { TEXTS } from '~/utils/allConstants/Constants'

import { NotificationContext } from '../components/Notification'
import { APIErrorRes } from '../types/APIErrorRes.model'
import { SiteSettings } from '../types/SiteSettings.model'

import axiosInstance from './axiosInstance'

const { SOMETHING_WENT_WRONG } = TEXTS

export const useSiteSettings = () => {
  const alert = useContext(NotificationContext)

  const updateSiteSettings = useMutation(
    (data: any) => {
      return axiosInstance<SiteSettings>({
        url: '/v1/settings/price-notification',
        method: 'PUT',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: data,
      })
    },
    {
      onSuccess: () => {
        alert.success('Price Change Notification data updated successfully!')
      },
      onError: (e: APIErrorRes) => {
        alert.error(e.response.data.message || SOMETHING_WENT_WRONG)
      },
    }
  )

  return updateSiteSettings
}

export const fetchSiteSettings = () => {
  return axiosInstance
    .get<SiteSettings>(`/v1/settings/price-notification`)
    .then(({ data }) => {
      return data
    })
}
