import { useCallback } from 'react'
import { useParams } from 'react-router'

import { Divider, Grid, Link, Stack, Typography } from '@mui/material'
import { useQueries } from '@tanstack/react-query'

import Loading, { GlobalLoading } from '~/components/UI/Loading'
import {
  fetchACREmployeeJobTitles,
  fetchAllAccountsByUserId,
  fetchAllSalesmenByUserId,
  fetchAllSubAccountsByUserId,
  fetchAreaOfResponsibilities,
  fetchUserById,
  RoleTypes,
  useAllAccounts,
  useAllSalesmen,
  useAllSubAccounts,
  useAttachAccountToUser,
  useAttachSalesmanToUser,
  useAttachSubAccountToUser,
  useDetachAccountFromUser,
  useDetachSalesmanFromUser,
  useDetachSubAccountFromUser,
  usePasswordResetPassword,
  useResendConfirmationEmail,
  UserTypes,
} from '~/services/users'

import {
  accountsTableFields,
  mappedSalesmenFields,
  subAccountsTableFields,
} from './fields'
import OneToManyMapper from './OneToManyMapper'
import UserEditForm from './UserEditForm'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorBlue } = variables

const UserEdit = () => {
  const { customerId } = useParams()
  const { mutate: resetPassword, isLoading: isLoadingResetPassword } =
    usePasswordResetPassword()
  const { mutate: resendConfirmation, isLoading: isLoadingConfirmationEmail } =
    useResendConfirmationEmail()

  const [
    { data: user, isLoading: isLoadingUser },
    { data: areaOfResponsibilities, isLoading: isLoadingArea },
    { data: acrJobTitles, isLoading: isLoadingJobTitles },
  ] = useQueries({
    queries: [
      {
        queryKey: ['users', customerId],
        queryFn: () => fetchUserById(customerId),
      },
      {
        queryKey: ['area-Of-Responsibility'],
        queryFn: fetchAreaOfResponsibilities,
      },
      {
        queryKey: ['acr-job-titles'],
        queryFn: fetchACREmployeeJobTitles,
      },
    ],
  })

  const handleResetPassword = useCallback(() => {
    resetPassword(user.contactEmail)
  }, [user])

  const handleResendConfirm = useCallback(() => {
    resendConfirmation(user.contactEmail)
  }, [user])

  const useAttachAccount = (_, cb) => {
    return useAttachAccountToUser(user, cb)
  }

  const useAtttachSubAcccount = (_, cb) => {
    return useAttachSubAccountToUser(user, cb)
  }

  if (isLoadingUser || isLoadingArea || isLoadingJobTitles) {
    return <Loading />
  }

  // user.userTypeOther = ''
  if (
    user.userType !== 'Other' &&
    !Object.values(UserTypes).includes(user.userType)
  ) {
    user.userTypeOther = user.userType
    user.userType = 'Other'
  }

  // user.responsibilityAreaOther = ''
  if (
    user.responsibilityArea !== 'Other' &&
    !Object.values(areaOfResponsibilities).includes(user.responsibilityArea)
  ) {
    user.responsibilityAreaOther = user.responsibilityArea
    user.responsibilityArea = 'Other'
  }

  return (
    <>
      {(isLoadingResetPassword || isLoadingConfirmationEmail) && (
        <GlobalLoading />
      )}

      <Typography variant="h3" pb={3}>
        User Details
      </Typography>

      <Divider />

      <UserEditForm
        user={user}
        areaOfResponsibilities={areaOfResponsibilities}
        acrJobTitles={acrJobTitles}
      />

      <Grid container sx={{ mt: 2, textAlign: 'right' }}>
        <Grid item xs={true}>
          <Stack
            direction="row"
            spacing={2}
            sx={{ justifyContent: 'flex-end' }}
          >
            <Link
              component={'button'}
              onClick={handleResendConfirm}
              sx={{
                fontSize: '16px',
                fontWeight: 'bold',
                color: acrColorBlue,
                textDecorationColor: acrColorBlue,
              }}
            >
              Resend Confirmation Email
            </Link>

            <Link
              component={'button'}
              onClick={handleResetPassword}
              sx={{
                fontSize: '16px',
                fontWeight: 'bold',
                color: acrColorBlue,
                textDecorationColor: acrColorBlue,
              }}
            >
              Send Password Reset Email
            </Link>
          </Stack>
        </Grid>
      </Grid>
      {(user.securityRole === RoleTypes.CUSTOMER ||
        user.securityRole === RoleTypes.BROKER) && <Divider sx={{ mt: 3 }} />}

      {user.securityRole === RoleTypes.CUSTOMER && (
        <>
          <OneToManyMapper
            user={user}
            title={'Customer Account Access'}
            useAll={useAllAccounts}
            useAttach={useAttachAccount}
            useDetach={useDetachAccountFromUser}
            getOptionLabel={(option) =>
              `${option.name} - ${option.customerNumber}`
            }
            tableProps={{
              fields: accountsTableFields,
              name: `manage_users_${user.id}_accounts`,
              source: (...params) => {
                return fetchAllAccountsByUserId(user, ...params)
              },
            }}
          />

          <OneToManyMapper
            user={user}
            title={'Customer Sub-Account Access'}
            useAll={useAllSubAccounts}
            useAttach={useAtttachSubAcccount}
            useDetach={useDetachSubAccountFromUser}
            getOptionLabel={(option) =>
              `${option.shipToNumber} - ${option.city} - ${option.state}`
            }
            tableProps={{
              fields: subAccountsTableFields,
              name: `manage_users_${user.id}_subaccounts`,
              source: (...params) => {
                return fetchAllSubAccountsByUserId(user, ...params)
              },
            }}
          />
        </>
      )}

      {user.securityRole === RoleTypes.BROKER && (
        <OneToManyMapper
          user={user}
          title={'Mapped Salesmen'}
          useAll={useAllSalesmen}
          useAttach={useAttachSalesmanToUser}
          useDetach={useDetachSalesmanFromUser}
          getOptionLabel={(option) => option.name}
          tableProps={{
            fields: mappedSalesmenFields,
            name: `manage_users_${user.id}_mapped_salesmen`,
            source: (...params) => {
              return fetchAllSalesmenByUserId(user, ...params)
            },
          }}
        />
      )}
    </>
  )
}

export default UserEdit
