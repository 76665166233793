import { useCallback } from 'react'
import ReactGA from 'react-ga4'
import { useParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'

import { useAuth } from '~/services/auth'
import { fetchInvoiceDetailsData } from '~/services/invoices'
import { RoleTypes } from '~/services/users'
import { TEXTS } from '~/utils/allConstants/Constants'
import { exportFile } from '~/utils/helpers/helperFunctions'

import DetailsPage from '../UI/DetailsPage/DetailsPage'
import { GlobalLoading } from '../UI/Loading'

import { fields, invoiceSpecificContent } from './InvoiceDetailContent'

const { EXPORT_AS_PDF } = TEXTS

const options = {
  name: 'Invoice',
  queryName: `invoice-details`,
  tableProps: {
    fields: fields,
  },
}

const InvoiceDetail = () => {
  const { id } = useParams<{ id?: string }>()
  const { data: user } = useAuth()

  const { isLoading, data } = useQuery(
    [`invoice-details`, id],
    () => {
      return fetchInvoiceDetailsData(id)
    },
    { keepPreviousData: true }
  )

  const handlePdfExport = useCallback(() => {
    ReactGA.event({
      category: 'Orders',
      action: EXPORT_AS_PDF,
    })
    return exportFile({
      name: 'invoices',
      extension: 'pdf',
      id,
      exportfilename: 'InvoiceDetails',
    })
  }, [id])

  if (isLoading) {
    return <GlobalLoading />
  }

  if ([RoleTypes.CARRIER].includes(user.role)) {
    options.tableProps.fields.splice(4, 2)
    invoiceSpecificContent.forEach((item) =>
      item.items.forEach((subItem, index) => {
        if (subItem.id === 'paymentDetails') {
          return item.items.splice(index, 1)
        }
      })
    )
  }

  return (
    <DetailsPage
      data={data}
      options={{ ...options, id }}
      assetSpecificContent={invoiceSpecificContent}
      handlePdfExport={handlePdfExport}
      roleCheck={![RoleTypes.CARRIER].includes(user.role)}
    />
  )
}

export default InvoiceDetail
