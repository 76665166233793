import { useContext } from 'react'
import ReactGA from 'react-ga4'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Box, Grid, Typography, useTheme } from '@mui/material'

import { useSetPasswordByTokenMutation } from '~/services/users'
import { CreatePasswordForm } from '~/types/UserForm.model'
import { TEXTS } from '~/utils/allConstants/Constants'
import {
  confirmPasswordValidation,
  passwordValidation,
} from '~/utils/helpers/helperFunctions'

import ControllerStyledFormControl from '../Form/StyledFormControl'
import { NotificationContext } from '../Notification'

const { CREATE_PASSWORD, NO_PASSWORD_MESSAGE, SOMETHING_WENT_WRONG } = TEXTS

const schema = yup
  .object({
    password: yup
      .string()
      .default('')
      .required(NO_PASSWORD_MESSAGE)
      .test('validation', 'error', passwordValidation),

    confirm_password: confirmPasswordValidation,
  })
  .required()

const CreatePassword = () => {
  const alert = useContext(NotificationContext)
  const theme = useTheme()
  const navigate = useNavigate()
  const [search] = useSearchParams()
  const searchAsObject = Object.fromEntries(new URLSearchParams(search))

  const setPasswordMutation = useSetPasswordByTokenMutation(
    searchAsObject.token
  )
  // FIXME: need to be improvement
  const { handleSubmit, control } = useForm<CreatePasswordForm>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({}),
  })

  const onSubmit = (data: any) => {
    setPasswordMutation.mutate(data, {
      onSuccess: () => {
        ReactGA.event({
          category: 'User',
          action: 'Registration Success',
        })
        navigate('/login')
      },
      onError: (error: any) => {
        alert.error(error.response.data.message || SOMETHING_WENT_WRONG)
      },
    })
  }
  return (
    <Box
      sx={{
        maxWidth: '600px',
        mx: 'auto',
        [theme.breakpoints.down('md')]: {
          maxWidth: '100%',
          padding: '16px',
        },
      }}
    >
      <Grid
        container
        sx={{
          width: '100%',
          justifyContent: 'center',
          margin: '100px auto',
          p: 1,
        }}
      >
        <Typography variant="h1" align={'center'}>
          Thank you for confirming email, please create your password
        </Typography>

        <Typography
          variant={'h4'}
          align={'center'}
          sx={{ color: (theme) => theme.palette.text.secondary, py: 2 }}
        >
          Please create your password and finalize your registration Once you
          have created your password, your account will be set to an ACR
          representative to complete the process
        </Typography>

        <Typography
          sx={{
            color: (theme) => theme.palette.text.secondary,
            lineHeight: '24px',
            textAlign: 'center',
            fontWeight: 700,
            py: 2,
          }}
        >
          Please note this is the password you will use to login to the 1ACR
          Portal
        </Typography>
        <Grid container spacing={3} mb={2}>
          <Grid item xs={12} md={6}>
            <ControllerStyledFormControl
              name="password"
              control={control}
              id="password-textield"
              label="Password"
              placeholder="Password"
              type="password"
              tooltip={
                <ul style={{ margin: 0, paddingLeft: '15px', lineHeight: 1.5 }}>
                  Password Requirements:
                  <li>Min length 6 characters</li>
                  <li>1 special character</li>
                  <li>1 numeric digit</li>
                  <li>1 lowercase character</li>
                  <li>1 uppercase character</li>
                </ul>
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ControllerStyledFormControl
              name="confirm_password"
              control={control}
              id="confirm-password-textield"
              label="Confirm Password"
              placeholder="Confirm Password"
              type="password"
            />
          </Grid>
        </Grid>

        <LoadingButton
          variant="contained"
          fullWidth={true}
          size={'large'}
          onClick={handleSubmit(onSubmit)}
        >
          {CREATE_PASSWORD}
        </LoadingButton>
      </Grid>
    </Box>
  )
}

export default CreatePassword
