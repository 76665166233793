import { useContext } from 'react'

import { Alert, styled } from '@mui/material'

import { NotificationStatus } from './context/NotificationContext'
import { NotificationContext } from './index'

const StyledAlert = styled(Alert)`
  position: fixed;
  left: 16px;
  top: 170px;
  width: calc(100% - 32px);
  z-index: 10;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.15);
  &.MuiAlert-outlinedInfo {
    background: #cde6fd;
    border: 1px solid #0380f3;
    color: #0380f3;
  }
  &.MuiAlert-outlinedError {
    background: #fad2d7;
    border: 1px solid #f30303;
    color: #e71d36;
  }
`
const Notification = () => {
  const alert = useContext(NotificationContext)

  if (alert.alert !== NotificationStatus.None) {
    return (
      <StyledAlert
        severity={alert.alert}
        variant="outlined"
        onClose={alert.clear}
      >
        {alert.alertText}
      </StyledAlert>
    )
  } else {
    return null
  }
}

export default Notification
