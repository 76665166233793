import { AxiosResponse } from 'axios'
import { NavigateFunction } from 'react-router-dom'

import { UseMutationResult } from '@tanstack/react-query'

import { APIErrorRes } from '~/types/APIErrorRes.model'
import {
  MutateData,
  ReviewReturnCSR,
  ReviewReturnCSS,
} from '~/types/Approval.model'

export const mutateFunction = (
  mutation: UseMutationResult<
    AxiosResponse<any, ReviewReturnCSR | ReviewReturnCSS>,
    APIErrorRes,
    any
  >,
  data: MutateData,
  submitVal: boolean,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  navigate: NavigateFunction,
  url: string
) => {
  mutation.mutate(
    { variable: { data, submit: submitVal } },
    {
      onSuccess: () => {
        setLoading(false)
        navigate(url)
      },
      onError: () => {
        setLoading(false)
      },
    }
  )
}
