import { FC, ReactElement } from 'react'
import * as React from 'react'
import { Navigate } from 'react-router-dom'

import Loading from '../components/UI/Loading'
import { useAuth } from '../services/auth'
import { RoleTypes } from '../services/users'
import {
  GrantedClaims,
  JobTitles,
  RoutesCheck,
} from '../utils/allConstants/Constants'

interface Props {
  children: ReactElement
  type: RoutesCheck[]
}

const CheckRoutes: FC<Props> = ({ children, type }): ReactElement => {
  const authQuery = useAuth()
  const loginPage = '/login'
  const dashboardPage = '/dashboard'

  if (authQuery.isLoading) {
    return <Loading />
  }

  if (type.includes(RoutesCheck.PRIVATE)) {
    if (authQuery.isError) {
      if (authQuery.error === 'not authorized') {
        return <Navigate to={loginPage} />
      }
      throw authQuery.error
    }
  }

  if (type.includes(RoutesCheck.PUBLIC)) {
    if (authQuery.data) {
      return <Navigate to={dashboardPage} />
    }
  } else if (authQuery.isError) {
    return <Navigate to={loginPage} />
  }

  if (type.includes(RoutesCheck.SUPER_ADMIN)) {
    if (!authQuery.data.role.includes(RoleTypes.ADMIN)) {
      return <Navigate to={dashboardPage} />
    }
  }

  if (type.includes(RoutesCheck.NOT_CUSTOMER)) {
    if (authQuery.data.role === RoleTypes.CUSTOMER) {
      return <Navigate to={dashboardPage} />
    }
  }

  if (type.includes(RoutesCheck.NOT_BROKER)) {
    if (authQuery.data.role === RoleTypes.BROKER) {
      return <Navigate to={dashboardPage} />
    }
  }

  if (type.includes(RoutesCheck.NOT_CARRIER)) {
    if (authQuery.data.role === RoleTypes.CARRIER) {
      return <Navigate to={dashboardPage} />
    }
  }

  if (
    type.includes(RoutesCheck.CUSTOMER_RA_REQUEST) ||
    type.includes(RoutesCheck.CARRIER_RETURNS)
  ) {
    if (
      !authQuery.data.claims.find(
        (claim) =>
          claim === GrantedClaims.CUSTOMER_RA_REQUEST ||
          claim === GrantedClaims.CARRIER_RETURNS
      )
    ) {
      return <Navigate to={dashboardPage} />
    }
  }

  if (type.includes(RoutesCheck.NOT_SERVICE_EMPLOYEE)) {
    if (authQuery.data.role === RoleTypes.EMPLOYEE) {
      if (
        ![
          JobTitles.CUSTOMER_SERVICE_REPRESENTATIVE,
          JobTitles.CUSTOMER_SERVICE_SUPERVISOR,
          JobTitles.CUSTOMER_SERVICE_DIRECTOR,
          JobTitles.VP_OF_NATIONAL_ACCOUNT_SALES,
          JobTitles.VP_OF_DISTRIBUTION_SALES,
        ].includes(authQuery.data.jobTitle)
      ) {
        return <Navigate to={dashboardPage} />
      }
    }
  }

  if (type.includes(RoutesCheck.NEW_USER_CREATION)) {
    if (!authQuery.data.claims.includes(GrantedClaims.NEW_USER_CREATION)) {
      return <Navigate to={dashboardPage} />
    }
  }

  if (type.includes(RoutesCheck.REPORT)) {
    if (!authQuery.data.claims.includes(GrantedClaims.REPORTS)) {
      return <Navigate to={dashboardPage} />
    }
  }

  if (type.includes(RoutesCheck.BANNER_NOTIFICATION_EDITOR)) {
    if (
      !authQuery.data.claims.includes(GrantedClaims.BANNER_NOTIFICATION_EDITOR)
    ) {
      return <Navigate to={dashboardPage} />
    }
  }

  return React.cloneElement(children, {
    auth: authQuery,
  })
}

export default CheckRoutes
