import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Button, Grid, useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'

import ControlledStyledTextField from '~/components/Form/StyledTextField'
import { useAuth } from '~/services/auth'
import { useDisputeMutation } from '~/services/returns'
import { theme } from '~/theme/AppTheme'
import { DATE_STRING_OPTIONS } from '~/utils/allConstants/Constants'
import { formatDate } from '~/utils/helpers/helperFunctions'

import ModalContainer from '../UI/ModalContainer'
import ModalHeader from '../UI/ModalHeader'
import { ControllerStyledTextField } from '../UI/ModalInputs'

interface Props {
  id: string
  closeModal?: () => void
}

const schema = yup
  .object({
    email: yup
      .string()
      .email('Email Address is not valid')
      .required('Email is Required'),
    phone: yup.string(),
    message: yup.string().required('Comment is Required'),
  })
  .required()

const ReturnDenialDispute: React.FC<Props> = ({
  id,
  closeModal,
}): React.ReactElement => {
  const { data: user } = useAuth()
  const navigate = useNavigate()
  const isMatchTab = useMediaQuery(theme.breakpoints.down('md'))

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      phone: '',
      message: '',
    },
  })

  const disputeMutation = useDisputeMutation(id)
  const handleSendComment = (data: any) => {
    const body = {
      ...data,
      name: `${user.firstName} ${user.lastName}`,
    }
    disputeMutation.mutate(body, {
      onSuccess: () => {
        reset({ message: '', phone: '', email: '' })
        closeModal()
        navigate('/returns/history')
      },
    })
  }

  const returnNumber = [
    {
      label: 'Return Number',
      value: id,
    },
  ]

  return (
    <Box
      sx={{
        position: 'relative',
        top: '1%',
        textAlign: 'left',
        p: 2,
      }}
    >
      <ModalHeader data={returnNumber} heading={'Return Denial Dispute'} />
      <Box
        sx={{
          overflowY: 'auto',
          my: 2,
          height: isMatchTab ? '100%' : 410,
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={10}>
            <ControlledStyledTextField
              name={'email'}
              control={control}
              label={'Email Address'}
              fullWidth
              placeholder="Email Address"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={10}>
            <ControlledStyledTextField
              name={'phone'}
              control={control}
              label={'Mobile Number'}
              fullWidth
              placeholder="Mobile Number"
            />
          </Grid>
        </Grid>
        <ModalContainer
          author={`${user.firstName} ${user.lastName}`}
          createdAt={formatDate(Date.now(), DATE_STRING_OPTIONS)}
          InputComponent={
            <ControllerStyledTextField
              name={'message'}
              control={control}
              placeholder="Your message..."
            />
          }
        />
      </Box>

      <Grid container>
        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit(handleSendComment)}
            sx={{
              borderRadius: 0,
              margin: '5px',
              textTransform: 'initial',
            }}
            loading={disputeMutation.isLoading}
          >
            Send Dispute
          </LoadingButton>
          <Button
            variant={'outlined'}
            sx={{
              borderRadius: 0,
              paddingLeft: 5,
              paddingRight: 5,
              margin: '5px',
              fontSize: '14px',
              width: '140px',
            }}
            onClick={closeModal}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ReturnDenialDispute
