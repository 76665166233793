import { SyntheticEvent, useContext, useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'

import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { AccountsContext } from '~/components/Accounts'
import { fetchAccounts } from '~/services/accounts'
import { useAuth } from '~/services/auth'
import { RoleTypes } from '~/services/users'
import { Account } from '~/types/Account.model'

import './AccountSelector.scss'

const AccountSelector = () => {
  const { data: user } = useAuth()
  const { selectedAccount, setSelectedAccount, setSelectedSubAccount } =
    useContext(AccountsContext)

  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [debounceInputValue] = useDebounce(inputValue, 400)

  const searchParams = {
    page: 1,
    pageSize: 100,
    type: 'ACCOUNT',
    status: 'ACTIVE',
    q: debounceInputValue,
  }
  const { data, isFetching, isFetchedAfterMount } = useQuery(
    ['accounts', searchParams],
    fetchAccounts,
    {
      enabled:
        (!selectedAccount ||
          debounceInputValue !==
            `${selectedAccount.name} - ${selectedAccount.customerNumber}`) &&
        ![RoleTypes.CARRIER].includes(user.role),
      placeholderData: {
        items: [],
      },
      keepPreviousData: true,
    }
  )

  useEffect(() => {
    if (data.items.length === 1 && inputValue === '') {
      setSelectedAccount(data.items[0])
    }
  }, [isFetchedAfterMount])

  const handleChangeAccount = (
    ev: SyntheticEvent<Element, Event>,
    account: Account,
    reason: string
  ) => {
    if (reason === 'selectOption') {
      setSelectedAccount(account.id ? account : null)
    }
    if (reason === 'clear') {
      setSelectedAccount(null)
      setSelectedSubAccount(null)
    }
  }

  const matchNumber: any = (val: string) => /^[0-9]/.test(val)

  let values = [...data?.items].sort(
    (a: any, b: any) =>
      matchNumber(a.name) - matchNumber(b.name) || a.name?.localeCompare(b.name)
  )

  if (selectedAccount) {
    const has = values.find((itm) => itm.id === selectedAccount.id)

    if (!!has) {
      values = []
      values = [has]
    }
    if (!has) {
      values.push(selectedAccount)
    }
  }
  if (data.items.length > 1 && inputValue === '') {
    values.unshift({ customerNumber: null })
  }

  return (
    <Autocomplete
      fullWidth={true}
      open={open}
      value={selectedAccount ?? null}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      isOptionEqualToValue={(option, value) =>
        option.customerNumber === value.customerNumber
      }
      getOptionLabel={(option) => {
        return option.id ? `${option.name} - ${option.customerNumber}` : 'ALL'
      }}
      onChange={handleChangeAccount}
      options={values}
      loading={isFetching}
      disabled={[RoleTypes.CARRIER].includes(user.role)}
      className="account-dropdown"
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={'My Account'}
          color="secondary"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default AccountSelector
