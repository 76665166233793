import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { Outlet, useLocation } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import { Container, Grid } from '@mui/material'

import { AccountsProvider } from '../components/Accounts'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import SubHeader from '../components/Header/SubHeader'
import {
  Notification,
  NotificationProvider,
} from '../components/Notification/index'
import { useAuth } from '../services/auth'
import { theme } from '../theme/AppTheme'

import './App.scss'

const App = () => {
  const isAuth = useAuth()
  const location = useLocation()

  const [lastLocation, setLastLocation] = useState('')

  useEffect(() => {
    if (location.pathname !== lastLocation) {
      window.scrollTo(0, 0)
      setLastLocation(location.pathname)
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname,
      })
    }
  }, [location, lastLocation])

  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <NotificationProvider>
        <AccountsProvider>
          <Container
            disableGutters={true}
            sx={{
              height: '100%',
              width: '100%',
              boxSizing: 'border-box',
              margin: '0',
            }}
            maxWidth={false}
          >
            <Header />
            {isAuth.data && <SubHeader />}
            <Grid
              container
              direction={'column'}
              sx={{
                paddingTop: (theme) => theme.spacing(isAuth.data ? 20 : 16),
                minHeight: '100%',
                left: '0',
                width: '100%',
                [theme.breakpoints.down('md')]: {
                  paddingTop: (theme) => theme.spacing(isAuth.data ? 16 : 13),
                },
                [theme.breakpoints.down('sm')]: {
                  paddingTop: (theme) => theme.spacing(isAuth.data ? 22 : 13),
                },
              }}
            >
              <Grid item xs={true} sx={{ width: '100%' }}>
                <Notification />
                <Outlet />
              </Grid>
              {isAuth.data && (
                <Grid item style={{ maxWidth: '100%' }}>
                  <Footer />
                </Grid>
              )}
            </Grid>
          </Container>
        </AccountsProvider>
      </NotificationProvider>
    </QueryParamProvider>
  )
}

export default App
