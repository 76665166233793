import { FC, ReactElement } from 'react'

import Tooltip from '@mui/material/Tooltip'
import useMediaQuery from '@mui/material/useMediaQuery'

import { theme } from '~/theme/AppTheme'

type Props = {
  children: ReactElement
  title: string | ReactElement
}

const CustomTooltip: FC<Props> = ({ children, title, ...rest }) => {
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Tooltip
      arrow
      placement="top"
      title={title}
      enterDelay={isTabletOrMobile ? 0 : 200}
      leaveDelay={100}
      {...rest}
    >
      {children}
    </Tooltip>
  )
}

export default CustomTooltip
