import { Fields } from '~/types/Fields.model'
import { TEXTS } from '~/utils/allConstants/Constants'

const {
  CUSTOMER_NAME,
  CUSTOMER_NUMBER,
  SHIP_TO_NUMBER,
  CITY,
  STATE,
  SALESMAN_NAME,
  SALESMAN_NUMBER,
  STATUS,
} = TEXTS

export const accountsTableFields: Fields[] = [
  {
    id: 'name',
    label: CUSTOMER_NAME,
    minWidth: 250,
    sort: true,
  },
  {
    id: 'customerNumber',
    label: CUSTOMER_NUMBER,
    minWidth: 250,
    sort: true,
  },
]

export const subAccountsTableFields: Fields[] = [
  {
    id: 'name',
    label: CUSTOMER_NAME,
    minWidth: 250,
    sort: true,
  },
  {
    id: 'shipToNumber',
    label: SHIP_TO_NUMBER,
    minWidth: 100,
    sort: true,
  },
  {
    id: 'city',
    label: CITY,
    minWidth: 100,
    sort: true,
  },
  {
    id: 'state',
    label: STATE,
    minWidth: 100,
    sort: true,
  },
]

export const mappedSalesmenFields: Fields[] = [
  {
    id: 'name',
    label: SALESMAN_NAME,
    minWidth: 250,
    sort: true,
  },
  {
    id: 'number',
    label: SALESMAN_NUMBER,
    minWidth: 100,
    sort: true,
  },

  {
    id: 'status',
    label: STATUS,
    minWidth: 100,
    sort: true,
  },
]
