import { FC, ReactElement } from 'react'
import { Control, UseFormHandleSubmit } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { ArrowBack } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Typography } from '@mui/material'

import CreatedReturnInfo from '~/components/common/createdReturnInfo/CreatedReturnInfo'
import ControlledStyledTextField from '~/components/Form/StyledTextField'
import DataGrid from '~/components/UI/DataGrid/DataGrid'
import {
  Options,
  ReturnRequestApproval,
  SubmitApproval,
} from '~/types/Approval.model'
import { TEXTS } from '~/utils/allConstants/Constants'

import './ApprovalReturnRequestContainer.scss'
import variables from '~/theme/scss/_variables.module.scss'

const {
  DOWNLOAD_ATTACHMENTS,
  APPROVER_COMMENT,
  COMPLETE_APPROVAL,
  SAVE,
  NO_DATA_FOUND,
  BACK,
} = TEXTS
const { acrColorCyanWhite } = variables

type Props = {
  data: ReturnRequestApproval
  handleAttachment: () => void
  isLoading: boolean
  options: Options
  tableProps: any
  control: Control<any>
  handleSubmit: UseFormHandleSubmit<SubmitApproval>
  handleSave: (data: SubmitApproval) => void
  isSaveLoading: boolean
  isSubmitLoading: boolean
  handleSubmitApproval: (data: SubmitApproval) => void
}

const ApprovalReturnRequestContainer: FC<Props> = ({
  data,
  handleAttachment,
  isLoading,
  options,
  tableProps,
  control,
  handleSubmit,
  handleSave,
  isSaveLoading,
  isSubmitLoading,
  handleSubmitApproval,
}): ReactElement => {
  const navigate = useNavigate()

  return (
    <Box sx={{ px: { xs: 2, md: 3 } }}>
      <Box>
        <Grid container mt={3} sx={{ alignItems: 'center' }}>
          <Grid item xs={12} className="action-buttons-wrapper">
            <Button
              startIcon={<ArrowBack />}
              sx={{
                fontSize: 20,
                color: (theme) => theme.palette.text.primary,
                px: 0,
                marginRight: 'auto',
              }}
              onClick={() => navigate(-1)}
            >
              {BACK}
            </Button>
            {Object.keys(data.assets).length > 0 && (
              <LoadingButton
                loading={isLoading}
                className={'action-button'}
                variant={'contained'}
                onClick={handleAttachment}
              >
                {DOWNLOAD_ATTACHMENTS}
              </LoadingButton>
            )}
          </Grid>
        </Grid>
        {!!data ? (
          <Box mt={3}>
            <CreatedReturnInfo pageHeading={options.name} returnData={data} />
            <Box sx={{ marginBottom: '20px' }}>
              {Object.keys(tableProps).length > 0 && <DataGrid />}
            </Box>
            {(data.customStatus === 'REOPENED' ||
              data.currentGroup === 'LC') && (
              <Grid
                container
                sx={{ backgroundColor: acrColorCyanWhite }}
                mb={2}
              >
                <Grid item xs={12} sm={6} md={6} px={2} mt={2}>
                  <ControlledStyledTextField
                    name={'approverComment'}
                    label={APPROVER_COMMENT}
                    control={control}
                    className="approver-comment"
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        ) : (
          <Box sx={{ height: 300 }}>
            <Typography
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
              }}
            >
              {NO_DATA_FOUND}
            </Typography>
          </Box>
        )}
      </Box>
      <Box className="bottom-buttons-wrapper">
        <LoadingButton
          variant={'contained'}
          onClick={handleSubmit(handleSave)}
          loading={isSaveLoading}
          className="action-button"
        >
          {SAVE}
        </LoadingButton>
        <LoadingButton
          variant={'contained'}
          loading={isSubmitLoading}
          onClick={handleSubmit(handleSubmitApproval)}
          className="action-button"
        >
          {COMPLETE_APPROVAL}
        </LoadingButton>
      </Box>
    </Box>
  )
}

export default ApprovalReturnRequestContainer
