import { Divider, Typography } from '@mui/material'

import { TEXTS } from '~/utils/allConstants/Constants'

const { SYSTEM_INFORMATION } = TEXTS

const SystemInformation = () => {
  return (
    <>
      <Typography variant="h3" pb={3}>
        {SYSTEM_INFORMATION}
      </Typography>

      <Divider />
    </>
  )
}

export default SystemInformation
