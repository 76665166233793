import { useContext } from 'react'

import { useMutation } from '@tanstack/react-query'

import { NotificationContext } from '../components/Notification'

import axiosInstance from './axiosInstance'

export const useOrderPod = () => {
  const alert = useContext(NotificationContext)
  const useOrderNumberMutation = useMutation(
    (orderNumber: string) => {
      return axiosInstance.get(`/v1/orders/pod/${orderNumber}`, {
        responseType: 'blob',
      })
    },
    {
      onSuccess: () => {
        alert.success('Order POD Downloaded Successfully!')
      },
    }
  )

  return useOrderNumberMutation
}

export const useOrderPodReq = (orderNumber: string) => {
  const alert = useContext(NotificationContext)
  const useOrderPodReqMutation = useMutation(
    (data: { userComment: string }) => {
      return axiosInstance.post(`/v1/orders/pod/${orderNumber}/request`, data)
    },
    {
      onSuccess: () => {
        alert.success('Order POD Requested Successfully!')
      },
    }
  )

  return useOrderPodReqMutation
}
