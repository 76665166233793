import { useContext } from 'react'

import { useMutation } from '@tanstack/react-query'

import { NotificationContext } from '../components/Notification'

export const useSendComment = (createFunction: any) => {
  const alert = useContext(NotificationContext)

  const sendCommentMutation = useMutation(
    (data) => {
      return createFunction(data)
    },
    {
      onSuccess: () => {
        alert.success('Comment Sent Successfully')
      },
      onError: () => {
        alert.error('Comment Failed')
      },
    }
  )

  return sendCommentMutation
}
