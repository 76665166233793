import { createContext, FC, ReactElement, useEffect, useState } from 'react'
import { useLocation } from 'react-router'

export enum NotificationStatus {
  None = 'NONE',
  Info = 'info',
  Error = 'error',
  Success = 'success',
}

const NotificationContext = createContext(null)
NotificationContext.displayName = 'NotificationContext'

const NotificationProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const location = useLocation()
  const [alert, setAlert] = useState(NotificationStatus.None)
  const [alertText, setAlertText] = useState<string | null>(null)

  useEffect(() => {
    setAlert(NotificationStatus.None)
  }, [location])

  return (
    <NotificationContext.Provider
      value={{
        alert: alert,
        alertText: alertText,
        success: (text: string) => {
          setAlertText(text)
          setAlert(NotificationStatus.Info)
          setTimeout(() => {
            setAlert(NotificationStatus.None)
          }, 5000)
        },
        error: (text: string) => {
          setAlertText(text)
          setAlert(NotificationStatus.Error)
        },
        clear: () => setAlert(NotificationStatus.None),
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export { NotificationProvider }
export default NotificationContext
