import { SyntheticEvent, useContext, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { AccountsContext } from '~/components/Accounts'
import { fetchAccounts } from '~/services/accounts'
import { SubAccount } from '~/types/Account.model'
import { RECORD_TYPE } from '~/utils/allConstants/Constants'

import './AccountSelector.scss'

const SubAccountSelector = () => {
  const location = useLocation()
  const { selectedAccount, selectedSubAccount, setSelectedSubAccount } =
    useContext(AccountsContext)

  const [open, setOpen] = useState(false)

  const isPriceListPage = location.pathname.includes('/price-list')

  if (
    isPriceListPage &&
    selectedSubAccount?.recordType === RECORD_TYPE.BILL_TO
  ) {
    setSelectedSubAccount(null)
  }

  const searchParams = {
    page: 1,
    pageSize: 1000,
    type: 'SUBACCOUNT',
    status: 'ACTIVE',
    parentId: selectedAccount?.id,
  }
  const { data, isFetching } = useQuery(
    ['subAccounts', searchParams],
    fetchAccounts,
    {
      enabled: open && !!selectedAccount,
      placeholderData: {
        items: [],
      },
      keepPreviousData:
        selectedSubAccount?.customerNumber === selectedAccount?.customerNumber,
    }
  )

  const handleChangeAccount = (
    ev: SyntheticEvent<Element, Event>,
    account: SubAccount,
    reason: string
  ) => {
    if (reason === 'selectOption') {
      setSelectedSubAccount(account.id ? account : null)
    }
    if (reason === 'clear') {
      setSelectedSubAccount(null)
    }
  }

  let values = [...data?.items].sort((a, b) => a.shipToNumber - b.shipToNumber)

  if (selectedSubAccount) {
    const has = values.find((itm) => itm.id === selectedSubAccount.id)

    if (!has) {
      values.push(selectedSubAccount)
    }
  }

  if (isPriceListPage) {
    values = values.filter(
      ({ recordType }) => recordType !== RECORD_TYPE.BILL_TO
    )
  } else {
    values.unshift({ shipToNumber: null })
  }

  return (
    <Autocomplete
      fullWidth={true}
      open={open}
      value={
        selectedSubAccount && selectedAccount
          ? selectedSubAccount
          : selectedAccount
          ? { shipToNumber: null }
          : null
      }
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      isOptionEqualToValue={(option, value) =>
        option.shipToNumber === value.shipToNumber
      }
      getOptionLabel={(option) => {
        return option.id
          ? `${option.shipToNumber} - ${option.city} - ${option.state}`
          : 'ALL'
      }}
      disabled={!selectedAccount}
      onChange={handleChangeAccount}
      options={values}
      loading={isFetching}
      className="account-dropdown"
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={'Sub-Accounts'}
          color="secondary"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default SubAccountSelector
