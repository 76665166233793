import { FC, memo, ReactElement, useCallback, useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { useQuery } from '@tanstack/react-query'

import useDataGridStore, {
  DataGridProvider,
} from '~/components/UI/DataGrid/DataGridContext'
import Loading from '~/components/UI/Loading'
import {
  fetchDenialReasons,
  fetchInternalReasons,
  fetchWarehouse,
  useApprovalReturnCSS,
  useCarrierReturnLc,
} from '~/services/approval'
import { fetchReturnReasons } from '~/services/returns'
import { RoleTypes } from '~/services/users'
import {
  Options,
  ReturnRequestApproval,
  SubmitApproval,
} from '~/types/Approval.model'
import { UpdateReturnData } from '~/types/Return.model'
import { ApprovalStatusItemsCarrier } from '~/utils/allConstants/Constants'
import {
  exportFile,
  sendApprovalStatusInPayload,
} from '~/utils/helpers/helperFunctions'

import { mutateFunction } from '../../components/ApprovalMutation'
import ApprovalReturnRequestContainer from '../components/ApprovalReturnRequestContainer'
import { schemaCarrierReturn, schemaWarehouseMispickReturn } from '../schema'

import ApprovalReturnRequestDetailsInput, {
  ApprovalReturnRequestDetailsInputHeader,
} from './components/ApprovalReturnRequestDetailsInput'

type Props = {
  data: ReturnRequestApproval
  options: Options
}

const ApprovalReturnRequestInfoDisplayPageCarrier: FC<Props> = ({
  data,
  options,
}): ReactElement => {
  const navigate = useNavigate()
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { setTableProps, tableProps } = useDataGridStore()
  const returnApprovalCssMutation = useApprovalReturnCSS(data.id)
  const carrierReturnLcMutation = useCarrierReturnLc(data.id)
  const isCarrierReturn = data.isCarrierReturn

  const source = useCallback(() => {
    return {
      items: data.returnItemList,
    }
  }, [data])

  const { data: denialReasonsCodes } = useQuery(
    [`denial-reasons`],
    fetchDenialReasons,
    { keepPreviousData: true }
  )

  const { data: warehouse } = useQuery(
    [`warehouse-locations`],
    fetchWarehouse,
    {
      keepPreviousData: true,
    }
  )

  const { data: returnReasonCodes, isFetching: isReturnReasonCodesFetching } =
    useQuery(
      ['return-reason-code-carrier'],
      () => fetchReturnReasons(isCarrierReturn && RoleTypes.CARRIER),
      {
        keepPreviousData: true,
      }
    )

  const { data: internalReasonCodes, isFetching: isInternalReasonFetching } =
    useQuery([`return-reasons`], fetchInternalReasons, {
      keepPreviousData: true,
    })

  const { control, handleSubmit, setValue, resetField } = useForm({
    defaultValues: {
      items: [
        {
          itemCustomStatus: '',
          denialReason: '',
          productId: '',
          reason: '',
          isApproved: null,
          internalReason: '',
          warehouseLocationName: '',
          alreadyValidated: false,
        },
      ],
      approverComment: data.approverComment || '',
    },
    resolver: yupResolver(
      isCarrierReturn ? schemaCarrierReturn : schemaWarehouseMispickReturn
    ),
  })

  const handleAttachment = useCallback(() => {
    setIsLoading(true)
    ReactGA.event({
      category: 'Return Request Approval',
      action: 'Export as Zip',
    })
    return exportFile({
      name: 'returns',
      id: data.id,
      extension: 'zip',
      exportfilename: 'Attachments',
    }).then(() => setIsLoading(false))
  }, [data.id])

  const ApprovalData = (data: SubmitApproval) => {
    return {
      items: data.items
        .filter(({ alreadyValidated }) => !alreadyValidated)
        .map(
          ({
            itemCustomStatus,
            approvalStatus,
            denialReason,
            warehouseLocationName,
            internalReason,
            ...item
          }) => {
            const warehouseLocationIndex = Object.values(warehouse)
              .map((location: any) => location.locationNumber)
              .indexOf(warehouseLocationName?.split(' - ')[0])
            return [
              {
                ...item,
                isApproved: sendApprovalStatusInPayload(
                  isCarrierReturn ? itemCustomStatus : approvalStatus
                ),
                internalReason: Object.keys(internalReasonCodes).find(
                  (k) =>
                    internalReasonCodes[
                      k as keyof typeof internalReasonCodes
                    ] === internalReason
                ),
                denialReason: !denialReason
                  ? null
                  : Object.keys(denialReasonsCodes).find(
                      (k) =>
                        denialReasonsCodes[
                          k as keyof typeof denialReasonsCodes
                        ] === denialReason
                    ),
                itemCustomStatus:
                  itemCustomStatus === ApprovalStatusItemsCarrier.DENIED
                    ? null
                    : Object.keys(ApprovalStatusItemsCarrier).find(
                        (k) =>
                          ApprovalStatusItemsCarrier[
                            k as keyof typeof ApprovalStatusItemsCarrier
                          ] === itemCustomStatus
                      ),
                warehouseDetails: warehouse[warehouseLocationIndex],
              },
            ][0]
          }
        ),
      approverComment: data.approverComment,
    }
  }

  const handleSubmitApproval = (data: SubmitApproval) => {
    setIsSubmitLoading(true)

    mutateFunction(
      isCarrierReturn ? carrierReturnLcMutation : returnApprovalCssMutation,
      ApprovalData(data),
      true,
      setIsSubmitLoading,
      navigate,
      isCarrierReturn
        ? '/approvals/all-approvals/lc'
        : '/approvals/all-approvals/csr'
    )
  }

  const handleSave = (saveData: SubmitApproval) => {
    setIsSaveLoading(true)

    mutateFunction(
      isCarrierReturn ? carrierReturnLcMutation : returnApprovalCssMutation,
      ApprovalData(saveData),
      false,
      setIsSaveLoading,
      navigate,
      '/approvals/my-approvals'
    )
  }

  const actions = [
    {
      header: () => {
        return <ApprovalReturnRequestDetailsInputHeader />
      },
      render: (row: UpdateReturnData, index: number) => {
        return (
          <ApprovalReturnRequestDetailsInput
            key={`${row.id}-${row.code}`}
            row={row}
            isCarrierReturn={isCarrierReturn}
            control={control}
            index={index}
            setValue={setValue}
            denialReasonsCodes={denialReasonsCodes}
            internalReasonCodes={internalReasonCodes}
            warehouse={warehouse}
            resetField={resetField}
          />
        )
      },
    },
  ]

  useEffect(() => {
    setTableProps({
      name: `${options.queryName}-${options.id}`,
      source,
      actions,
      fields: options.fields,
    })
  }, [
    source,
    data,
    options,
    setTableProps,
    denialReasonsCodes,
    warehouse,
    internalReasonCodes,
  ])

  if (isReturnReasonCodesFetching || isInternalReasonFetching) {
    return <Loading />
  }

  const returnReasonCodeFieldIndex = options.fields.findIndex(
    (item) => item.id === 'reason'
  )

  options.fields[returnReasonCodeFieldIndex].format = (
    _,
    row: string | any
  ) => {
    return returnReasonCodes[row.reason as keyof typeof returnReasonCodes]
  }

  return (
    <>
      <ApprovalReturnRequestContainer
        data={data}
        options={options}
        tableProps={tableProps}
        handleAttachment={handleAttachment}
        isLoading={isLoading}
        control={control}
        handleSubmit={handleSubmit}
        handleSave={handleSave}
        isSaveLoading={isSaveLoading}
        isSubmitLoading={isSubmitLoading}
        handleSubmitApproval={handleSubmitApproval}
      />
    </>
  )
}
// eslint-disable-next-line react/display-name
export default memo((props: Props) => (
  <DataGridProvider>
    <ApprovalReturnRequestInfoDisplayPageCarrier {...props} />
  </DataGridProvider>
))
