import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Alert,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from '@mui/material'

import ControllerStyledFormControl from '../components/Form/StyledFormControl'
import AccountNotApproved from '../components/Login/AccountNotApproved'
import { useLogingMutation } from '../services/auth'
import { theme } from '../theme/AppTheme'
import { LoginUserForm } from '../types/UserForm.model'
import { RoutesCheck, TEXTS } from '../utils/allConstants/Constants'

import { CheckRoutes } from './index'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorWhite, acrColorBlue } = variables
const { FORGOT_PASSWORD, LOGIN } = TEXTS

const { EMAIL_FORMAT_MESSAGE, NO_EMAIL_MESSAGE, NO_PASSWORD_MESSAGE } = TEXTS

const schema = yup
  .object({
    email: yup.string().email(EMAIL_FORMAT_MESSAGE).required(NO_EMAIL_MESSAGE),
    password: yup.string().required(NO_PASSWORD_MESSAGE),
  })
  .required()

const Login = () => {
  const loginMutation = useLogingMutation()
  const navigate = useNavigate()

  // FIXME:
  const [responseError, setResponseError] = useState('')

  const { handleSubmit, control } = useForm<LoginUserForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
      remember_me: true,
    },
  })
  const isMatch = useMediaQuery(theme.breakpoints.down('md'))

  const onSubmit = (data: LoginUserForm) => {
    setResponseError('')
    loginMutation.mutate(data, {
      onError: ({ data }: any) => {
        if (data.code === 403) {
          if (data.details && data.details.reason) {
            if (
              ['USER_PENDING_APPROVAL', 'CUSTOMER_PENDING_APPROVAL'].includes(
                data.details.reason
              )
            ) {
              navigate('/account-not-approved')
              return true
            }

            const errorMessages: any = {
              USER_NOT_FOUND:
                'We are unable to locate this email in our database. Please reenter your email to try again.',
              EMAIL_NOT_VERIFIED:
                'Please confirm your email before logging in.',
              USER_NOT_APPROVED: 'Access denied this account is not approved.',
              USER_DISABLED: 'Access denied this account is not approved.',

              CUSTOMER_NOT_FOUND:
                'We are unable to locate this email in our database. Please reenter your email to try again.',
              CUSTOMER_NOT_APPROVED:
                'Access denied this account is not approved.',
              CUSTOMER_DISABLED: 'Access denied this account is not approved.',
            }

            const errorMessage =
              errorMessages[data.details.reason] ??
              `${data.message} (${data.details.reason})`
            setResponseError(errorMessage)

            return true
          }
        }
        setResponseError(data.message)
      },
    })
  }

  return (
    <Box px={{ xs: 2, md: 3 }}>
      <Box
        sx={{
          display: isMatch ? 'block' : 'flex',
          maxWidth: '1050px',
          alignItems: 'center',
          width: 'fit-content',
          mx: 'auto',
          border: isMatch ? '0px' : '1px solid #8D96E7',
          borderRadius: 1,
          marginBottom: '32px',
        }}
      >
        <Grid item xs={12} md={6}>
          <Grid
            container
            direction={'column'}
            item
            sx={[
              {
                background: acrColorWhite,
                width: '100%',
                my: { xs: 0, md: 3 },
                px: 2,
              },
              !isMatch && { p: 6 },
            ]}
          >
            <Typography variant="h1" align={'center'}>
              Log in to your account
            </Typography>
            <Typography
              variant={'h4'}
              align={'center'}
              sx={{ color: (theme) => theme.palette.text.secondary, py: 3 }}
            >
              Welcome to ACR&apos;s 1ACR Portal also known as the web portal for
              customer purchase order and sales information. You must be an
              existing ACR customer to access the portal.
              <br />
              <br /> If you are an existing customer and need to register for a
              user ID, please click on the New User button in the upper
              right-hand corner. <br /> <br /> Non-ACR existing customers,
              please click&nbsp;
              <Link
                href={'https://amercareroyal.com/pages/open-an-account'}
                target={'_blank'}
                sx={{
                  color: acrColorBlue,
                  textDecorationColor: acrColorBlue,
                }}
              >
                here
              </Link>
              &nbsp;for more information on how to open a customer account.
            </Typography>
            {responseError && <Alert severity="error">{responseError}</Alert>}
          </Grid>
        </Grid>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ my: isMatch ? 0 : 10, display: isMatch ? 'none' : 'visible' }}
        />
        <Grid item xs={12} md={6} sx={{ px: isMatch ? 0 : 6 }}>
          <Box
            component="form"
            sx={{ '& .MuiTextField-root': { my: 1 } }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <ControllerStyledFormControl
              name="email"
              control={control}
              id="email-textield"
              label="E-mail address"
              placeholder="E-mail address"
            />
            <ControllerStyledFormControl
              name="password"
              control={control}
              id="password-textield"
              label="Password"
              placeholder="Password"
              type="password"
            />
            <Grid container alignItems={'center'}>
              <Grid item xs={6}>
                <Controller
                  name="remember_me"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      name={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      control={<Checkbox defaultChecked />}
                      label="Stay signed in"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} container justifyContent={'flex-end'}>
                <Link
                  component={RouterLink}
                  to={`/forgot-password`}
                  sx={{
                    fontWeight: 'bold',
                    color: acrColorBlue,
                    textDecorationColor: acrColorBlue,
                  }}
                >
                  {FORGOT_PASSWORD}
                </Link>
              </Grid>
              <Grid item xs={12} my={3}>
                <LoadingButton
                  type={'submit'}
                  variant="contained"
                  fullWidth={true}
                  size={'large'}
                  loading={loginMutation.isLoading}
                >
                  {LOGIN}
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Box>
  )
}

export default Login

export const routes = [
  {
    path: 'login',
    element: (
      <CheckRoutes type={[RoutesCheck.PUBLIC]}>
        <Login />
      </CheckRoutes>
    ),
  },
  {
    path: 'account-not-approved',
    element: (
      <CheckRoutes type={[RoutesCheck.PUBLIC]}>
        <AccountNotApproved />
      </CheckRoutes>
    ),
  },
]
