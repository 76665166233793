import { v4 as uuid } from 'uuid'

import { Grid } from '@mui/material'

import './LabelAndValue.scss'

interface item {
  label: string
  value: string
}

type Props = {
  items: item[]
}

const LabelAndValue: React.FC<Props> = ({ items }) => {
  return (
    <Grid container spacing={2}>
      {items.map(({ label, value }) => (
        <Grid item xs={4} key={uuid()}>
          <div className="label-value-group">
            <div className="label-text">{label}</div>
            <div className="value-text">{value}</div>
          </div>
        </Grid>
      ))}
    </Grid>
  )
}

export default LabelAndValue
