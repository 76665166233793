import { FC, Fragment, ReactElement } from 'react'

import { DATE_STRING_OPTIONS } from '~/utils/allConstants/Constants'
import { formatDate } from '~/utils/helpers/helperFunctions'

import ModalContainer from '../../UI/ModalContainer'
import { StyledTextField } from '../../UI/ModalInputs'

type Props = {
  receivedComments: any
}
const ReceivedComments: FC<Props> = ({ receivedComments }): ReactElement => {
  return (
    <>
      {receivedComments.map((receivedComment: any, index: number) => (
        <Fragment key={index}>
          <ModalContainer
            author={receivedComment.authorName}
            createdAt={formatDate(
              receivedComment.createdAt,
              DATE_STRING_OPTIONS
            )}
            InputComponent={<StyledTextField value={receivedComment.content} />}
          />
        </Fragment>
      ))}
    </>
  )
}

export default ReceivedComments
