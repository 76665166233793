import {
  FC,
  memo,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Grid,
  styled,
  Tab,
  Tabs,
  useMediaQuery,
} from '@mui/material'

import FilterGridData from '~/components/common/filterGridData/FilterGridData'
import { theme } from '~/theme/AppTheme'
import { TabsType } from '~/types/TabsType.model'
import { TEXTS } from '~/utils/allConstants/Constants'

import DataGrid from './DataGrid/DataGrid'
import useDataGridStore from './DataGrid/DataGridContext'

import './TabsTablePage.scss'

const { EXPORT_TO_EXCEL, EXPORT_TO_PDF } = TEXTS

const StyledTabs = styled(Tabs)`
  &.MuiTabs-indicator {
    display: flex;
    justify-content: center;
    background-color: transparent;
  }

  .MuiTabs-indicatorSpan {
    background-color: #2b2c2c;
    max-width: 120px;
    width: 100%;
  }
`

const StyledTab = styled(Tab)`
  color: #818385;
  font-weight: 400;

  &.Mui-selected {
    color: #2b2c2c;
    font-weight: 700;
  }

  &.Mui-focusVisible {
    background-color: red;
  }
`

interface Props {
  currentTab: string
  tabs: TabsType[]
  handleExcelExport?: () => Promise<void>
  handlePdfExport?: () => Promise<void>
  navigateUrl?: { name: string; link: string }
}

const TabsTablePage: FC<Props> = ({
  currentTab,
  tabs,
  handleExcelExport,
  handlePdfExport,
  navigateUrl,
}): ReactElement => {
  const [tabValue, setTabValue] = useState<string>(currentTab)
  const [isExportExcelLoading, setIsExportExcelLoading] = useState(false)
  const [isExportPdfLoading, setIsExportPdfLoading] = useState(false)
  const { setTableProps, tableProps, reset, data } = useDataGridStore()
  const navigate = useNavigate()

  const { pathname } = useLocation()
  const isApprovalRelatedPages = pathname.includes('/approvals/')
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleChangeTabs = (event: SyntheticEvent, newTabValue: string) => {
    if (tabValue !== newTabValue) {
      setTabValue(newTabValue)
    }
  }

  useEffect(() => {
    setTableProps((prev: TabsType) => {
      const next = tabs.find(
        ({ name }: { name: string }) => name.indexOf(tabValue) === 0
      )
      if (!!prev.name && prev.name !== next.name) {
        reset()
      }
      return next
    })

    if (tabValue !== currentTab) {
      navigate([...pathname.split('/').slice(0, -1), tabValue].join('/'))
    }
  }, [tabValue])

  const onExportExcelButtonClick = useCallback(() => {
    setIsExportExcelLoading(true)
    handleExcelExport().then(() => setIsExportExcelLoading(false))
  }, [handleExcelExport])

  const onExportPdfButtonClick = useCallback(() => {
    setIsExportPdfLoading(true)
    handlePdfExport().then(() => setIsExportPdfLoading(false))
  }, [handlePdfExport])

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box component="div">
        <Grid
          container
          direction={isTabletOrMobile ? 'column-reverse' : 'row'}
          sx={{ gap: 2 }}
        >
          <div className="tabs-and-filter">
            <StyledTabs
              variant="scrollable"
              scrollButtons="auto"
              value={tabValue}
              onChange={handleChangeTabs}
              TabIndicatorProps={{
                children: <span className="MuiTabs-indicatorSpan" />,
              }}
            >
              {tabs.map(({ label, name }: { label: string; name: string }) => {
                return (
                  <StyledTab
                    value={name.split('_')[0]}
                    label={label}
                    key={label}
                  />
                )
              })}
            </StyledTabs>
            {isTabletOrMobile && !isApprovalRelatedPages && (
              <div className="filter">
                <FilterGridData />
              </div>
            )}
          </div>

          {(handlePdfExport || handleExcelExport || navigateUrl) && (
            <Box
              className="action-buttons-wrapper"
              sx={{
                flex: 1,
                flexWrap: isTabletOrMobile ? 'wrap' : 'nowrap',
                minWidth: 180,
              }}
            >
              {handlePdfExport && (
                <LoadingButton
                  className="action-button"
                  variant={'contained'}
                  sx={{
                    minWidth: 180,
                  }}
                  loading={isExportPdfLoading}
                  onClick={onExportPdfButtonClick}
                  disabled={data?.items.length === 0}
                >
                  {EXPORT_TO_PDF}
                </LoadingButton>
              )}
              {handleExcelExport && (
                <LoadingButton
                  className="action-button"
                  variant={'contained'}
                  sx={{
                    minWidth: 180,
                  }}
                  loading={isExportExcelLoading}
                  onClick={onExportExcelButtonClick}
                  disabled={data?.items.length === 0}
                >
                  {EXPORT_TO_EXCEL}
                </LoadingButton>
              )}
              {navigateUrl && (
                <Button
                  className="action-button"
                  variant={'contained'}
                  onClick={() =>
                    navigate(
                      [
                        ...pathname.split('/').slice(0, -1),
                        navigateUrl?.link,
                      ].join('/')
                    )
                  }
                  sx={{
                    minWidth: 180,
                  }}
                >
                  {navigateUrl?.name}
                </Button>
              )}
            </Box>
          )}
        </Grid>
      </Box>

      <Box component="div">
        {Object.keys(tableProps).length > 0 && <DataGrid />}
      </Box>
    </Box>
  )
}

export default memo(TabsTablePage)
