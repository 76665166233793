import { FC, ReactElement, useState } from 'react'

import TableNoDataFoundModalBody from './modalBodies/TableNoDataFoundModalBody'
import CustomModal from './CustomModal'

interface Props {
  message: string
}

const NoDataFoundModal: FC<Props> = ({ message }): ReactElement => {
  const [openModal, setOpenModal] = useState(true)

  return (
    <CustomModal open={openModal} setOpen={setOpenModal} sx={{ width: '50%' }}>
      <TableNoDataFoundModalBody message={message} />
    </CustomModal>
  )
}

export default NoDataFoundModal
