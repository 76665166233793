import { createTheme } from '@mui/material'

import variables from './scss/_variables.module.scss'

const {
  acrColorWhite,
  acrColorBlack,
  acrColorGrey,
  acrColorLightGrey,
  acrColorRed,
  acrColorPrimary,
  acrColorNavyBlue,
} = variables

export const theme = createTheme({
  palette: {
    primary: {
      main: acrColorPrimary,
    },
    secondary: {
      main: acrColorGrey,
    },
    text: {
      primary: acrColorBlack,
      disabled: acrColorLightGrey,
      secondary: acrColorGrey,
    },
    background: {
      default: acrColorWhite,
    },
  },
  status: {
    danger: acrColorRed,
  },
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    h1: {
      fontSize: '32px',
      fontWeight: 700,
      color: acrColorNavyBlue,
    },
    h3: {
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 700,
      color: acrColorGrey,
    },
    h4: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '30px',
    },
    h5: {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '150%',
      color: acrColorBlack,
    },
  },
})
