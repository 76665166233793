import ApprovalIcon from '@mui/icons-material/Approval'
import DashboardIcon from '@mui/icons-material/Dashboard'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import ReceiptIcon from '@mui/icons-material/Receipt'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import SummarizeIcon from '@mui/icons-material/Summarize'
import ViewListIcon from '@mui/icons-material/ViewList'

import { MENUS, PAGE_TITLES, TEXTS } from '~/utils/allConstants/Constants'

export type Item = {
  name: string
}

export type Page = {
  name: string
  icon: any
  menuItem?: Item[]
}

const { DASHBOARD, APPROVALS, INVOICES, ORDERS, PRICE_LIST, REPORTS, RETURNS } =
  PAGE_TITLES
const { ALL_APPROVALS, ALL_RETURNS, MY_APPROVALS, PRICE_CHANGE_HISTORY } = MENUS
const {
  ALL_INVOICES,
  OPEN_INVOICES,
  CLOSED_INVOICES,
  OPEN_ORDERS,
  ORDERS_HISTORY,
  OPEN_RETURNS,
  RETURNS_HISTORY,
} = TEXTS

export const MobileMenus: Page[] = [
  {
    name: DASHBOARD,
    icon: DashboardIcon,
  },
  {
    name: INVOICES,
    icon: ReceiptIcon,
    menuItem: [
      {
        name: ALL_INVOICES,
      },
      {
        name: OPEN_INVOICES,
      },
      {
        name: CLOSED_INVOICES,
      },
    ],
  },
  {
    name: ORDERS,
    icon: ShoppingCartIcon,
    menuItem: [
      {
        name: OPEN_ORDERS,
      },
      {
        name: ORDERS_HISTORY,
      },
    ],
  },

  {
    name: PRICE_LIST,
    icon: ViewListIcon,
  },
  {
    name: RETURNS,
    icon: KeyboardReturnIcon,
    menuItem: [
      {
        name: OPEN_RETURNS,
      },
      {
        name: RETURNS_HISTORY,
      },
    ],
  },
  {
    name: APPROVALS,
    icon: ApprovalIcon,
    menuItem: [
      {
        name: ALL_APPROVALS,
      },
      {
        name: ALL_RETURNS,
      },
      {
        name: MY_APPROVALS,
      },
    ],
  },
  {
    name: REPORTS,
    icon: SummarizeIcon,
    menuItem: [
      {
        name: PRICE_CHANGE_HISTORY,
      },
    ],
  },
]

export const Pages: Page[] = [
  {
    name: DASHBOARD,
    icon: DashboardIcon,
  },
  {
    name: INVOICES,
    icon: ReceiptIcon,
  },
  {
    name: ORDERS,
    icon: ShoppingCartIcon,
  },
  {
    name: PRICE_LIST,
    icon: ViewListIcon,
  },
  {
    name: RETURNS,
    icon: KeyboardReturnIcon,
  },
  {
    name: APPROVALS,
    icon: ApprovalIcon,
    menuItem: [
      {
        name: ALL_APPROVALS,
      },
      {
        name: ALL_RETURNS,
      },
      {
        name: MY_APPROVALS,
      },
    ],
  },
  {
    name: REPORTS,
    icon: SummarizeIcon,
    menuItem: [
      {
        name: PRICE_CHANGE_HISTORY,
      },
    ],
  },
]
