import { useParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'

import { GlobalLoading } from '~/components/UI/Loading'
import { fetchReturnDetailsData } from '~/services/returns'

import ApprovalReturnRequestInfoDisplayPage from './ApprovalReturnRequestInfoDisplayPage/ApprovalReturnRequestInfoDisplayPage'
import { fields as allFields } from './ApprovalReturnRequestInfoDisplayPage/components/ApprovalReturnRequestContent'
import ApprovalReturnRequestInfoDisplayPageCarrier from './ApprovalReturnRequestInfoDisplayPageCarrier/ApprovalReturnRequestInfoDisplayPageCarrier'
import { fields as carrierFields } from './ApprovalReturnRequestInfoDisplayPageCarrier/components/ApprovalReturnRequestContent'

const options = {
  name: 'Return Request Approval',
  queryName: 'return-info-details-approvals',
}
const ApprovalReturnRequestFetchInfo = () => {
  const { id } = useParams<{ id?: string }>()

  const { isFetching, data } = useQuery(
    [`return-info-details-approvals`, id],
    () => {
      return fetchReturnDetailsData(id)
    },
    { keepPreviousData: true }
  )
  if (isFetching) {
    return <GlobalLoading />
  }

  const isLogisticFlowRequired = data.currentGroup === 'LC'

  return isLogisticFlowRequired ? (
    <ApprovalReturnRequestInfoDisplayPageCarrier
      data={data}
      options={{ ...options, id, fields: carrierFields }}
    />
  ) : (
    <ApprovalReturnRequestInfoDisplayPage
      data={data}
      options={{ ...options, id, fields: allFields }}
    />
  )
}

export default ApprovalReturnRequestFetchInfo
