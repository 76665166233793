import { useContext } from 'react'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { NotificationContext } from '../components/Notification'
import { APIErrorRes } from '../types/APIErrorRes.model'
import { Signup, Token } from '../types/Auth.model'
import { CurrentUser, User } from '../types/User.model'
import {
  CreateNewUserForm,
  LoginUserForm,
  RegisterUserForm,
} from '../types/UserForm.model'

import axiosInstance from './axiosInstance'

export interface ForgotPasswordFormProps {
  email: string
}
export interface SetPasswordFormProps {
  password: string
}

export const useLogingMutation = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const loginMutation = useMutation(
    (form: LoginUserForm) => {
      return axiosInstance
        .post<Token>('/v1/auth/login', form)
        .then(({ data }) => {
          return data
        })
        .catch(({ response }) => {
          return Promise.reject(response)
        })
    },
    {
      onSuccess: (data, form: LoginUserForm) => {
        data.impersonate = false
        const stringifyData = JSON.stringify(data)
        if (form.remember_me) {
          window.localStorage.setItem('tokens', stringifyData)
        }

        window.sessionStorage.setItem('tokens', stringifyData)

        queryClient.invalidateQueries(['useAuth'])
        queryClient.invalidateQueries(['accounts'])

        ReactGA.event({
          category: 'User',
          action: 'Login Success',
        })

        navigate('/')
      },
    }
  )

  return loginMutation
}

export const useLogoutMutation = () => {
  const logoutMutation = useMutation(
    () => {
      return axiosInstance.post<string>('/v1/auth/logout')
    },
    {
      onSuccess: () => {
        let tokens = 'tokens'
        if (window.sessionStorage.getItem('impersonate_tokens') !== null) {
          tokens = 'impersonate_tokens'
        }
        window.localStorage.removeItem(tokens)
        window.sessionStorage.removeItem(tokens)
      },
    }
  )

  return logoutMutation
}

export const useRegisterMutation = () => {
  return useMutation((form: RegisterUserForm) => {
    return axiosInstance
      .post<Signup>('/v1/auth/signup', form)
      .catch(({ response }) => {
        return Promise.reject(response)
      })
  })
}

export const useNewRegisterMutation = () => {
  return useMutation((form: CreateNewUserForm) => {
    return axiosInstance
      .post<CreateNewUserForm>('/v1/customers', form)
      .catch(({ response }) => {
        return Promise.reject(response)
      })
  })
}

export const useSetPasswordMutation = () => {
  const alert = useContext(NotificationContext)
  return useMutation(
    (form: SetPasswordFormProps) => {
      return axiosInstance.post<{ password: string }>(
        '/v1/auth/setPassword',
        form
      )
    },
    {
      onError: (e: APIErrorRes) => {
        alert.error(e.response.data.message || 'Something went wrong')
      },
    }
  )
}

export const useForgotPasswordMutation = () => {
  const alert = useContext(NotificationContext)

  return useMutation(
    (form: ForgotPasswordFormProps) => {
      return axiosInstance.post<{ email: string }>(
        '/v1/auth/password/forgot',
        form
      )
    },
    {
      onError: (e: APIErrorRes) => {
        alert.error(e.response.data.message || 'Something went wrong')
      },
    }
  )
}

const auth = async () => {
  let tokens = JSON.parse(window.sessionStorage.getItem('tokens') as string)
  if (window.sessionStorage.getItem('impersonate_tokens') !== null) {
    tokens = JSON.parse(
      window.sessionStorage.getItem('impersonate_tokens') as string
    )
  }

  if (!tokens) {
    return Promise.reject('not authorized')
  }

  return axiosInstance
    .get<CurrentUser>('/v1/customers/current')
    .then(({ data }) => {
      return {
        ...data,
        impersonate: tokens.impersonate,
      }
    })
    .catch(({ response }) => {
      return Promise.reject(
        response.status === 401 ? 'not authorized' : response
      )
    })
}

export const useAuth = () => {
  return useQuery(['useAuth'], auth)
}

export const doRefreshToken = () => {
  const refreshToken = getRefreshToken()
  if (!refreshToken) {
    return Promise.reject()
  }

  const params = new URLSearchParams({
    refreshToken: getRefreshToken(),
  })
  return axiosInstance
    .post<Token>('/v1/auth/token/refresh?' + params)
    .then(({ data }) => {
      data.impersonate =
        window.sessionStorage.getItem('impersonate_tokens') !== null

      const stringifyData = JSON.stringify(data)
      if (!data.impersonate) {
        const hasLocalStorage = window.localStorage.getItem('tokens')
        if (!!hasLocalStorage) {
          window.localStorage.setItem('tokens', stringifyData)
        }
        window.sessionStorage.setItem('tokens', stringifyData)
      } else {
        window.sessionStorage.setItem('impersonate_tokens', stringifyData)
      }
    })
}

export const getAccessToken = () => {
  let tokens = JSON.parse(window.sessionStorage.getItem('tokens') as string)
  if (window.sessionStorage.getItem('impersonate_tokens') !== null) {
    tokens = JSON.parse(
      window.sessionStorage.getItem('impersonate_tokens') as string
    )
  }

  return !!tokens ? tokens.accessToken : null
}

export const getRefreshToken = () => {
  let tokens = JSON.parse(window.sessionStorage.getItem('tokens') as string)
  if (window.sessionStorage.getItem('impersonate_tokens') !== null) {
    tokens = JSON.parse(
      window.sessionStorage.getItem('impersonate_tokens') as string
    )
  }

  return !!tokens ? tokens.refreshToken : null
}

export const useImpersonate = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  return useMutation(
    (user: User) => {
      return axiosInstance
        .post<Token>(`/v1/admin/auth/${user.id}/impersonate`)
        .then(({ data }) => data)
    },
    {
      onSuccess: (tokens) => {
        tokens.impersonate = true
        const stringifyData = JSON.stringify(tokens)
        window.sessionStorage.setItem('impersonate_tokens', stringifyData)

        queryClient.invalidateQueries()

        navigate('/')
      },
    }
  )
}
