import { FC, ReactElement, useEffect, useState } from 'react'
import { Control, UseFormResetField, UseFormSetValue } from 'react-hook-form'

import { Box, Grid, MenuItem, SelectChangeEvent } from '@mui/material'

import ControllerStyledSelect from '~/components/Form/StyledSelect'
import Loading from '~/components/UI/Loading'
import {
  DenialReasons,
  InternalReasons,
  UpdateReturnData,
} from '~/types/Return.model'
import { ApprovalStatusItems } from '~/utils/allConstants/Constants'
import {
  getApprovalStatus,
  isMisPickSelectedAsInternalReason,
} from '~/utils/helpers/helperFunctions'

const { APPROVED, DENIED } = ApprovalStatusItems

type Props = {
  control: Control<any>
  index: number
  row: UpdateReturnData
  resetField: UseFormResetField<any>
  setValue: UseFormSetValue<any>
  denialReasonsCodes: DenialReasons
  internalReasonCodes: InternalReasons
}
const ApprovalReturnRequestDetailsInput: FC<Props> = ({
  control,
  index,
  row,
  resetField,
  setValue,
  denialReasonsCodes,
  internalReasonCodes,
}): ReactElement => {
  const isMisPickSelected = isMisPickSelectedAsInternalReason({
    internalReasonCodes,
    internalReason: row.internalReason,
  })

  useEffect(() => {
    if (denialReasonsCodes != undefined) {
      setAPvalue(row.approved === true ? APPROVED : DENIED)
      setValue(
        `items.${index}.alreadyValidated`,
        row.systemDenied ? true : false
      )
      setValue(`items.${index}.productId`, row.productId)
      setValue(`items.${index}.reason`, row.reason)
      setValue(`items.${index}.lineNumber`, row.lineNumber)
      setValue(`items.${index}.approvalStatus`, getApprovalStatus(row.approved))
      const name = `items.${index}.denialReason`
      setValue(name, '')
      if (!row.itemDenialReasonList) {
        setValue(name, '')
      } else {
        const list = row.itemDenialReasonList
          .filter((item) => item != null)
          .map(
            (item) =>
              denialReasonsCodes[item as keyof typeof denialReasonsCodes]
          )
        setValue(name, list[0])
      }
      return
    }
    setValue(`items.${index}.approvalStatus`, '')
    setValue(`items.${index}.denialReason`, '')
  }, [denialReasonsCodes, row])

  const [APvalue, setAPvalue] = useState('')

  const handleChange = (event: SelectChangeEvent) => {
    setAPvalue(event.target.value)
    if (event.target.value === APPROVED) {
      resetField(`items.${index}.denialReason`)
    }
  }

  if (denialReasonsCodes === undefined) {
    return <Loading />
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{ minWidth: 350, alignItems: 'flex-start' }}
    >
      <Grid item xs={6} sm={6} md={6}>
        {row.systemDenied ? (
          <Box className="flexVerticalCenter">{DENIED}</Box>
        ) : (
          <ControllerStyledSelect
            key={JSON.stringify(row)}
            name={`items.${index}.approvalStatus`}
            control={control}
            onCustomChange={handleChange}
            disabled={isMisPickSelected}
            placeholder="Please select status"
          >
            {Object.keys(ApprovalStatusItems).map((key) => {
              return (
                <MenuItem
                  key={key}
                  value={
                    ApprovalStatusItems[key as keyof typeof ApprovalStatusItems]
                  }
                >
                  {ApprovalStatusItems[key as keyof typeof ApprovalStatusItems]}
                </MenuItem>
              )
            })}
          </ControllerStyledSelect>
        )}
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        {row.systemDenied ? (
          <Box className="flexVerticalCenter">
            {row.itemDenialReasonList
              .map(
                (item) =>
                  denialReasonsCodes[item as keyof typeof denialReasonsCodes]
              )
              .join(', ')}
          </Box>
        ) : (
          <ControllerStyledSelect
            key={`${row.id}-${row.code}`}
            name={`items.${index}.denialReason`}
            control={control}
            disabled={APvalue === APPROVED || isMisPickSelected}
            placeholder="Please select denial reason"
          >
            {Object.keys(denialReasonsCodes).map((key) => {
              return (
                <MenuItem
                  key={key}
                  value={
                    denialReasonsCodes[key as keyof typeof denialReasonsCodes]
                  }
                >
                  {denialReasonsCodes[key as keyof typeof denialReasonsCodes]}
                </MenuItem>
              )
            })}
          </ControllerStyledSelect>
        )}
      </Grid>
    </Grid>
  )
}

const ApprovalReturnRequestDetailsInputHeader = () => {
  return (
    <Grid container spacing={2} sx={{ minWidth: 350, alignItems: 'center' }}>
      <Grid item xs={6} sm={6} md={6}>
        Approval Status
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        Denial Reason Code
      </Grid>
    </Grid>
  )
}

export { ApprovalReturnRequestDetailsInputHeader }

export default ApprovalReturnRequestDetailsInput
