import { KeyboardEvent, MouseEvent, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import SearchIcon from '@mui/icons-material/Search'
import { Box, IconButton, Input } from '@mui/material'

import { useAuth } from '~/services/auth'
import { RoleTypes } from '~/services/users'
import { theme } from '~/theme/AppTheme'

import './SearchBox.scss'
import variables from '~/theme/scss/_variables.module.scss'

const { acrColorPrimary } = variables

const SearchBox = () => {
  const { data: user } = useAuth()
  const { searchVal } = useParams<{ searchVal?: string }>()
  const [search, setSearch] = useState<string>(searchVal ?? '')
  const navigate = useNavigate()

  const handleSubmit = (
    event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>
  ) => {
    event.preventDefault()
    if (search && !search.startsWith('.') && !search.includes('/')) {
      navigate(`search-results/${search.trim()}`)
      setSearch('')
    }
  }

  return (
    <Box
      sx={{
        width: '324px',
        height: '44px',
        display: 'flex',
        padding: '0 10px 0 5px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        borderRadius: '30px',
        border: '1px solid',
        borderColor: acrColorPrimary,
        [theme.breakpoints.down('sm')]: {
          width: 'auto',
          mt: 1,
        },
      }}
    >
      <IconButton type="submit" onClick={handleSubmit}>
        <SearchIcon sx={{ color: acrColorPrimary }} />
      </IconButton>
      <Input
        type={'text'}
        color="secondary"
        placeholder={
          [RoleTypes.CARRIER].includes(user.role)
            ? 'Search Tracking Number'
            : 'Search Invoices and Orders'
        }
        className={'subheader-search'}
        onChange={(event) => setSearch(event.target.value)}
        value={search}
        disableUnderline={true}
        autoComplete="off"
        onKeyPress={(ev) => {
          if (ev.key === 'Enter') {
            handleSubmit(ev)
          }
        }}
      />
    </Box>
  )
}

export default SearchBox
