import { Link as RouterLink } from 'react-router-dom'

import { Grid, Link, Typography } from '@mui/material'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorBlue } = variables

const ForgotPasswordComplete = () => {
  return (
    <Grid
      container
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        textAlign: 'center',
      }}
    >
      <Grid item>
        <Typography variant="h1" mb={2}>
          An Email to reset your password has been sent.
        </Typography>

        <Typography
          variant={'h4'}
          sx={{
            color: (theme) => theme.palette.text.secondary,
            py: 3,
            fontSize: 16,
          }}
        >
          Please&nbsp;
          <Link
            component={RouterLink}
            to={'/login'}
            sx={{
              fontWeight: 'bold',
              color: acrColorBlue,
              textDecorationColor: acrColorBlue,
            }}
          >
            click here
          </Link>
          &nbsp; to go back to sign in.
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ForgotPasswordComplete
