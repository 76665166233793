export type Order = {
  actualShipmentDate: string
  invoiceNumber?: number
  hold_code: null
  id: string
  orderDate: string
  orderNumber: string
  orderPlacedBy: string
  orderStatus: OrderStatus
  orderTotal: number
  purchaseOrder: string
  reqDeliveryDate: string
  shipper: string
  state?: OrderState
  trackingNumber: string
  trackingUrl: string
  itemNo?: string
  orderType: string
}

export type OrderSummary = {
  items: Order[]
  totalCount: number
}

export enum OrderState {
  OPEN = 'Open',
  CLOSED = 'Closed',
}

export enum OrderStatus {
  Open = 'Open',
  Picking = 'Picking',
  Shipped = 'Shipped',
  Cancelled = 'Cancelled',
  Closed = 'Closed',
}
