import { Controller } from 'react-hook-form'

import { Select } from '@mui/material'

import { StyledFormFieldProps } from '~/types/StyledFormFieldProps.model'

import { FilterStyledInput } from './FilterTextField'

const FilterSelectField = ({
  control,
  name,
  children,
  ...rest
}: StyledFormFieldProps) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Select
          error={!!error}
          name={field.name}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          labelId={rest.id + '-label'}
          input={<FilterStyledInput />}
          fullWidth={true}
          displayEmpty
          {...rest}
          renderValue={(val) => {
            if (val === '') {
              return <span className={'placeholder'}>{'Please Select'}</span>
            }

            const itm = children.find(
              (itm: { props: { children: string; value: string } }) =>
                itm.props.value === val
            )
            return itm ? itm.props.children : val
          }}
        >
          {children}
        </Select>
      )}
    />
  )
}

export default FilterSelectField
