import { FC, ReactElement } from 'react'
import { useParams } from 'react-router-dom'

import { useQuery } from '@tanstack/react-query'

import { GlobalLoading } from '~/components/UI/Loading'
import { fetchCustomerAlertById } from '~/services/bannerNotification'

import BannerNotificationForm from './BannerNotificationForm'

const BannerNotificationEditForm: FC = (): ReactElement => {
  const { alertId } = useParams()

  const { isLoading, data } = useQuery(
    [`alert-id`, alertId],
    () => {
      return fetchCustomerAlertById(alertId)
    },
    { keepPreviousData: true }
  )

  if (isLoading) {
    return <GlobalLoading />
  }
  return <BannerNotificationForm bannerData={data} />
}

export default BannerNotificationEditForm
