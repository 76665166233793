import { useContext, useState } from 'react'
import ReactGA from 'react-ga4'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'

import { useNewRegisterMutation } from '~/services/auth'
import { theme } from '~/theme/AppTheme'
import { CreateNewUserForm } from '~/types/UserForm.model'
import { TEXTS } from '~/utils/allConstants/Constants'
import {
  companyNameValidation,
  companyPhoneValidation,
  emailValidation,
  firstNameValidation,
  lastNameValidation,
  mobilePhoneValidation,
  responsibilityAreaValidation,
  textFieldValidation,
  userTypeValidation,
} from '~/utils/helpers/helperFunctions'

import { NotificationContext } from '../Notification'
import UserRegistrationForm from '../Register/components/UserRegistrationForm'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorWhite } = variables
const { CREATE_NEW_USER } = TEXTS

const schema = yup
  .object({
    contactEmail: emailValidation,
    responsibilityArea: responsibilityAreaValidation,
    responsibilityAreaOther: textFieldValidation,
    userType: userTypeValidation,
    userTypeOther: textFieldValidation,
    firstName: firstNameValidation,
    lastName: lastNameValidation,
    contactPhone: mobilePhoneValidation,
    company: companyNameValidation,
    companyContactPhone: companyPhoneValidation,
  })
  .required()

const CreateUsers = () => {
  const newRegisterMutation = useNewRegisterMutation()

  const alert = useContext(NotificationContext)
  // FIXME: need to be improvement
  const [responseError, setResponseError] = useState('')

  const { handleSubmit, control, watch, reset } = useForm<CreateNewUserForm>({
    resolver: yupResolver(schema),
    defaultValues: schema.cast({}),
  })
  const isMatch = useMediaQuery(theme.breakpoints.down('md'))

  const onSubmit = (data: CreateNewUserForm) => {
    setResponseError('')
    if (
      data.responsibilityArea === 'Other' &&
      data.responsibilityAreaOther !== ''
    ) {
      data.responsibilityArea = data.responsibilityAreaOther
    }
    if (data.userType === 'Other' && data.userTypeOther !== '') {
      data.userType = data.userTypeOther
    }
    data.contactEmail = data.contactEmail.trim()
    newRegisterMutation.mutate(data, {
      onSuccess: () => {
        ReactGA.event({
          category: 'User',
          action: 'Registration Success',
        })
        alert.success('New User Created!')

        reset()
      },
      onError: (error: any) => {
        setResponseError(error.data.message)
      },
    })
  }

  return (
    <Box sx={{ maxWidth: '100%', mx: 'auto' }}>
      <Grid
        container
        sx={[
          {
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            mt: 0,
          },
          !isMatch && {
            background: acrColorWhite,
            borderRadius: '10px',
          },
        ]}
      >
        <Grid item xs={12} sm={12} md={12} lg={6} px={{ xs: 2, sm: 2, md: 3 }}>
          <Typography variant="h1" align={'center'}>
            {CREATE_NEW_USER}
          </Typography>
          <Typography
            variant="h4"
            sx={{
              color: (theme) => theme.palette.text.secondary,
              py: 3,
            }}
          >
            Fill in the required to complete the registration on behalf of a new
            user. Please ensure all information is correct. Upon clicking
            &apos;Create&apos; an email will be sent to the user to confirm
            their email and create a password.
          </Typography>
        </Grid>
        <UserRegistrationForm
          control={control}
          watch={watch}
          registerMutation={newRegisterMutation}
          responseError={responseError}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          submitButton={'Create'}
          password={false}
          type="newUser"
        />
      </Grid>
    </Box>
  )
}

export default CreateUsers
