import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import { Badge, IconButton } from '@mui/material'

import useDataGridStore from '~/components/UI/DataGrid/DataGridContext'
import FilterIcon from '~/UI/icons/FilterIcon'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorMediumGrey } = variables

const FilterGridData = () => {
  const { filters, clearFilters, openFilter } = useDataGridStore()
  return (
    <>
      <IconButton onClick={openFilter}>
        <Badge badgeContent={Object.keys(filters).length} color={'primary'}>
          <FilterIcon color={acrColorMediumGrey} />
        </Badge>
      </IconButton>

      {Object.keys(filters).length > 0 && (
        <IconButton onClick={clearFilters}>
          <FilterAltOffIcon />
        </IconButton>
      )}
    </>
  )
}

export default FilterGridData
