import { FC, Fragment, ReactElement } from 'react'

import { Grid } from '@mui/material'

import { InvoiceDetailsData } from '~/types/InvoiceDetailsData.model'
import { OrderDetailsData } from '~/types/OrderDetailsData.model'

import {
  MainTypography,
  SecondaryTypography,
  TitleTypography,
} from '../DetailsPage'

import './AssetSpecificInformation.scss'

type Fields = {
  id: string
  name?: string
  format?: (val: any) => void
  type?: string
}

type Items = {
  dataId?: string
  fields: Fields[]
  id: string
  title: string
  infoText?: string
}

export type AssetSpecificContent = {
  id: string
  items: Items[]
}

type Props = {
  assetSpecificContent: AssetSpecificContent[]
  data: OrderDetailsData | InvoiceDetailsData | any
}

const getFieldValue = ({
  field,
  currentData,
}: {
  field: Fields
  currentData: any
}) => {
  const fieldValue = currentData[field.id]
  switch (true) {
    case field.id === 'raNumber' || field.id === 'raPurchaseOrder':
      return fieldValue ? fieldValue : 'N/A'
    case field.hasOwnProperty('format'):
      return field.format(currentData)

    default:
      return fieldValue
  }
}

const AssetSpecificInformation: FC<Props> = ({
  assetSpecificContent,
  data,
}): ReactElement => {
  return (
    <>
      {assetSpecificContent.map((informationSection: AssetSpecificContent) => {
        return (
          <div key={informationSection.id} className="detail-grid-wrapper">
            <div className="detail-grid">
              {informationSection.items.map((section: Items) => {
                const currentData = section.hasOwnProperty('dataId')
                  ? data[section.dataId]
                  : data
                return (
                  <div key={section.id} className="grid-item">
                    <Grid container rowSpacing={1} columnSpacing={1}>
                      <Grid item xs={12}>
                        <TitleTypography
                          title={section.title}
                          infoText={section.infoText}
                        />
                      </Grid>
                      {section.fields.map((field: Fields) => (
                        <Fragment key={field.id}>
                          {field.hasOwnProperty('name') ? (
                            <>
                              <Grid item xs={5}>
                                <MainTypography text={field.name} />
                              </Grid>
                              <Grid item xs={7}>
                                <SecondaryTypography>
                                  {getFieldValue({ field, currentData })}
                                </SecondaryTypography>
                              </Grid>
                            </>
                          ) : (
                            <Grid item xs={12}>
                              <SecondaryTypography
                                component={
                                  field?.type === 'title' ? 'div' : 'p'
                                }
                              >
                                {field.hasOwnProperty('format')
                                  ? field.format(currentData)
                                  : currentData[field.id]}
                              </SecondaryTypography>
                            </Grid>
                          )}
                        </Fragment>
                      ))}
                    </Grid>
                  </div>
                )
              })}
            </div>
          </div>
        )
      })}
    </>
  )
}

export default AssetSpecificInformation
