import React from 'react'

import AccountLoader from '../components/Accounts/AccountLoader'
import OrderDetail from '../components/OrderDetail/OrderDetail'
import Orders from '../components/Orders/Orders'
import { RoutesCheck } from '../utils/allConstants/Constants'
import { RedirectWithAccountParams } from '../utils/routes-util'

import CheckRoutes from './CheckRoutes'

export default [
  {
    path: 'orders',
    element: <RedirectWithAccountParams to={'/orders/open-orders'} />,
  },
  {
    path: ':accountId/orders',
    element: (
      <RedirectWithAccountParams to={'/:accountId/orders/open-orders'} />
    ),
  },
  {
    path: ':accountId/:subAccountId/orders',
    element: (
      <RedirectWithAccountParams
        to={'/:accountId/:subAccountId/orders/open-orders'}
      />
    ),
  },

  {
    path: 'orders/:active_tab',
    element: (
      <CheckRoutes type={[RoutesCheck.PRIVATE, RoutesCheck.NOT_CARRIER]}>
        <Orders />
      </CheckRoutes>
    ),
  },
  {
    path: ':accountId/orders/:active_tab',
    element: (
      <CheckRoutes type={[RoutesCheck.PRIVATE, RoutesCheck.NOT_CARRIER]}>
        <AccountLoader>
          <Orders />
        </AccountLoader>
      </CheckRoutes>
    ),
  },
  {
    path: ':accountId/:subAccountId/orders/:active_tab',
    element: (
      <CheckRoutes type={[RoutesCheck.PRIVATE, RoutesCheck.NOT_CARRIER]}>
        <AccountLoader>
          <Orders />
        </AccountLoader>
      </CheckRoutes>
    ),
  },
  {
    path: 'orders/:id/details',
    element: (
      <CheckRoutes type={[RoutesCheck.PRIVATE, RoutesCheck.NOT_CARRIER]}>
        <OrderDetail />
      </CheckRoutes>
    ),
  },
]
