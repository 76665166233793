// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!./_variables.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"acrColorBlack": `#000`,
	"acrColorDarkGrey": `#2b2c2c`,
	"acrColorGrey": `#565759`,
	"acrColorMediumGrey": `#818385`,
	"acrColorLightGrey2": `#acaeb2`,
	"acrColorLightGrey": `#b3b5b6`,
	"acrColorRedishGrey": `#dfdfdf`,
	"acrColorGreenishWhite": `#e2f0d9`,
	"acrColorYellowishWhite": `#feefd8`,
	"acrColorBluishWhite": `#ecf0ff`,
	"acrColorCyanWhite": `#f7f8f8`,
	"acrColorWhite": `#fff`,
	"acrColorGreen": `#008f78`,
	"acrColorRed": `red`,
	"acrColorYellow": `#fcc163`,
	"acrColorPrimary": `#5860a0`,
	"acrColorDarkPrimary": `#3d4370`,
	"acrColorNavyBlue": `#252a82`,
	"acrColorBlue": `#0380f3`,
	"acrColorSkyBlue": `skyblue`,
	"acrColorPaginationBlue": `#505683`,
	"acrColorPaginationGreen": `#00bc9e`
};
export default ___CSS_LOADER_EXPORT___;
