import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material'

import { useAuth, useLogoutMutation } from '~/services/auth'
import { useChangeCurrentUser } from '~/services/users'
import { theme } from '~/theme/AppTheme'
import { TEXTS } from '~/utils/allConstants/Constants'
import {
  confirmNewPasswordValidation,
  passwordValidation,
} from '~/utils/helpers/helperFunctions'

import ControlledStyledTextField, {
  StyledTextField,
} from '../Form/StyledTextField'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorBlue } = variables
const {
  FIRST_NAME,
  LAST_NAME,
  EMAIL_ADDRESS,
  PHONE_NUMBER,
  COMPANY_NAME,
  COMPANY_PHONE_NUMBER,
  AREA_OF_RESPONSIBILITY,
  USER_TYPE,
  DISCARD,
  SAVE,
  YOUR_CURRENT_PASSWORD,
  NEW_PASSWORD,
  CONFIRM_NEW_PASSWORD,
  FORGOT_PASSWORD,
  MY_ACCOUNT,
} = TEXTS

const schema = yup
  .object({
    oldPassword: yup.string().default('').required('No old password provided'),
    newPassword: yup
      .string()
      .default('')
      .when('oldPassword', (oldPassword) => {
        if (oldPassword) {
          return yup
            .string()
            .required('No new password provided')
            .test('validation', 'error', passwordValidation)
        }
      }),
    confirmPassword: confirmNewPasswordValidation,
  })
  .required()

const UserDetails = () => {
  const { data: user } = useAuth()
  const logoutMutation = useLogoutMutation()
  const changeProfileMutation = useChangeCurrentUser(user.id)
  const isMatch = useMediaQuery(theme.breakpoints.down('md'))

  const { control, handleSubmit, reset } = useForm<{
    oldPassword: string
    newPassword: string
    confirmPassword: string
  }>({
    resolver: yupResolver(schema),
    // defaultValues: schema.cast(user),
  })

  const onSubmit = (data: {
    oldPassword: string
    newPassword: string
    confirmPassword: string
  }) => {
    changeProfileMutation.mutate(data, {
      onSuccess: () => {
        reset({ oldPassword: '', newPassword: '', confirmPassword: '' })
      },
    })
  }

  const onError = (errors: any, e: any) => console.log(errors, e)

  const onDiscard = () => {
    reset({ oldPassword: '', newPassword: '', confirmPassword: '' })
  }
  const handleLogOut = () => {
    logoutMutation.mutate(null, {
      onSuccess: () => {
        window.location.href = '/forgot-password'
      },
    })
  }

  return (
    <>
      <Typography variant="h3" pb={3}>
        {MY_ACCOUNT}
      </Typography>

      <Divider />

      <Grid container mt={3} pb={3} spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Typography variant={'h5'}>User Account Information</Typography>
          <Typography>Update your Account Information</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <StyledTextField
            name={'firstName'}
            value={user.firstName}
            label={FIRST_NAME}
            disabled={true}
          />

          <StyledTextField
            name={'lastName'}
            value={user.lastName}
            label={LAST_NAME}
            disabled={true}
          />

          <StyledTextField
            name={'contactEmail'}
            value={user.contactEmail}
            label={EMAIL_ADDRESS}
            disabled={true}
          />

          <StyledTextField
            name={'contactPhone'}
            value={user.contactPhone}
            label={PHONE_NUMBER}
            disabled={true}
          />

          <StyledTextField
            name={'company'}
            value={user.company}
            label={COMPANY_NAME}
            disabled={true}
          />

          <StyledTextField
            name={'companyContactPhone'}
            value={user.companyContactPhone}
            label={COMPANY_PHONE_NUMBER}
            disabled={true}
          />

          <StyledTextField
            name={'responsibilityArea'}
            value={user.responsibilityArea ?? ''}
            label={AREA_OF_RESPONSIBILITY}
            disabled={true}
          />

          <StyledTextField
            name={'userType'}
            value={user.userType ?? ''}
            label={USER_TYPE}
            disabled={true}
          />
        </Grid>
      </Grid>

      <Divider />

      <Grid container mt={3} pb={3} spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <Typography variant={'h5'}>Password change</Typography>
          <Typography>Provide your Personal Information</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          <ControlledStyledTextField
            name={'oldPassword'}
            control={control}
            label={YOUR_CURRENT_PASSWORD}
            type={'password'}
            autoComplete="new-password"
          />

          <ControlledStyledTextField
            name={'newPassword'}
            control={control}
            label={NEW_PASSWORD}
            type={'password'}
            tooltip={
              <ul style={{ margin: 0, paddingLeft: '15px', lineHeight: 1.5 }}>
                Password Requirements:
                <li>Min length 6 characters</li>
                <li>1 special character</li>
                <li>1 numeric digit</li>
                <li>1 lowercase character</li>
                <li>1 uppercase character</li>
              </ul>
            }
          />

          <ControlledStyledTextField
            name={'confirmPassword'}
            control={control}
            label={CONFIRM_NEW_PASSWORD}
            type={'password'}
          />
        </Grid>
      </Grid>

      <Stack
        spacing={2}
        direction={{ xs: 'column', md: 'row' }}
        sx={{ justifyContent: 'flex-end' }}
      >
        <Button
          variant="outlined"
          size={'large'}
          sx={{ width: !isMatch ? '200px' : '100%' }}
          onClick={onDiscard}
        >
          {DISCARD}
        </Button>

        <LoadingButton
          variant="contained"
          size={'large'}
          sx={{ width: !isMatch ? '200px' : '100%' }}
          onClick={handleSubmit(onSubmit, onError)}
          loading={changeProfileMutation.isLoading}
        >
          {SAVE}
        </LoadingButton>
      </Stack>

      <Grid container sx={{ mt: 2, textAlign: 'right' }}>
        <Grid item xs={true}>
          <Link
            onClick={handleLogOut}
            sx={{
              fontSize: '16px',
              fontWeight: 'bold',
              color: acrColorBlue,
              textDecorationColor: acrColorBlue,
              cursor: 'pointer',
              '&:hover': {
                textDecoration: 'none',
              },
            }}
          >
            {FORGOT_PASSWORD}
          </Link>
        </Grid>
      </Grid>
    </>
  )
}

export default UserDetails
