import React from 'react'

import { Chip } from '@mui/material'

import { InternalLink } from '~/components/UI/DataGrid/DataGrid'
import { Fields } from '~/types/Fields.model'
import { Invoice } from '~/types/Invoice.model'
import { TEXTS } from '~/utils/allConstants/Constants'
import { formatCurrency, formatDate } from '~/utils/helpers/helperFunctions'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorYellowishWhite, acrColorYellow } = variables
const {
  INVOICE_NUMBER,
  ORDER_NUMBER,
  STATUS,
  INVOICE_AMOUNT,
  BALANCE,
  CARRIER,
  INVOICE_DATE,
  INVOICE_DUE_DATE,
  CUSTOMER_PO,
  TRACKING_NUMBER,
} = TEXTS

const fields: Fields[] = [
  {
    id: 'invoiceNumber',
    label: INVOICE_NUMBER,
    minWidth: 120,
    filterFieldConfiguration: {
      type: 'freeText',
    },
    format: (value: string) => (
      <InternalLink to={`/invoices/${value}/details`}>{value}</InternalLink>
    ),
    sort: true,
  },
  {
    id: 'orderNumber',
    label: ORDER_NUMBER,
    minWidth: 110,
    filterFieldConfiguration: {
      type: 'freeText',
    },
    format: (value: string, { invoiceNumber }: { invoiceNumber: string }) => (
      <InternalLink to={`/invoices/${invoiceNumber}/order/${value}/details`}>
        {value}
      </InternalLink>
    ),
    sort: true,
  },
  {
    id: 'state',
    label: STATUS,
    minWidth: 80,
    format: (value: string) => {
      if (!value) {
        return null
      } else {
        return (
          <Chip
            label={value}
            sx={{
              backgroundColor: acrColorYellowishWhite,
              border: '1px solid',
              borderColor: acrColorYellow,
            }}
          />
        )
      }
    },
  },
  {
    id: 'invoiceAmount',
    label: INVOICE_AMOUNT,
    minWidth: 115,
    filterFieldConfiguration: {
      type: 'range-freeText',
      prefix: '$',
    },
    format: (value: number) => formatCurrency(value),
    sort: true,
  },
  {
    id: 'balance',
    label: BALANCE,
    minWidth: 70,
    filterFieldConfiguration: {
      type: 'range-freeText',
      prefix: '$',
    },
    format: (value: number) => formatCurrency(value),
    sort: true,
  },
  {
    id: 'customerPO',
    label: CUSTOMER_PO,
    minWidth: 100,
    filterFieldConfiguration: {
      type: 'freeText',
    },
    sort: true,
  },
  {
    id: 'invoiceDate',
    label: INVOICE_DATE,
    minWidth: 100,
    filterFieldConfiguration: {
      type: 'range-date',
    },
    format: (value: string) => formatDate(value),
    sort: true,
  },
  {
    id: 'dueDate',
    label: INVOICE_DUE_DATE,
    minWidth: 100,
    filterFieldConfiguration: {
      type: 'range-date',
    },
    format: (value: string) => formatDate(value),
    sort: true,
  },
  {
    id: 'shipper',
    label: CARRIER,
    minWidth: 100,
    sort: true,
  },
  {
    id: 'trackingNumber',
    label: TRACKING_NUMBER,
    minWidth: 50,
    format: (value: string, row: Invoice) =>
      row.trackingUrl ? (
        <InternalLink to={row.trackingUrl}>{value}</InternalLink>
      ) : (
        value
      ),
    sort: false,
  },
]

export { fields }
