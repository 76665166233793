import { useState } from 'react'

import RegisterComplete from '../components/Register/RegisterComplete'
import RegisterForm from '../components/Register/RegisterForm'

const RegisterRoute = () => {
  const [completeForm, setCompleteForm] = useState<boolean>(false)

  if (completeForm) {
    return <RegisterComplete />
  }

  return <RegisterForm complete={setCompleteForm} />
}

export default RegisterRoute
