import { FC, ReactElement, useCallback, useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { v4 as uuid } from 'uuid'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { ArrowBack } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import CreatedReturnInfo from '~/components/common/createdReturnInfo/CreatedReturnInfo'
import DataGrid from '~/components/UI/DataGrid/DataGrid'
import useDataGridStore, {
  DataGridProvider,
} from '~/components/UI/DataGrid/DataGridContext'
import Loading from '~/components/UI/Loading'
import { fetchInternalReasons, useReviewReturnCSR } from '~/services/approval'
import { fetchReturnReasons } from '~/services/returns'
import {
  ReturnItemList,
  ReturnRequestApproval,
  ReviewData,
  ReviewDataItem,
} from '~/types/Approval.model'
import { Fields } from '~/types/Fields.model'
import { ReturnItemPickupOptions, TEXTS } from '~/utils/allConstants/Constants'
import { exportFile } from '~/utils/helpers/helperFunctions'

import { mutateFunction } from '../components/ApprovalMutation'

import ReviewReturnRequestDetailsInput, {
  ReviewReturnRequestDetailsInputHeader,
} from './components/ReviewReturnRequestDetailsInput'

const {
  WAREHOUSE_MISPICK,
  ATTACHMENTS,
  DOWNLOAD_ATTACHMENTS,
  COMPLETE_RESEARCH,
  SAVE,
  BACK,
  NO_DATA_FOUND,
} = TEXTS

type Options = {
  id: string
  name: string
  queryName: string
  tableProps: { fields: Fields[] }
}

type Props = {
  data: ReturnRequestApproval
  options: Options
}

const ReviewReturnRequestInfoDisplayPage: FC<Props> = ({
  data,
  options,
}): ReactElement => {
  const navigate = useNavigate()
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const source = useCallback(() => {
    return {
      items: data.returnItemList,
    }
  }, [data])

  const { data: internalReasonCodes, isFetching } = useQuery(
    [`return-reasons`],
    () => {
      return fetchInternalReasons()
    },
    { keepPreviousData: true }
  )

  const { data: returnReasonCodes, isFetching: isReturnReasonCodesFetching } =
    useQuery(['return-reason-code'], () => fetchReturnReasons(), {
      keepPreviousData: true,
    })

  const { setTableProps, tableProps } = useDataGridStore()

  const returnReviewCsrMutation = useReviewReturnCSR(data.id)

  const schema = yup.object().shape({
    items: yup.array().of(
      yup.object().shape({
        internalReason: yup
          .string()
          .default('')
          .required('Internal Reason is required.'),
        comment: yup.string().default(''),
        receivedItemNumber: yup
          .string()
          .nullable()
          .default('')
          .when('internalReason', (internalReason, field) => {
            if (internalReason === WAREHOUSE_MISPICK) {
              return yup
                .string()
                .nullable()
                .required('Item number is required.')
            }
            return field
          }),
        pickUpInformation: yup
          .string()
          .default(null)
          .when('internalReason', (internalReason, field) => {
            if (internalReason === WAREHOUSE_MISPICK) {
              return yup.string().required('Pickup option is required.')
            }
            return field
          }),
      })
    ),
  })

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      items: [],
    },
    mode: 'all',
    resolver: yupResolver(schema),
  })

  const ReviewData = (data: ReviewData) => {
    return {
      items: data.items.map(
        (item: ReviewDataItem) =>
          [
            {
              ...item,
              internalReason: Object.keys(internalReasonCodes).find(
                (k) =>
                  internalReasonCodes[k as keyof typeof internalReasonCodes] ===
                  item.internalReason
              ),
              pickUpInformation: Object.keys(ReturnItemPickupOptions).find(
                (k) =>
                  ReturnItemPickupOptions[
                    k as keyof typeof ReturnItemPickupOptions
                  ] === item.pickUpInformation
              ),
            },
          ][0]
      ),
    }
  }

  const handleResearch = (data: ReviewData) => {
    setIsSubmitLoading(true)

    mutateFunction(
      returnReviewCsrMutation,
      ReviewData(data),
      true,
      setIsSubmitLoading,
      navigate,
      '/approvals/all-approvals/csr'
    )
  }

  const handleSave = (data: ReviewData) => {
    setIsSaveLoading(true)

    mutateFunction(
      returnReviewCsrMutation,
      ReviewData(data),
      false,
      setIsSaveLoading,
      navigate,
      '/approvals/my-approvals'
    )
  }

  const actions = [
    {
      header: () => {
        return <ReviewReturnRequestDetailsInputHeader key={uuid()} />
      },
      render: (row: ReturnItemList, index: number) => {
        return (
          <ReviewReturnRequestDetailsInput
            key={uuid()}
            row={row}
            control={control}
            index={index}
            setValue={setValue}
            internalReasonCodes={internalReasonCodes}
          />
        )
      },
    },
  ]

  const handleAttachment = useCallback(() => {
    setIsLoading(true)
    ReactGA.event({
      category: 'Return Request Reviewer',
      action: 'Export as Zip',
    })
    return exportFile({
      name: 'returns',
      id: data.id,
      extension: 'zip',
      exportfilename: ATTACHMENTS,
    }).then(() => setIsLoading(false))
  }, [data.id])

  useEffect(() => {
    setTableProps({
      name: `${options.queryName}-${options.id}`,
      source,
      actions,
      ...options.tableProps,
    })
  }, [source, options, setTableProps, internalReasonCodes, returnReasonCodes])

  if (isFetching || isReturnReasonCodesFetching) {
    return <Loading />
  }

  const returnReasonCodeFieldIndex = options.tableProps.fields.findIndex(
    (item) => item.id === 'reason'
  )

  options.tableProps.fields[returnReasonCodeFieldIndex].format = (
    _,
    row: string | any
  ) => {
    return returnReasonCodes[row.reason as keyof typeof returnReasonCodes]
  }

  return (
    <Box sx={{ px: { xs: 2, md: 3 } }}>
      <Box>
        <Grid container mt={3} sx={{ alignItems: 'center' }}>
          <Grid item xs={12} className="action-buttons-wrapper">
            <Button
              startIcon={<ArrowBack />}
              sx={{
                fontSize: 20,
                color: (theme) => theme.palette.text.primary,
                px: 0,
                marginRight: 'auto',
              }}
              onClick={() => navigate(-1)}
            >
              {BACK}
            </Button>
            {Object.keys(data.assets).length > 0 && (
              <LoadingButton
                loading={isLoading}
                className={'action-button'}
                variant={'contained'}
                onClick={handleAttachment}
              >
                {DOWNLOAD_ATTACHMENTS}
              </LoadingButton>
            )}
          </Grid>
        </Grid>
        {!!data ? (
          <Box mt={3}>
            <CreatedReturnInfo pageHeading={options.name} returnData={data} />
            <Box sx={{ marginBottom: '20px' }}>
              {Object.keys(tableProps).length > 0 && <DataGrid />}
            </Box>
          </Box>
        ) : (
          <Box sx={{ height: 300 }}>
            <Typography
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
              }}
            >
              {NO_DATA_FOUND}
            </Typography>
          </Box>
        )}
      </Box>
      <Box className="bottom-buttons-wrapper">
        <LoadingButton
          variant={'contained'}
          onClick={handleSubmit(handleSave)}
          loading={isSaveLoading}
          className="action-button"
        >
          {SAVE}
        </LoadingButton>
        <LoadingButton
          variant={'contained'}
          loading={isSubmitLoading}
          onClick={handleSubmit(handleResearch)}
          className="action-button"
        >
          {COMPLETE_RESEARCH}
        </LoadingButton>
      </Box>
    </Box>
  )
}
// eslint-disable-next-line react/display-name
export default (props: Props) => (
  <DataGridProvider>
    <ReviewReturnRequestInfoDisplayPage {...props} />
  </DataGridProvider>
)
