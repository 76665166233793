import { LoadingButton } from '@mui/lab'
import { Alert, Grid, MenuItem, useMediaQuery } from '@mui/material'

import ControllerStyledFormControl from '~/components/Form/StyledFormControl'
import ControllerStyledSelect from '~/components/Form/StyledSelect'
import { NoGridStyledTextField } from '~/components/Form/StyledTextField'
import { GlobalLoading } from '~/components/UI/Loading'
import { useAreaOfResponsibility, UserTypes } from '~/services/users'
import { theme } from '~/theme/AppTheme'
import { UserRegisterForm } from '~/types/UserForm.model'
import { TEXTS } from '~/utils/allConstants/Constants'

const {
  FIRST_NAME,
  LAST_NAME,
  EMAIL_ADDRESS,
  MOBILE_NUMBER,
  COMPANY_NAME,
  COMPANY_PHONE_NUMBER,
  AREA_OF_RESPONSIBILITY,
  PASSWORD,
  CONFIRM_PASSWORD,
} = TEXTS

const UserRegistrationForm = ({
  control,
  watch,
  registerMutation,
  responseError,
  handleSubmit,
  submitButton,
  onSubmit,
  password,
  type,
}: UserRegisterForm) => {
  const watchUserType = watch('userType')
  const watchAreaOfResponsibility = watch('responsibilityArea')
  const isMatch = useMediaQuery(theme.breakpoints.down('md'))
  const { data: areaOfResponsibility, isLoading } = useAreaOfResponsibility()
  if (isLoading) {
    return <GlobalLoading />
  }
  return (
    <Grid item xs={12} sm={12} md={12} lg={6} px={{ xs: 2, sm: 2, md: 3 }}>
      <Grid container columnSpacing={4} pb={3}>
        <Grid item xs={12} md={6}>
          <ControllerStyledFormControl
            name="firstName"
            control={control}
            id="first-name-textfield"
            label={FIRST_NAME}
            placeholder={FIRST_NAME}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControllerStyledFormControl
            name="lastName"
            control={control}
            id="last-name-textfield"
            label={LAST_NAME}
            placeholder={LAST_NAME}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControllerStyledFormControl
            name="company"
            control={control}
            id="company-name-textfield"
            label={COMPANY_NAME}
            placeholder={COMPANY_NAME}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControllerStyledFormControl
            name="companyContactPhone"
            control={control}
            id="company-phone-number-textfield"
            label={COMPANY_PHONE_NUMBER}
            placeholder={COMPANY_PHONE_NUMBER}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControllerStyledFormControl
            name="contactEmail"
            control={control}
            id="contactEmail-textield"
            label={EMAIL_ADDRESS}
            placeholder={EMAIL_ADDRESS}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ControllerStyledFormControl
            name="contactPhone"
            control={control}
            id="phone-number-textfield"
            label={MOBILE_NUMBER}
            placeholder={MOBILE_NUMBER}
          />
        </Grid>
        {type != 'newCarrier' && (
          <>
            <Grid item xs={12} md={6}>
              <Grid container>
                <ControllerStyledSelect
                  name={'userType'}
                  control={control}
                  id="user-type-textfield"
                  label="User Type"
                >
                  {Object.values(UserTypes).map((key) => (
                    <MenuItem key={key} value={key}>
                      {key}
                    </MenuItem>
                  ))}
                  <MenuItem value={'Other'}>Other</MenuItem>
                </ControllerStyledSelect>
              </Grid>

              {watchUserType === 'Other' && (
                <Grid item xs={12} sx={{ marginTop: isMatch ? '0px' : '20px' }}>
                  <NoGridStyledTextField
                    name="userTypeOther"
                    control={control}
                    id="userType-textfield"
                    placeholder="Please specify User Type"
                  />
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <Grid container>
                {areaOfResponsibility && (
                  <ControllerStyledSelect
                    name={'responsibilityArea'}
                    control={control}
                    id="responsibilityArea-select"
                    label={AREA_OF_RESPONSIBILITY}
                  >
                    {areaOfResponsibility.map((item: string, index: number) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                    <MenuItem value={'Other'}>Other</MenuItem>
                  </ControllerStyledSelect>
                )}
              </Grid>
              {watchAreaOfResponsibility === 'Other' && (
                <Grid item xs={12} sx={{ marginTop: isMatch ? '0px' : '20px' }}>
                  <NoGridStyledTextField
                    name="responsibilityAreaOther"
                    control={control}
                    id="responsibilityArea-textfield"
                    placeholder="Please specify Area of Responsibility"
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
        {password && (
          <>
            <Grid item xs={12} md={6}>
              <ControllerStyledFormControl
                name="password"
                control={control}
                id="password-textield"
                label={PASSWORD}
                placeholder={PASSWORD}
                type="password"
                tooltip={
                  <ul
                    style={{ margin: 0, paddingLeft: '15px', lineHeight: 1.5 }}
                  >
                    Password Requirements:
                    <li>Min length 6 characters</li>
                    <li>1 special character</li>
                    <li>1 numeric digit</li>
                    <li>1 lowercase character</li>
                    <li>1 uppercase character</li>
                  </ul>
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <ControllerStyledFormControl
                name="confirm_password"
                control={control}
                id="confirm-password-textield"
                label={CONFIRM_PASSWORD}
                placeholder={CONFIRM_PASSWORD}
                type="password"
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} my={2}>
          {responseError && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {responseError}
            </Alert>
          )}

          <LoadingButton
            variant="contained"
            fullWidth={true}
            size={'large'}
            onClick={handleSubmit(onSubmit)}
            loading={registerMutation.isLoading}
          >
            {submitButton}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default UserRegistrationForm
