import { FC, useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import InfoIcon from '@mui/icons-material/Info'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'

import CustomTooltip from '~/components/common/customTooltip/CustomTooltip'
import DataGrid from '~/components/UI/DataGrid/DataGrid'
import useDataGridStore, {
  DataGridProvider,
} from '~/components/UI/DataGrid/DataGridContext'
import { useAuth } from '~/services/auth'
import { useCreateOrderReturn } from '~/services/returns'
import { Fields } from '~/types/Fields.model'
import { OrderDetailsData } from '~/types/OrderDetailsData.model'
import { DATE_STRING_OPTIONS, TEXTS } from '~/utils/allConstants/Constants'
import { fileValidation, formatDate } from '~/utils/helpers/helperFunctions'

import ModalContainer from '../UI/ModalContainer'
import ModalHeader from '../UI/ModalHeader'
import { ControllerStyledTextField } from '../UI/ModalInputs'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorPrimary } = variables
const {
  SUBMIT_RETURN_REQUEST,
  RETURN_REQUEST_REVIEW,
  CLOSE,
  ORDER_NUMBER,
  INVOICE_NUMBER,
  PO,
} = TEXTS

type ReturnItems = { reason: string; quantity: number; productId: string }
interface Props {
  orderInfo: OrderDetailsData | any
  returnItems: ReturnItems[]
  closeModal?: () => void
}

const userFields: Fields[] = [
  {
    id: 'lineNumber',
    label: 'Line',
    minWidth: 20,
    sort: true,
  },
  {
    id: 'productId',
    label: 'Item Number',
    minWidth: 50,
    sort: true,
  },
  {
    id: 'quantity',
    label: 'Return Qty',
    minWidth: 50,
    sort: true,
  },
  {
    id: 'reason',
    label: 'Reason',
    minWidth: 100,
    sort: true,
  },
]

const ReturnModalBody: FC<Props> = ({ orderInfo, closeModal, returnItems }) => {
  const navigate = useNavigate()
  const createReturnOrder = useCreateOrderReturn()
  const [isLoading, setIsLoading] = useState(false)
  const source = useCallback(() => {
    return {
      items: returnItems.map((val: any) => ({
        ...val,
        id: val.lineNumber,
      })),
    }
  }, [returnItems])

  const { data: user } = useAuth()

  const modalHeaderData = [
    {
      label: ORDER_NUMBER,
      value: orderInfo.orderNumber,
    },
    {
      label: INVOICE_NUMBER,
      value: orderInfo.invoiceNumber,
    },
    {
      label: PO,
      value: orderInfo.customerNumber,
    },
  ]

  const schema = yup
    .object({
      comment: yup.string().required('Comment is Required'),
      file: yup
        .mixed()
        .test(
          'is-damaged-product',
          'Damaged product image is required',
          (files) => {
            if (returnItems.some((e: any) => e.reason === 'Damaged Product')) {
              if (!files) {
                return false
              }
            }
            return true
          }
        )
        .test('validation', 'error', fileValidation),
    })
    .required()

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      comment: '',
      file: '',
    },
  })

  const handleSendReturn = (formData: {
    comment: string
    file: File | string
  }) => {
    setIsLoading(true)
    const returnData = returnItems.map((item: ReturnItems, index: number) => {
      return {
        ...returnItems[index],
        reason: item.reason.replaceAll(' ', '_').toUpperCase(),
      }
    })

    const data = new FormData()
    Object.values(formData.file).map((file: File) => data.append('files', file))
    data.append(
      'body',
      JSON.stringify({
        orderId: orderInfo.orderNumber,
        items: returnData,
        comment: formData.comment,
      })
    )
    createReturnOrder.mutate(data, {
      onSuccess: () => {
        setIsLoading(false)
        closeModal()
        reset({ comment: '', file: '' })
        navigate('/returns/open-returns')
      },
      onError: () => {
        setIsLoading(false)
        closeModal()
        reset({ comment: '', file: '' })
      },
    })
  }

  const { setTableProps, tableProps } = useDataGridStore()

  useEffect(() => {
    setTableProps({
      name: 'Return Data',
      source,
      fields: userFields,
    })
  }, [source, setTableProps, returnItems])

  return (
    <Box
      sx={{
        position: 'relative',
        top: '1%',
        textAlign: 'left',
        p: 2,
      }}
    >
      <ModalHeader data={modalHeaderData} heading={RETURN_REQUEST_REVIEW} />
      {Object.keys(tableProps).length > 0 && <DataGrid />}
      <ModalContainer
        author={`${user.firstName} ${user.lastName}`}
        createdAt={formatDate(Date.now(), DATE_STRING_OPTIONS)}
        InputComponent={
          <ControllerStyledTextField
            name={'comment'}
            control={control}
            placeholder={'Your reason...'}
          />
        }
        attachments={
          <Controller
            name="file"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <>
                <label htmlFor="upload-file">
                  <input
                    style={{ display: 'none', cursor: 'pointer' }}
                    multiple
                    id="upload-file"
                    type="file"
                    onChange={(e) =>
                      field.onChange({
                        target: {
                          value: e.target.files,
                          name: field.name,
                        },
                      })
                    }
                  />
                  <Typography
                    color={acrColorPrimary}
                    sx={{ ':hover': { cursor: 'pointer' } }}
                  >
                    <>
                      {field.value.length != 0
                        ? `${Array.from(field.value).map(
                            (file: any) => file.name
                          )}`
                        : 'Attachment Files...'}
                      <CustomTooltip
                        title={
                          <ul
                            style={{
                              margin: 0,
                              paddingLeft: '15px',
                              lineHeight: 2,
                            }}
                          >
                            File Requirements:
                            <li>
                              File accepts pdf, jpeg, png, jpg and gif types
                            </li>
                            <li>Single image cannot be more than 10mb</li>
                            <li>All images cannot be more than 50mb</li>
                          </ul>
                        }
                      >
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </CustomTooltip>
                    </>
                  </Typography>
                </label>
                {!!error && (
                  <FormHelperText error={!!error}>
                    {!!error ? error.message : ''}
                  </FormHelperText>
                )}
              </>
            )}
          />
        }
      />
      <Grid container>
        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            sx={{
              borderRadius: 0,
              margin: '5px',
            }}
            onClick={handleSubmit(handleSendReturn)}
          >
            {SUBMIT_RETURN_REQUEST}
          </LoadingButton>
          <Button
            variant={'outlined'}
            sx={{
              borderRadius: 0,
              paddingLeft: 5,
              paddingRight: 5,
              margin: '5px',
              fontSize: '14px',
              width: '140px',
            }}
            onClick={closeModal}
          >
            {CLOSE}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

// eslint-disable-next-line react/display-name
export default (props: Props) => (
  <DataGridProvider>
    <ReturnModalBody {...props} />
  </DataGridProvider>
)
