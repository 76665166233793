import { Fields } from '~/types/Fields.model'
import { TEXTS } from '~/utils/allConstants/Constants'
import { formatCurrency, formatDate } from '~/utils/helpers/helperFunctions'

const {
  ITEM_NUMBER,
  DESCRIPTION,
  APPLIED,
  CURRENT_PRICE,
  ORIGINAL,
  APPROVED,
  EFFECTIVE_START,
  EFFECTIVE_END,
  CHANGE,
} = TEXTS

export const fields: Fields[] = [
  {
    id: 'code',
    label: ITEM_NUMBER,
    minWidth: 100,
    align: 'center',
    filterFieldConfiguration: {
      type: 'freeText',
    },
  },
  {
    id: 'description',
    label: DESCRIPTION,
    minWidth: 100,
    align: 'center',
    filterFieldConfiguration: {
      type: 'freeText',
    },
  },
  {
    id: 'price.priceSource',
    label: APPLIED,
    minWidth: 100,
    align: 'center',
    format: (_, row) => {
      if (row.price.priceSource === 'CUSTOMER') {
        return 'All Locations'
      } else {
        return 'Location'
      }
    },
  },
  {
    id: 'carbs',
    label: CURRENT_PRICE,
    minWidth: 100,
    align: 'center',
    format: (_, row) => {
      if (row.price.status === 'DELETED') {
        return 'REMOVED'
      } else if (
        new Date() > new Date(row.price.effectiveTo) &&
        row.price.status === 'ACTIVE'
      ) {
        return 'EXPIRED'
      } else {
        return `$${row.price.value}`
      }
    },
  },
]

export const subFields: Fields[] = [
  {
    id: 'originalPrice',
    label: ORIGINAL,
    minWidth: 100,
    align: 'center',
    format: (_, row) => formatCurrency(row.originalPrice),
  },
  {
    id: 'value',
    label: APPROVED,
    minWidth: 100,
    align: 'center',
    format: (_, row) => formatCurrency(row.value),
  },
  {
    id: 'effectiveFrom',
    label: EFFECTIVE_START,
    minWidth: 100,
    align: 'center',
    format: (value: string) => formatDate(value),
  },
  {
    id: 'effectiveEnd',
    label: EFFECTIVE_END,
    minWidth: 100,
    align: 'center',
    format: (value: string) => {
      if (value === '-') {
        return '-'
      } else {
        return formatDate(value)
      }
    },
  },
  {
    id: 'changeApp',
    label: CHANGE,
    minWidth: 100,
    align: 'center',
  },
]
