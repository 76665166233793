import { useEffect } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'

export const RedirectWithAccountParams = ({ to }) => {
  const { accountId, subAccountId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    navigate(
      to
        .replace(':accountId', accountId)
        .replace(':subAccountId', subAccountId),
      {
        replace: true,
      }
    )
  }, [])

  return null
}
