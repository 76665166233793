import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Account, SubAccount } from '~/types/Account.model'

import { AccountsContext } from '../index'

export default (pageName: string) => {
  const { selectedAccount, selectedSubAccount } =
    useContext(AccountsContext) ||
    ({} as {
      selectedAccount: Account
      selectedSubAccount: SubAccount
    })
  const navigate = useNavigate()
  const params = useParams()
  useEffect(() => {
    const accountChanged = selectedAccount?.id !== params.accountId
    const subAccountChanged = selectedSubAccount?.id !== params.subAccountId

    if (accountChanged || subAccountChanged) {
      const link = ['']
      if (selectedAccount) {
        link.push(selectedAccount.id)
        if (selectedSubAccount) {
          link.push(selectedSubAccount.id)
        }
      }
      link.push(pageName)

      navigate(link.join('/'))
    }
  }, [selectedAccount, selectedSubAccount])
}
