import { FC } from 'react'

import AttachFileAction from './components/AttachFileAction'
import CommentsAction from './components/CommentsAction'

type Props = {
  hasComments: number
  toggleAttachFileModal: () => void
  toggleCommentModal: () => void
  attachVisible?: boolean
  isFetching: boolean
  isOrderPodLoading: boolean
  hasAttachClick: boolean
}

const OrderInvoiceActionButton: FC<Props> = ({
  hasComments,
  toggleAttachFileModal,
  toggleCommentModal,
  attachVisible = true,
  isFetching,
  isOrderPodLoading,
  hasAttachClick,
}) => {
  return (
    <>
      {attachVisible && (
        <AttachFileAction
          isOrderPodLoading={isOrderPodLoading}
          toggleModal={toggleAttachFileModal}
          hasAttachClick={hasAttachClick}
        />
      )}

      <CommentsAction
        onClick={toggleCommentModal}
        isFetching={isFetching}
        hasComments={Boolean(hasComments)}
      />
    </>
  )
}

export default OrderInvoiceActionButton
