import { Grid, Link, Typography, useTheme } from '@mui/material'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorBlue } = variables

const RegisterConfirm = () => {
  const theme = useTheme()
  return (
    <Grid
      container
      sx={{
        minWidth: '700px',
        width: '840px',
        justifyContent: 'center',
        margin: '100px auto',
        [theme.breakpoints.down('md')]: {
          minWidth: 'auto',
          width: '100%',
          padding: '16px',
          margin: '50px auto',
        },
      }}
    >
      <Typography variant="h1" align={'center'}>
        Thank you for confirming your email!
      </Typography>

      <Typography
        variant={'h4'}
        align={'center'}
        sx={{ color: (theme) => theme.palette.text.secondary, py: 3 }}
      >
        Your account has been sent to an ACR representative to complete the
        approval process.
      </Typography>

      <Typography
        mt={3}
        sx={{
          color: (theme) => theme.palette.text.secondary,
          lineHeight: '24px',
          textAlign: 'center',
        }}
      >
        Access to the ACR portal is for existing customer only.
        <br />
        Non-ACR existing customers, please click&nbsp;
        <Link
          component={'a'}
          href={'https://amercareroyal.com/pages/open-an-account'}
          target={'_blank'}
          sx={{
            fontWeight: 'bold',
            color: acrColorBlue,
            textDecorationColor: acrColorBlue,
          }}
        >
          here
        </Link>
        &nbsp; for more information on how to open a customer account.
      </Typography>
    </Grid>
  )
}

export default RegisterConfirm
