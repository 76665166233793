import { FC, memo, ReactElement, useCallback, useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { useQuery } from '@tanstack/react-query'

import useDataGridStore, {
  DataGridProvider,
} from '~/components/UI/DataGrid/DataGridContext'
import Loading from '~/components/UI/Loading'
import {
  fetchDenialReasons,
  fetchInternalReasons,
  useApprovalReturnCSS,
} from '~/services/approval'
import { fetchReturnReasons } from '~/services/returns'
import { ReturnRequestApproval, SubmitApproval } from '~/types/Approval.model'
import { Fields } from '~/types/Fields.model'
import { UpdateReturnData } from '~/types/Return.model'
import {
  exportFile,
  sendApprovalStatusInPayload,
} from '~/utils/helpers/helperFunctions'

import { mutateFunction } from '../../components/ApprovalMutation'
import ApprovalReturnRequestContainer from '../components/ApprovalReturnRequestContainer'
import { schemaNormalReturn } from '../schema'

import ApprovalReturnRequestDetailsInput, {
  ApprovalReturnRequestDetailsInputHeader,
} from './components/ApprovalReturnRequestDetailsInput'

type Options = {
  id: string
  name: string
  queryName: string
  fields: Fields[]
}

type Props = {
  data: ReturnRequestApproval
  options: Options
}

const ApprovalReturnRequestInfoDisplayPage: FC<Props> = ({
  data,
  options,
}): ReactElement => {
  const navigate = useNavigate()
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const source = useCallback(() => {
    return {
      items: data.returnItemList,
    }
  }, [data])

  const { data: denialReasonsCodes } = useQuery(
    [`denial-reasons`],
    fetchDenialReasons,
    { keepPreviousData: true }
  )

  const { data: internalReasonCodes, isFetching: isInternalReasonFetching } =
    useQuery([`return-reasons`], fetchInternalReasons, {
      keepPreviousData: true,
    })

  const { data: returnReasonCodes, isFetching: isReturnReasonCodesFetching } =
    useQuery(['return-reason-code'], () => fetchReturnReasons(), {
      keepPreviousData: true,
    })

  const { setTableProps, tableProps } = useDataGridStore()

  const returnApprovalCssMutation = useApprovalReturnCSS(data.id)

  const { control, handleSubmit, setValue, resetField } = useForm({
    defaultValues: {
      items: [
        {
          approvalStatus: '',
          denialReason: '',
          productId: '',
          reason: '',
          isApproved: null,
          alreadyValidated: false,
        },
      ],
      approverComment: data.approverComment || '',
    },
    resolver: yupResolver(schemaNormalReturn),
  })

  const handleAttachment = useCallback(() => {
    setIsLoading(true)
    ReactGA.event({
      category: 'Return Request Approval',
      action: 'Export as Zip',
    })
    return exportFile({
      name: 'returns',
      id: data.id,
      extension: 'zip',
      exportfilename: 'Attachments',
    }).then(() => setIsLoading(false))
  }, [data.id])

  const ApprovalData = (data: SubmitApproval) => {
    return {
      items: data.items
        .filter(({ alreadyValidated }) => !alreadyValidated)
        .map(
          ({ denialReason, approvalStatus, ...item }) =>
            [
              {
                ...item,
                isApproved: sendApprovalStatusInPayload(approvalStatus),
                denialReason: !denialReason
                  ? null
                  : Object.keys(denialReasonsCodes).find(
                      (k) =>
                        denialReasonsCodes[
                          k as keyof typeof denialReasonsCodes
                        ] === denialReason
                    ),
              },
            ][0]
        ),
      approverComment: data.approverComment,
    }
  }

  const handleSubmitApproval = (data: SubmitApproval) => {
    setIsSubmitLoading(true)

    mutateFunction(
      returnApprovalCssMutation,
      ApprovalData(data),
      true,
      setIsSubmitLoading,
      navigate,
      '/approvals/all-approvals/csr'
    )
  }

  const handleSave = (saveData: SubmitApproval) => {
    setIsSaveLoading(true)

    mutateFunction(
      returnApprovalCssMutation,
      ApprovalData(saveData),
      false,
      setIsSaveLoading,
      navigate,
      '/approvals/my-approvals'
    )
  }

  const actions = [
    {
      header: () => {
        return <ApprovalReturnRequestDetailsInputHeader />
      },
      render: (row: UpdateReturnData, index: number) => {
        return (
          <ApprovalReturnRequestDetailsInput
            key={`${row.id}-${row.code}`}
            row={row}
            control={control}
            index={index}
            setValue={setValue}
            denialReasonsCodes={denialReasonsCodes}
            internalReasonCodes={internalReasonCodes}
            resetField={resetField}
          />
        )
      },
    },
  ]

  useEffect(() => {
    setTableProps({
      name: `${options.queryName}-${options.id}`,
      source,
      actions,
      fields: options.fields,
    })
  }, [source, data, options, setTableProps, denialReasonsCodes])

  if (isInternalReasonFetching || isReturnReasonCodesFetching) {
    return <Loading />
  }

  const returnReasonCodeFieldIndex = options.fields.findIndex(
    (item) => item.id === 'reason'
  )

  options.fields[returnReasonCodeFieldIndex].format = (
    _,
    row: string | any
  ) => {
    return returnReasonCodes[row.reason as keyof typeof returnReasonCodes]
  }

  const internalReasonFieldIndex = options.fields.findIndex(
    (item) => item.id === 'internalReason'
  )
  options.fields[internalReasonFieldIndex].format = (_, row: string | any) => {
    return internalReasonCodes[
      row.internalReason as keyof typeof internalReasonCodes
    ]
  }

  return (
    <ApprovalReturnRequestContainer
      data={data}
      options={options}
      tableProps={tableProps}
      handleAttachment={handleAttachment}
      isLoading={isLoading}
      control={control}
      handleSubmit={handleSubmit}
      handleSave={handleSave}
      isSaveLoading={isSaveLoading}
      isSubmitLoading={isSubmitLoading}
      handleSubmitApproval={handleSubmitApproval}
    />
  )
}
// eslint-disable-next-line react/display-name
export default memo((props: Props) => (
  <DataGridProvider>
    <ApprovalReturnRequestInfoDisplayPage {...props} />
  </DataGridProvider>
))
