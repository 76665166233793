import { FC } from 'react'
import { MuiFileInput } from 'mui-file-input'
import { Controller, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import InfoIcon from '@mui/icons-material/Info'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import CustomTooltip from '~/components/common/customTooltip/CustomTooltip'
import GroupCheckbox from '~/components/Form/GroupCheckbox'
import RadioButton from '~/components/Form/RadioButton'
import { NoGridStyledTextField } from '~/components/Form/StyledTextField'
import { InternalLink } from '~/components/UI/DataGrid/DataGrid'
import {
  useCustomerAlertMutation,
  useCustomerAlertUpdateMutation,
} from '~/services/bannerNotification'
import { Roles, RoleTypes } from '~/services/users'
import { theme } from '~/theme/AppTheme'
import { CustomerAlert } from '~/types/CustomerAlert.model'
import DatePickerOpenIcon from '~/UI/icons/CalendarIcon'
import { TEXTS } from '~/utils/allConstants/Constants'
import { formatDateTo } from '~/utils/helpers/helperFunctions'

import { BannerPreview } from './component/BannerPreview'

import './BannerNotificationForm.scss'

const { VIEW_MORE, UPDATE, SUBMIT } = TEXTS

type Props = {
  bannerData: CustomerAlert
}

const LoadingBannerButton = ({
  onClick,
  isLoading,
  children,
  isMatch,
}: {
  onClick: () => void
  isLoading: boolean
  children: string
  isMatch: boolean
}) => (
  <LoadingButton
    onClick={onClick}
    variant="contained"
    size={'large'}
    sx={{
      width: isMatch ? '100%' : '230px',
      borderRadius: '0px',
    }}
    loading={isLoading}
  >
    {children}
  </LoadingButton>
)

const BannerNotificationForm: FC<Props> = ({ bannerData }) => {
  const isMatch = useMediaQuery(theme.breakpoints.down('md'))
  const customerAlertMutation = useCustomerAlertMutation()
  const customerAlertUpdateMutation = useCustomerAlertUpdateMutation(
    bannerData?.id
  )
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const roleItems = [
    { id: RoleTypes.CUSTOMER, value: Roles.ROLE_CUSTOMER },
    { id: RoleTypes.BROKER, value: Roles.ROLE_BROKER },
    {
      id: RoleTypes.EMPLOYEE,
      value: Roles.ROLE_EMPLOYEE,
      disabled: true,
    },
    {
      id: RoleTypes.ADMIN,
      value: Roles.ROLE_SUPER_ADMIN,
      disabled: true,
    },
  ]

  const bannerExpirationOptions = [
    { label: '30 Days', value: '30' },
    { label: '60 Days', value: '60' },
    { label: 'User has marked read', value: 'MarkAsRead' },
  ]

  const schema: any = yup
    .object({
      message: yup
        .string()
        .default('')
        .required('Banner Notification is required.')
        .min(25, 'Atleast minimum 25 characters required.')
        .max(1000, 'Maximum Characters cannot bot more than 1000'),
      details: yup
        .string()
        .default('')
        .required('View More is required.')
        .max(1000, 'Maximum Characters cannot bot more than 1000'),
      includeAttachment: yup.boolean().default(false),
      file: yup
        .mixed()
        .default(null)
        .when('includeAttachment', (includeAttachment, field) => {
          if (
            (includeAttachment || includeAttachment == 'true') &&
            !bannerData?.fileUrl
          ) {
            return yup
              .mixed()
              .required('File is a required.')
              .test(
                'file',
                'error',
                (value: any, { createError }: yup.TestContext) => {
                  if (value) {
                    const type = value?.type.split('/')[1]
                    const validTypes = ['pdf', 'jpeg', 'png', 'jpg', 'gif']
                    const size = value?.size / (1024 * 1024)
                    if (!validTypes.includes(type)) {
                      return createError({
                        message: 'File is not of supported type',
                      })
                    }
                    if (size > 10) {
                      return createError({ message: 'File exceeds 10MB' })
                    }
                  }
                  return true
                }
              )
          }
          return field
        }),
      expirationType: yup.mixed().nullable().default('30'),
      effectiveFrom: yup
        .mixed()
        .default(null)
        .required('Start Date is required.'),
      role: yup
        .array()
        .of(yup.string())
        .default([RoleTypes.ADMIN, RoleTypes.EMPLOYEE]),
    })
    .required()

  const { control, handleSubmit, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: schema.cast(bannerData ? bannerData : {}),
  })

  const bannerFormWatch = watch()
  if (
    (!bannerFormWatch.includeAttachment ||
      bannerFormWatch.includeAttachment === 'false') &&
    bannerFormWatch.file
  ) {
    setValue('file', null)
  }

  const formatDataBody = (formData: CustomerAlert) => {
    const data = new FormData()
    data.append('file', formData.file)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { file, ...formDataRest } = formData
    data.append(
      'body',
      JSON.stringify({
        ...formDataRest,
        effectiveFrom: formatDateTo(formData.effectiveFrom),
      })
    )
    return data
  }

  const onSubmit = (formData: CustomerAlert) => {
    customerAlertMutation.mutate(formatDataBody(formData), {
      onSuccess: () => {
        navigate([...pathname.split('/').slice(0, -1), 'active'].join('/'))
      },
    })
  }

  const onUpdate = (formData: CustomerAlert) => {
    customerAlertUpdateMutation.mutate(formatDataBody(formData), {
      onSuccess: () => {
        navigate([...pathname.split('/').slice(0, -2), 'pending'].join('/'))
      },
    })
  }

  return (
    <>
      <Typography variant="h3" pb={3}>
        Banner Notification
      </Typography>

      <Divider />

      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { my: 1 },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid container p={3} className="banner-grid">
          <Grid item xs={12} className="banner-grid-item">
            <div>
              <Typography variant="h4">Banner Notification:</Typography>
              <Typography className="helper-text">
                Enter the message for the banner display.
              </Typography>
            </div>
            <NoGridStyledTextField
              name="message"
              control={control}
              label={'Banner Notification'}
              multiline
              rows={3}
              maxRows={5}
            />
          </Grid>
          <Grid item xs={12} className="banner-grid-item">
            <div>
              <Typography variant="h4">View More:</Typography>
              <Typography className="helper-text">
                Enter view more information for the banner display.
              </Typography>
            </div>
            <NoGridStyledTextField
              name="details"
              control={control}
              label={VIEW_MORE}
              multiline
              rows={3}
              maxRows={5}
            />
          </Grid>
          <Grid item xs={12} className="banner-grid-item">
            <div>
              <Typography variant="h4">File:</Typography>
              <Typography className="helper-text">
                Insert a file with instructions to display for the user.
                <CustomTooltip
                  title={
                    <ul
                      style={{
                        margin: 0,
                        paddingLeft: '15px',
                        lineHeight: 2,
                      }}
                    >
                      File Requirements:
                      <li>File accepts pdf, jpeg, png, jpg and gif types</li>
                      <li>File cannot be more than 10mb</li>
                    </ul>
                  }
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </CustomTooltip>
              </Typography>
            </div>
            <div>
              <Box display={'flex'} alignItems={'center'} my={2}>
                <Typography pr={1}>Include attachment? </Typography>
                <RadioButton
                  control={control}
                  name="includeAttachment"
                  sx={{
                    position: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false },
                  ]}
                />
                <Controller
                  name="file"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <MuiFileInput
                      sx={{ maxWidth: 250 }}
                      disabled={
                        !bannerFormWatch.includeAttachment ||
                        bannerFormWatch.includeAttachment === 'false'
                      }
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Box>
              {bannerData?.fileUrl && (
                <Box textAlign={'right'}>
                  <InternalLink to={bannerData.fileUrl}>
                    Last Uploaded File
                  </InternalLink>
                </Box>
              )}
            </div>
          </Grid>
          <Grid item xs={12} className="banner-grid-item">
            <div>
              <Typography variant="h4">Banner Expiration:</Typography>
              <Typography className="helper-text">
                Select when this banner notification should expire.
              </Typography>
            </div>
            <Box display={'flex'} alignItems={'center'}>
              <RadioButton
                control={control}
                name="expirationType"
                sx={{
                  position: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
                options={bannerExpirationOptions}
              />
            </Box>
          </Grid>
          <Grid item xs={12} className="banner-grid-item">
            <div>
              <Typography variant="h4">Display Start Date:</Typography>
              <Typography className="helper-text">
                Select the date of when the notification should begin to
                display.
              </Typography>
            </div>
            <Controller
              name="effectiveFrom"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disablePast
                    inputFormat="MM/dd/yyyy"
                    value={field.value}
                    onChange={field.onChange}
                    components={{
                      OpenPickerIcon: DatePickerOpenIcon,
                    }}
                    renderInput={(params: TextFieldProps) => (
                      <TextField
                        {...params}
                        error={!!error}
                        helperText={error?.message}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={12} className="banner-grid-item">
            <div>
              <Typography variant="h4">Security Role:</Typography>
              <Typography className="helper-text">
                Select those roles this message should be displayed to.
              </Typography>
            </div>
            <Box display={'flex'} alignItems={'center'}>
              <GroupCheckbox
                name="role"
                control={control}
                sx={{
                  position: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
                items={roleItems}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={12} mt={5}>
            <BannerPreview bannerFormData={bannerFormWatch} fileUrl={false} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Stack
              spacing={2}
              direction={{ xs: 'column', md: 'row' }}
              sx={{ justifyContent: 'flex-end', py: 3 }}
            >
              {bannerData ? (
                <LoadingBannerButton
                  isLoading={customerAlertUpdateMutation.isLoading}
                  isMatch={isMatch}
                  onClick={handleSubmit(onUpdate)}
                >
                  {UPDATE}
                </LoadingBannerButton>
              ) : (
                <LoadingBannerButton
                  isLoading={customerAlertMutation.isLoading}
                  isMatch={isMatch}
                  onClick={handleSubmit(onSubmit)}
                >
                  {SUBMIT}
                </LoadingBannerButton>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default BannerNotificationForm
