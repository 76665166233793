import * as React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import { Button, Grid, useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { useAuth } from '~/services/auth'
import { useSendComment } from '~/services/tableDataMutations'
import { theme } from '~/theme/AppTheme'
import { Invoice } from '~/types/Invoice.model'
import { Order } from '~/types/Order.model'
import { DATE_STRING_OPTIONS, TEXTS } from '~/utils/allConstants/Constants'
import { formatDate } from '~/utils/helpers/helperFunctions'

import IssueSection from '../UI/IssueSection'
import ModalContainer from '../UI/ModalContainer'
import ModalHeader from '../UI/ModalHeader'
import { ControllerStyledTextField } from '../UI/ModalInputs'

import ReceivedComments from './components/ReceivedComments'

const { CLOSE, SEND_COMMENT } = TEXTS

type DataActions = {
  fetchSource: (val: string) => any
  createSource: (val: string) => void
}

interface Props {
  source: Invoice | Order
  dataActions: DataActions
  name: string
  closeModal?: () => void
}

const schema = yup
  .object({
    content: yup.string().required('Comment is Required'),
    category: yup.string().required('Issue Type is Required'),
  })
  .required()

const TableCommentModalBody: React.FC<Props> = ({
  source,
  dataActions,
  name,
  closeModal,
}): React.ReactElement => {
  const { data: user } = useAuth()
  const queryClient = useQueryClient()
  const isMatchTab = useMediaQuery(theme.breakpoints.down('md'))

  const { handleSubmit, control, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      content: '',
      category: '',
    },
  })

  const { data: receivedComments } = useQuery(
    [`${name}-${source.id}`],
    async () => dataActions.fetchSource(source.id)
  )

  const commentNumbers = [
    {
      label: 'Invoice Number',
      value: source.invoiceNumber,
    },
    {
      label: 'Order Number',
      value: source.orderNumber,
    },
  ]

  const issueItems: any = [
    { label: 'Backordered Items', value: 'Backordered Items' },
    { label: 'Delivery Status', value: 'Delivery Status' },
    { label: 'Pricing', value: 'Pricing' },
    { label: 'Item Inquiry', value: 'Item Inquiry' },
    { label: 'Invoice Inquiry', value: 'Invoice Inquiry' },
    {
      label: 'Return Material Authorization (RMA)',
      value: 'Return Material Authorization (RMA)',
    },
    { label: 'Other', value: 'Other' },
  ]

  const sendCommentMutation = useSendComment(dataActions.createSource)
  const handleSendComment = (data: any) => {
    data = {
      ...data,
      contentType: '',
      id: source.id,
    }
    sendCommentMutation.mutate(data, {
      onSuccess: () => {
        reset({ content: '', category: '' })
        queryClient.invalidateQueries([`${name}-${source.id}`])
        queryClient.invalidateQueries([name, 'comments'])
        closeModal()
      },
    })
  }

  return (
    <Box
      sx={{
        position: 'relative',
        top: '1%',
        textAlign: 'left',
        p: 2,
      }}
    >
      <ModalHeader data={commentNumbers} heading={'Comments'} />
      <Box
        sx={{
          overflowY: 'auto',
          my: 2,
          px: 2,
          height: isMatchTab ? '100%' : 360,
        }}
      >
        {receivedComments && (
          <ReceivedComments receivedComments={receivedComments} />
        )}

        <IssueSection name={'category'} control={control} items={issueItems} />
        <ModalContainer
          author={`${user.firstName} ${user.lastName}`}
          createdAt={formatDate(Date.now(), DATE_STRING_OPTIONS)}
          InputComponent={
            <ControllerStyledTextField name={'content'} control={control} />
          }
        />
      </Box>

      <Grid container>
        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
          <LoadingButton
            variant="contained"
            onClick={handleSubmit(handleSendComment)}
            sx={{
              borderRadius: 0,
              margin: '5px',
            }}
            loading={sendCommentMutation.isLoading}
          >
            {SEND_COMMENT}
          </LoadingButton>
          <Button
            variant={'outlined'}
            sx={{
              borderRadius: 0,
              paddingLeft: 5,
              paddingRight: 5,
              margin: '5px',
              fontSize: '14px',
              width: '140px',
            }}
            onClick={closeModal}
          >
            {CLOSE}
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default TableCommentModalBody
