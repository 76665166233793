import { FC, ReactElement } from 'react'

import { Avatar, Grid, Typography } from '@mui/material'

import './ModalContainer.scss'
import variables from '~/theme/scss/_variables.module.scss'

const { acrColorCyanWhite } = variables

interface Props {
  InputComponent: ReactElement
  author: string
  createdAt: string
  attachments?: any
}
const ModalContainer: FC<Props> = ({
  InputComponent,
  createdAt,
  author,
  attachments,
}): ReactElement => {
  return (
    <>
      <Grid container className="comment-box">
        <Grid item xs={12} className="comment-header">
          <Avatar alt="Person Picture" src="" />
          <Typography className="author">{author}</Typography>
          <Typography className="created-date">{createdAt}</Typography>
        </Grid>
        <Grid item xs={12} bgcolor={acrColorCyanWhite}>
          {InputComponent}
        </Grid>
        <Grid item xs={12} bgcolor={acrColorCyanWhite}>
          {attachments}
        </Grid>
      </Grid>
    </>
  )
}

export default ModalContainer
