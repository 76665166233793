import {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useNavigate } from 'react-router-dom'

import { ArrowBack } from '@mui/icons-material'
import { Box, Button, Grid, Typography } from '@mui/material'

import DataGrid from '~/components/UI/DataGrid/DataGrid'
import useDataGridStore, {
  DataGridProvider,
} from '~/components/UI/DataGrid/DataGridContext'
import { InvoiceDetailsData } from '~/types/InvoiceDetailsData.model'
import { OrderDetailsData } from '~/types/OrderDetailsData.model'
import { OrderReturnItems, UpdateReturnData } from '~/types/Return.model'
import CustomModal from '~/UI/modals/CustomModal'
import ReturnModalBody from '~/UI/modals/modalBodies/returnModalBody/ReturnModalBody'
import { TEXTS } from '~/utils/allConstants/Constants'

import ReturnDetailInput, { ReturnDetailInputHeader } from './ReturnDetailInput'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorCyanWhite, acrColorGrey, acrColorDarkGrey } = variables
const { ORDER_NUMBER, NO_DATA_FOUND, CONTINUE, INVOICE_NUMBER, BACK } = TEXTS

type Fields = {
  id: string
  label: string
  minWidth: number
}

type MultiReturn = {
  item: OrderReturnItems[]
}

type Options = {
  id: string
  name: string
  queryName: string
  tableProps: { fields: Fields[] }
}

type Props = {
  data: InvoiceDetailsData | OrderDetailsData
  options: Options
}

const ReturnDetailsPage: FC<Props> = ({ data, options }): ReactElement => {
  const navigate = useNavigate()
  const source = useCallback(() => {
    return { items: data.orderItems }
  }, [data])

  const [openReturnModal, setOpenReturnModal] = useState(false)
  const [returnModalData, setReturnModalData] = useState({})
  const [returnModalItemsData, setReturnModalItemsData] = useState([])
  const { setTableProps, tableProps } = useDataGridStore()
  const [multiReturn, setMultiReturn] = useState<MultiReturn>({})

  const handleReturnModal = useCallback(() => {
    const returnData = Object.values(multiReturn).reduce(
      (acc, item) => acc.concat(item),
      []
    )

    setOpenReturnModal((prevState) => {
      if (!prevState) {
        setReturnModalData(data)
        setReturnModalItemsData(returnData)
      }
      return !prevState
    })
  }, [multiReturn])

  const updateReturnData = (
    row: UpdateReturnData,
    data: [
      {
        quantity: number
        reason: string
        id: string
        lineNumber: number
      }
    ]
  ) => {
    setMultiReturn((prev) => {
      return {
        ...prev,
        [(row.itemNo, row.lineNumber)]: data.map((item) => {
          return {
            reason: item.reason,
            quantity: item.quantity,
            productId: row.itemNo,
            lineNumber: row.lineNumber,
          }
        }),
      }
    })
  }

  const actions = [
    {
      header: () => {
        return <ReturnDetailInputHeader />
      },
      render: (row: UpdateReturnData) => (
        <ReturnDetailInput
          row={row}
          updateData={updateReturnData.bind(this, row)}
        />
      ),
    },
  ]

  useEffect(() => {
    setTableProps({
      name: `${options.queryName}-${options.id}`,
      source,
      actions,
      ...options.tableProps,
    })
  }, [source, data, options, setTableProps, multiReturn])

  const canReturn = useMemo(() => {
    return (
      Object.values(multiReturn).reduce((acc, item) => {
        return acc + item.reduce((v, i) => v + Number(i.quantity), 0)
      }, 0) > 0
    )
  }, [multiReturn])

  const closeModal = () => {
    setOpenReturnModal((prev) => {
      return !prev
    })
  }

  return (
    <>
      <Box sx={{ px: { xs: 2, md: 3 } }}>
        <Grid container mt={3}>
          <Grid item xs={10}>
            <Button
              startIcon={<ArrowBack />}
              sx={{
                fontSize: '20px',
                color: (theme) => theme.palette.text.primary,
                px: 0,
              }}
              onClick={() => navigate(-1)}
            >
              {BACK}
            </Button>
          </Grid>
        </Grid>
        {!!data ? (
          <>
            <Box mt={3}>
              <Typography
                variant="h1"
                sx={{
                  color: (theme) => theme.palette.text.secondary,
                  fontWeight: 'bold',
                  marginBottom: 3,
                }}
              >
                {options.name}
              </Typography>
              <Grid
                container
                sx={{
                  borderRadius: '4px',
                  padding: '5px 16px',
                  margin: '20px 0px',
                  backgroundColor: acrColorCyanWhite,
                  justifyContent: 'start',
                  gap: '10px',
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ mr: 7, color: acrColorGrey, fontWeight: 500 }}
                >
                  {ORDER_NUMBER}
                  <Typography
                    variant="caption"
                    sx={{ fontWeight: 'bold', fontSize: '17px', pl: 3 }}
                  >
                    {data.orderNumber}
                  </Typography>
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ mr: 3, color: acrColorGrey, fontWeight: 500 }}
                >
                  {INVOICE_NUMBER}
                  <Typography
                    variant="caption"
                    sx={{ fontWeight: 'bold', fontSize: '17px', pl: 3 }}
                  >
                    {data.invoiceNumber}
                  </Typography>
                </Typography>
                <Typography
                  variant="h4"
                  sx={{ color: acrColorDarkGrey, fontWeight: 700 }}
                ></Typography>
              </Grid>
              <Box sx={{ marginBottom: '20px' }}>
                {Object.keys(tableProps).length > 0 && <DataGrid />}
              </Box>
            </Box>
            <Box className="bottom-buttons-wrapper">
              <Button
                disabled={!canReturn}
                variant={'contained'}
                onClick={handleReturnModal}
                className="action-button"
              >
                {CONTINUE}
              </Button>
            </Box>
          </>
        ) : (
          <Box sx={{ height: 300 }}>
            <Typography
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
              }}
            >
              {NO_DATA_FOUND}
            </Typography>
          </Box>
        )}
      </Box>
      <CustomModal
        open={openReturnModal}
        setOpen={setOpenReturnModal}
        sx={{ width: '50%' }}
      >
        <ReturnModalBody
          orderInfo={returnModalData}
          returnItems={returnModalItemsData}
          closeModal={closeModal}
        />
      </CustomModal>
    </>
  )
}
// eslint-disable-next-line react/display-name
export default (props: Props) => (
  <DataGridProvider>
    <ReturnDetailsPage {...props} />
  </DataGridProvider>
)
