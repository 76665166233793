import { FC, ReactElement, useCallback, useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'

import { ArrowBack } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box, Button, Grid, Typography } from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import CreatedReturnInfo from '~/components/common/createdReturnInfo/CreatedReturnInfo'
import DataGrid from '~/components/UI/DataGrid/DataGrid'
import useDataGridStore, {
  DataGridProvider,
} from '~/components/UI/DataGrid/DataGridContext'
import Loading from '~/components/UI/Loading'
import { useAuth } from '~/services/auth'
import { fetchReturnReasons, useIssueReopen } from '~/services/returns'
import { RoleTypes } from '~/services/users'
import { ReturnRequestApproval } from '~/types/Approval.model'
import { Fields } from '~/types/Fields.model'
import CustomModal from '~/UI/modals/CustomModal'
import ReturnDenialDispute from '~/UI/modals/modalBodies/returnModalBody/ReturnDenialDisputeModel'
import { APPROVAL_STATUSES, TEXTS } from '~/utils/allConstants/Constants'
import { downloadFile, exportFile } from '~/utils/helpers/helperFunctions'

const {
  BACK,
  ATTACHMENTS,
  DOWNLOAD_ATTACHMENTS,
  EXPORT_TO_PDF,
  NO_DATA_FOUND,
  REOPEN,
  DISPUT_DENIALS,
  EXPORT_AS_ZIP,
  EXPORT_RA_AS_PDF,
} = TEXTS
const { APPROVED, REJECTED } = APPROVAL_STATUSES

type Options = {
  id: string
  name: string
  queryName: string
  tableProps: { fields: Fields[] }
}

type Props = {
  data: ReturnRequestApproval
  options: Options
}

const ReturnDetailsInfoPage: FC<Props> = ({ data, options }): ReactElement => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [isDownloadingRA, setIsDownloadingRA] = useState(false)
  const { data: user } = useAuth()
  const reopenMutation = useIssueReopen()
  const source = useCallback(() => {
    return {
      items: data.returnItemList.map((item) => {
        return {
          ...item,
          id: item.itemNo,
        }
      }),
    }
  }, [data])

  const { data: returnReasonCodes, isFetching: isReturnReasonCodesFetching } =
    useQuery(
      ['return-reason-code'],
      () => fetchReturnReasons(data.isCarrierReturn && RoleTypes.CARRIER),
      {
        keepPreviousData: true,
      }
    )

  const { setTableProps, tableProps } = useDataGridStore()
  const [openReturnConfirmModal, setOpenReturnConfirmModal] = useState(false)
  const approvedStatus = data.returnItemList.some((itm: any) => {
    if (itm.approved == false || itm.approved == true) {
      return true
    }
  })

  useEffect(() => {
    if (approvedStatus) {
      options.tableProps.fields
    } else {
      options.tableProps.fields = options.tableProps.fields.slice(0, 4)
    }

    setTableProps({
      name: `${options.queryName}-${options.id}`,
      source,
      ...options.tableProps,
    })
  }, [source, data, options, setTableProps])

  const closeModal = () => {
    setOpenReturnConfirmModal((prev) => {
      return !prev
    })
  }
  const handleDispute = () => {
    setOpenReturnConfirmModal((prev) => {
      return !prev
    })
  }

  const handleReopen = () => {
    reopenMutation.mutate(data.id, {
      onSuccess: () => {
        navigate('/returns/history')
      },
    })
  }

  const handleAttachment = useCallback(() => {
    setIsLoading(true)
    ReactGA.event({
      category: 'Return Request Reviewer',
      action: EXPORT_AS_ZIP,
    })
    return exportFile({
      name: 'returns',
      id: data.id,
      extension: 'zip',
      exportfilename: ATTACHMENTS,
    }).then(() => setIsLoading(false))
  }, [data.id])

  const handleRaAttachment = useCallback(() => {
    setIsDownloadingRA(true)
    ReactGA.event({
      category: 'Return Request Reviewer',
      action: EXPORT_RA_AS_PDF,
    })

    const url = `/v1/returns/${data.id}/raAsset`

    return downloadFile(url, `RA-${data.raNumber}.pdf`).then(() =>
      setIsDownloadingRA(false)
    )
  }, [data.id])

  const denialBtnStatus =
    data.returnItemList.findIndex((obj: any) => {
      return obj.approved === false
    }) >= 0

  if (isReturnReasonCodesFetching) {
    return <Loading />
  }

  const returnReasonCodeFieldIndex = options.tableProps.fields.findIndex(
    (item) => item.id === 'reason'
  )

  options.tableProps.fields[returnReasonCodeFieldIndex].format = (
    _,
    row: string | any
  ) => {
    return returnReasonCodes[row.reason as keyof typeof returnReasonCodes]
  }

  return (
    <>
      <Box sx={{ px: { xs: 2, md: 3 } }}>
        <Box>
          <Grid container mt={3} sx={{ alignItems: 'center' }}>
            <Grid item xs={12} className="action-buttons-wrapper">
              <Button
                startIcon={<ArrowBack />}
                sx={{
                  fontSize: 20,
                  color: (theme) => theme.palette.text.primary,
                  px: 0,
                  marginRight: 'auto',
                }}
                onClick={() => navigate(-1)}
              >
                {BACK}
              </Button>
              {Object.keys(data.assets).length > 0 && (
                <LoadingButton
                  loading={isLoading}
                  className={'action-button'}
                  variant={'contained'}
                  onClick={handleAttachment}
                >
                  {DOWNLOAD_ATTACHMENTS}
                </LoadingButton>
              )}
              {data.raAttachmentAssetId && (
                <LoadingButton
                  loading={isDownloadingRA}
                  className={'action-button'}
                  variant={'contained'}
                  onClick={handleRaAttachment}
                >
                  {EXPORT_TO_PDF}
                </LoadingButton>
              )}
            </Grid>
          </Grid>
        </Box>
        {!!data ? (
          <Box mt={3}>
            <CreatedReturnInfo pageHeading={options.name} returnData={data} />
            <Box sx={{ marginBottom: '20px' }}>
              {Object.keys(tableProps).length > 0 && <DataGrid />}
            </Box>
          </Box>
        ) : (
          <Box sx={{ height: 300 }}>
            <Typography
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
              }}
            >
              {NO_DATA_FOUND}
            </Typography>
          </Box>
        )}
        <Grid item sx={{}} display={'flex'} justifyContent={'center'}>
          {user.role != RoleTypes.CARRIER && (
            <Button
              variant={'contained'}
              onClick={handleDispute}
              disabled={data.dispute}
              sx={{
                borderRadius: 0,
                paddingLeft: 5,
                paddingRight: 5,
                fontSize: '14px',
                m: 1,
                textTransform: 'initial',
                display:
                  denialBtnStatus &&
                  [APPROVED, REJECTED].includes(data.approvalStatus)
                    ? 'visible'
                    : 'none',
              }}
            >
              {DISPUT_DENIALS}
            </Button>
          )}
          {user.role === RoleTypes.ADMIN && (
            <LoadingButton
              variant={'contained'}
              onClick={handleReopen}
              sx={{
                borderRadius: 0,
                paddingLeft: 5,
                paddingRight: 5,
                fontSize: '14px',
                m: 1,
                textTransform: 'initial',
                display:
                  data.dispute != null &&
                  [REJECTED].includes(data.approvalStatus) &&
                  user.role === RoleTypes.ADMIN
                    ? 'visible'
                    : 'none',
              }}
              loading={reopenMutation.isLoading}
            >
              {REOPEN}
            </LoadingButton>
          )}
        </Grid>
      </Box>
      <CustomModal
        open={openReturnConfirmModal}
        setOpen={setOpenReturnConfirmModal}
        sx={{ width: '50%' }}
      >
        <ReturnDenialDispute
          closeModal={closeModal}
          id={data.id}
          // onClick={handleNavigateReturn}
        />
      </CustomModal>
    </>
  )
}
// eslint-disable-next-line react/display-name
export default (props: Props) => (
  <DataGridProvider>
    <ReturnDetailsInfoPage {...props} />
  </DataGridProvider>
)
