import { FC } from 'react'

import { Box, Button, Grid, Link, Typography } from '@mui/material'

import returnPDF from '~/PDFs/Return.Policy.2023.pdf'

type Props = {
  closeModal: () => void
  onClick: () => void
}

const ReturnConfirmModal: FC<Props> = ({ closeModal, onClick }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        top: '1%',
        textAlign: 'left',
        p: 2,
      }}
    >
      <Grid container my={2} display="flex">
        <Grid item xs={12}>
          <Typography variant="h5" fontWeight={'bold'} mb={4}>
            Confirm Return
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Please review ACR&apos;s&nbsp;
            <Link
              component={'a'}
              href={returnPDF}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              Return Policy
            </Link>
            &nbsp; before proceeding with your Return.
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
          <Button
            variant="contained"
            sx={{
              borderRadius: 0,
              paddingLeft: 5,
              paddingRight: 5,
              margin: '5px',
              fontSize: '14px',
              width: '140px',
            }}
            onClick={onClick}
          >
            Proceed
          </Button>
          <Button
            variant={'outlined'}
            sx={{
              borderRadius: 0,
              paddingLeft: 5,
              paddingRight: 5,
              margin: '5px',
              fontSize: '14px',
              width: '140px',
            }}
            onClick={closeModal}
          >
            Close
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ReturnConfirmModal
