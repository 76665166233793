import { useEffect, useState } from 'react'
import { MuiFileInput } from 'mui-file-input'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { yupResolver } from '@hookform/resolvers/yup'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Link,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useQuery } from '@tanstack/react-query'

import { fetchSiteSettings, useSiteSettings } from '~/services/siteSettings'
import { theme } from '~/theme/AppTheme'
import DatePickerOpenIcon from '~/UI/icons/CalendarIcon'
import { TEXTS } from '~/utils/allConstants/Constants'
import { formatDateTo } from '~/utils/helpers/helperFunctions'

import { GlobalLoading } from '../UI/Loading'

import variables from '~/theme/scss/_variables.module.scss'

const { acrColorBlue } = variables
const { SITE_SETTINGS, DISCARD, SAVE } = TEXTS

const SiteSettings = () => {
  const updateSiteSettings = useSiteSettings()
  const [fileUrl, setFileUrl] = useState('')

  const { isFetching, data } = useQuery(
    [`site-settings`],
    () => {
      return fetchSiteSettings()
    },
    { keepPreviousData: true }
  )

  const schema = yup
    .object({
      file: yup.mixed().nullable(),
      active: yup.bool(),
      effective: yup
        .mixed()
        .default(null)
        .required('Select From Effective date'),
      expires: yup.mixed().default(null).required('Select To Effective date'),
    })
    .required()

  const { handleSubmit, control, reset, register, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      file: null,
      active: false,
      effective: '',
      expires: '',
    },
  })

  const onSubmit = (formData: any) => {
    const data = new FormData()
    data.append('file', formData.file)
    data.append(
      'body',
      JSON.stringify({
        active: formData.active,
        effective: formatDateTo(formData.effective),
        expires: formatDateTo(formData.expires),
      })
    )
    updateSiteSettings.mutate(data, {
      onSuccess: (res) => {
        setFileUrl(res.data.fileUrl)
        reset({ file: null })
        setValue('active', res.data.active)
        setValue('effective', formatDateTo(res.data.effective))
        setValue('expires', formatDateTo(res.data.expires))
      },
    })
  }

  useEffect(() => {
    if (data) {
      setFileUrl(data.fileUrl)
      setValue('active', data.active)
      setValue('effective', formatDateTo(data.effective))
      setValue('expires', formatDateTo(data.expires))
    }
  }, [data])

  const onDiscard = () => {
    reset({
      effective: null,
      expires: null,
      file: null,
      active: false,
    })
  }
  const isMatch = useMediaQuery(theme.breakpoints.down('md'))

  if (isFetching) {
    return <GlobalLoading />
  }
  return (
    <>
      <Typography variant="h3" pb={3}>
        {SITE_SETTINGS}
      </Typography>
      <Divider />

      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { my: 1 },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container mt={3} spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <Typography variant="h5">Price Change Notification</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <Grid container rowSpacing={2}>
              <Grid item xs={12} md={4} mt={2}>
                File
              </Grid>
              <Grid item xs={12} md={8}>
                <Controller
                  name="file"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <MuiFileInput
                      {...field}
                      error={!!error}
                      helperText={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} md={12} display="flex" justifyContent={'right'}>
                <Link
                  href={fileUrl}
                  target={'_blank'}
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    color: acrColorBlue,
                    textDecorationColor: acrColorBlue,
                    cursor: 'pointer',
                    '&:hover': {
                      textDecoration: 'none',
                    },
                  }}
                >
                  Last uploaded File
                </Link>
              </Grid>
              <Grid item xs={12} md={4} mt={1}>
                Disabled?
              </Grid>
              <Grid item xs={12} md={8}>
                <Controller
                  name="active"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...register('active')}
                      onChange={(e) => field.onChange(e.target.checked)}
                      checked={field.value}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4} my={isMatch ? 1 : 3}>
                Effective Dates?
              </Grid>
              <Grid item xs={12} md={8}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography>From</Typography>
                    <Controller
                      name="effective"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            disablePast
                            inputFormat="MM/dd/yyyy"
                            value={field.value}
                            onChange={field.onChange}
                            components={{
                              OpenPickerIcon: DatePickerOpenIcon,
                            }}
                            renderInput={(params: TextFieldProps) => (
                              <TextField
                                {...params}
                                error={!!error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>To</Typography>
                    <Controller
                      name="expires"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            disablePast
                            inputFormat="MM/dd/yyyy"
                            value={field.value}
                            onChange={field.onChange}
                            components={{
                              OpenPickerIcon: DatePickerOpenIcon,
                            }}
                            renderInput={(params: TextFieldProps) => (
                              <TextField
                                {...params}
                                error={!!error}
                                helperText={error?.message}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                </Grid>
                <Divider />
              </Grid>
            </Grid>
            <Stack
              spacing={2}
              direction={{ xs: 'column', md: 'row' }}
              sx={{ justifyContent: 'flex-end', py: 3 }}
            >
              <Button
                variant="outlined"
                size={'large'}
                onClick={onDiscard}
                sx={{ width: isMatch ? '100%' : '230px', borderRadius: '0px' }}
              >
                {DISCARD}
              </Button>

              <LoadingButton
                loading={updateSiteSettings.isLoading}
                variant="contained"
                size={'large'}
                sx={{ width: isMatch ? '100%' : '230px', borderRadius: '0px' }}
                type="submit"
              >
                {SAVE}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default SiteSettings
